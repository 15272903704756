import {
  Button,
  ContextMenu,
  Icon,
  Li,
  Link, Loading,
  SplitColumnsContainer
} from '@startlibs/components';
import {Field} from '@startlibs/form'
import {callIfFunction, getColor, safeNewLineToBr} from '@startlibs/utils';
import {usePopupToggle} from '@startlibs/core';
import React, {Suspense, useState} from 'react';
import styled from 'styled-components';
import {
  BoxWrapperHeading,
  BoxPageWrapper
} from '../components/BoxPageLayout';
import {CMSBackground} from '../components/PageLayout';
import {EmptyListPlaceholder} from '../administrator/RequestList';
import {ExpertCaseList} from './viewExpert/ExpertCaseList'
import {NotProvided} from '../pages/steps/components/RequestView'
import {ProfilePage, ImageInputContainer, ThumbnailContainer} from './components/ExpertProfileLayout';
import {ReturnButton} from '../components/Header';
import {getAvatarBackgroundImage} from './utils/avatar'
import { getOrigin } from './hooks/useJwt';
import {useDeleteExpertDialog} from './hooks/useDeleteExpertDialog'
import {useRegisterCasesUpdate, useRegisterCaseUpdate} from './hooks/useRegisterCaseUpdate'
import {useResetExpertPasswordDialog} from './hooks/useResetExpertPasswordDialog'

const LoadingWrapper = styled.div`
  width: 100%;
  height: 8rem;
  border-radius: 5px;
  background: ${getColor('gray240')};
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${getColor('gray210')};
  margin-top: 1.5rem;
`

export const ViewExpert = ({action, id, useExpertViewSuspense, useExpertCases}) => {

  const expert = useExpertViewSuspense(id)

  const [openResetPassword, resetPasswordDialog] = useResetExpertPasswordDialog(expert)
  const [openDeleteDialog, deleteDialog] = useDeleteExpertDialog(expert)

  const contextMenu = usePopupToggle()

  return <ProfilePage viewOnly>
    <CMSBackground/>
    <BoxPageWrapper>

      <BoxWrapperHeading withReturn>
        <ReturnButton as={Link} to="/experts"><Icon icon="return"/></ReturnButton>
        <div className="header-content">
          <h2><span className="title-label">Expert details:</span> <span>{expert.firstName} {expert.lastName}</span>
          </h2>
          <div className="buttons-wrapper">
            <Button.Link small to={`/experts/edit/${expert.id}`} state={{prevLocation: '/experts/view/' + expert.id}}>Edit
              details</Button.Link>
            <Button small onlyDropdown onClick={contextMenu.open}>
              {
                contextMenu.isOpen &&
                <ContextMenu>
                  <Li label="Remove expert" onClick={openDeleteDialog}/>
                  <Li label="Reset password" onClick={openResetPassword}/>
                </ContextMenu>
              }
            </Button>
          </div>
        </div>
      </BoxWrapperHeading>

      <div className="profile-content">

        <div className="fields-wrapper">
          <SplitColumnsContainer viewportMinWidth={850}>
            <Field label="First Name:">
              <span>{expert.firstName || <NotProvided/>}</span>
            </Field>
            <Field label="Last name:">
              <span>{expert.lastName || <NotProvided/>}</span>
            </Field>
          </SplitColumnsContainer>
          <SplitColumnsContainer viewportMinWidth={850}>
            <Field label="Email:">
              <span>{expert.email}</span>
            </Field>
            <Field label="Phone:">
              <span>{expert.info.phone || <NotProvided/>}</span>
            </Field>
          </SplitColumnsContainer>
          <Field label="Specialization:">
            <div>
              {
                expert.info.specialization.length
                  ? expert.info.specialization.map(spec => <span className="specialization-item"
                                                                 key={spec}>{spec}</span>)
                  : <NotProvided/>
              }
            </div>
          </Field>
          <Field label="Resume:" className="resume">
            {
              expert.info.resume
              ? <p dangerouslySetInnerHTML={{__html: safeNewLineToBr(expert.info.resume)}}/>
              : <NotProvided/>
            }
          </Field>
        </div>

        <div className="image-wrapper">
          <ImageInputContainer>
            <ThumbnailContainer viewOnly css={getAvatarBackgroundImage(expert)}>
              {!expert.picture && <Icon icon="physician"/>}
            </ThumbnailContainer>
          </ImageInputContainer>
        </div>

      </div>

      <Field label="Cases shared with this expert:" css={`padding-right: 120px;`}/>
      <Suspense fallback={<LoadingWrapper><Loading/></LoadingWrapper>}>
        <ExpertCases useExpertCases={useExpertCases} id={id} expert={expert}/>
      </Suspense>

      {resetPasswordDialog}
      {deleteDialog}
    </BoxPageWrapper>
  </ProfilePage>
}

const ExpertCases = ({useExpertCases,id,expert}) => {
  const [cases, setCases] = useState(useExpertCases(id))

  useRegisterCaseUpdate((caseInfo, update) => setCases(cases => cases.map(currentCaseInfo => currentCaseInfo.id === caseInfo.id ? callIfFunction(update, currentCaseInfo) : currentCaseInfo)))
  useRegisterCasesUpdate((update) => setCases(cases => callIfFunction(update, cases)))

  if (cases.length === 0) {

    return <EmptyListPlaceholder>
      <span>There are no cases shared with this expert yet. <a href={getOrigin() + "/cp_browser.php"} target="_top"
                                                               className="link">Click here</a> to share a case.</span>
    </EmptyListPlaceholder>
  }
  if (cases.length > 0) {
    return <ExpertCaseList cases={cases} expert={expert}/>
  }
}
