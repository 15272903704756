import {Button, SplitColumnsContainer} from '@startlibs/components'
import {DatePicker, TextInput, RadioboxGroup, SimpleRadiobox, WithForm, Errors} from '@startlibs/form'
import {Fill, useEnsureRef, useToggle} from '@startlibs/core'
import {formatDate, getColor, parseDate} from '@startlibs/utils';
import {navigate} from '@reach/router'
import React, {forwardRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {Card, PageFooter, SectionHeading} from '../../components/PageLayout';
import {DATE_FORMAT} from '../../config/DateFormat'
import {LogoutButton} from '../../components/Navbar';
import {buildValidation, confirmEmails, emailValidation, optional, required} from '../../utils/validation'
import {useRecentlySaved} from '../../associates/hooks/RecentlySaved'
import {useNotification} from '../../components/Notifications'
import {useSetConfirmExitPage} from '../../hooks/useConfirmExitPage'

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

const VIEWPORT_MIN_WIDTH = 500

const onFailure = (e) => {
  console.log(e)
}

const dobTransform = _.update('dob', (d) => formatDate(d, "yyyy-MM-dd"))
const dobParse = _.update('dob', (d) => d && parseDate(d, new Date(), "yyyy-MM-dd"))

export const Step1 = forwardRef(({action, onSuccess, isSignUp, values = {contactName: "", address: ""}, isAssociate, requestId}, ref) => {

  const formRef = useEnsureRef(ref)

  useSetConfirmExitPage(() => formRef.current.confirm())

  const [, setRecentlySaved] = useRecentlySaved()
  const [, setNotification] = useNotification()

  const saveAndExitLoading = useToggle()

  const saveAndExit = (form) => () => saveAndExitLoading.wait(form.submitForm(undefined, {onSuccess: () => navigate("/access")}))
  const saveAndViewAll = (form) => () => saveAndExitLoading.wait(form.submitForm(undefined, {
    onSuccess: () => {
      setRecentlySaved(requestId)
      setNotification("Request saved successfully.")
      navigate("/associate")
    }
  }))

  const preValidation = buildValidation({
    firstName: [required],
    lastName: [required],
    dob: [required, (d) => d === -1 && "Invalid date"],
    gender: [required],
    email: isAssociate ? [optional(emailValidation)] : [required, emailValidation, confirmEmails('confirmEmail')],
    confirmEmail: isAssociate ? [] : [required],
    phoneNumber: isAssociate ? [] : [required],
  })

  const onFailure = (e) => {
    console.log(e)
  }

  return <WithForm
    alwaysSave
    action={action}
    preValidation={preValidation}
    values={dobParse(values)}
    transform={dobTransform}
    ref={formRef}
    onSuccess={onSuccess}
    onFailure={onFailure}
  >{form => <>
    <SectionHeading><h3>Patient details</h3></SectionHeading>
    <Card>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          type="text"
          path="firstName"
          label="First name"
          placeholder="Ex: John"
          maxLength={255}
          mandatory
        />
        <TextInput
          type="text"
          path="lastName"
          label="Last name"
          placeholder="Ex: Doe"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <DatePicker
          weekStartsMonday
          label="Date of birth"
          placeholder={`Ex: ${DATE_FORMAT().toUpperCase()}`}
          format={DATE_FORMAT()}
          path="dob"
          mandatory
        />
        <RadioboxGroup mandatory label="Gender" horizontal path="gender">
          <SimpleRadiobox fieldValue="M" label="Male"/>
          <SimpleRadiobox fieldValue="F" label="Female"/>
        </RadioboxGroup>
      </SplitColumnsContainer>
      <AddressHeading>{isAssociate ? 'Patient mailing address' : 'Mailing address'}</AddressHeading>
      <TextInput
        type="text"
        path="address"
        label="Street"
        placeholder="Ex: 001 Street Name"
        maxLength={255}
      />
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          type="text"
          path="city"
          label="City"
          placeholder="Ex: New York"
          maxLength={255}
        />
        <TextInput
          type="text"
          path="state"
          label="State"
          placeholder="Ex: New York"
          maxLength={255}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          type="text"
          path="country"
          label="Country"
          placeholder="Ex: USA"
          maxLength={255}
        />
        <TextInput
          type="text"
          path="zipCode"
          label="Postal code"
          placeholder="Ex: 12345"
          maxLength={255}
        />
      </SplitColumnsContainer>
      {
        isAssociate && <>
          <AddressHeading css="margin-top:2.5rem;">Patient contact details</AddressHeading>
          <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            <TextInput
              path="email"
              label="Patient email"
              placeholder="Ex: user@email.com"
              maxLength={255}
            />
            <TextInput
              path="phone"
              label="Patient phone"
              setValue={(newValue, _, setValue) => setValue(newValue.replace(/[^0-9()\-+\s]/, ''))}
              helpText="(include country code)"
              placeholder="Ex: +1 (123) 456 7890"
              maxLength={255}
            />
          </SplitColumnsContainer>
        </>
      }
    </Card>
    {
      !isAssociate &&
      <>
        <SectionHeading>
          <h3>Contact details</h3>
          <p>Enter your contact information to receive notifications and to be contacted in case additional information
            is needed.<br/>
            <b>If you are not the patient, please also enter your name in the “Contact name” field.</b></p>
        </SectionHeading>
        <Card>
          <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            <TextInput
              type="text"
              path="email"
              label="Email"
              placeholder="Ex: user@email.com"
              mandatory
              maxLength={255}
            />
            <TextInput
              type="text"
              path="confirmEmail"
              label="Confirm email"
              placeholder="Ex: user@email.com"
              mandatory
              maxLength={255}
            />
          </SplitColumnsContainer>
          <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            <TextInput
              type="text"
              path="phoneNumber"
              setValue={(newValue, _, setValue) => setValue(newValue.replace(/[^0-9()\-+\s]/, ''))}
              label="Phone number"
              helpText="(include country code)"
              placeholder="Ex: +1 (123) 456 7890"
              mandatory
              maxLength={255}
            />
            <TextInput
              type="text"
              path="contactName"
              label="Contact name"
              helpText="(if different than patient)"
              placeholder="Ex: John Doe"
              maxLength={255}
            />
          </SplitColumnsContainer>
        </Card>
      </>
    }
    <Errors/>
    <PageFooter>
      {
        isSignUp
          ? <Button onClick={!isAssociate ? () => navigate("/access") : () => navigate("/associate")} tabIndex={3}>Discard
            request</Button>
          : (!isAssociate
            ? <Button isLoading={saveAndExitLoading.isOpen} onClick={saveAndExit(form)}>Exit and save for later</Button>
            : <Button isLoading={saveAndExitLoading.isOpen} onClick={saveAndViewAll(form)}>Save and view requests</Button>
          )
      }
      <div className="right-wrapper">
        <Button type="submit" highlight isLoading={form.isLoading && !saveAndExitLoading.isOpen}>Next</Button>
      </div>
    </PageFooter>
    <Fill name="Navbar-Exit">
      {
        isSignUp
          ? <LogoutButton onClick={() => navigate("/access")}>Discard request</LogoutButton>
          : <LogoutButton onClick={saveAndExit(form)}>Exit and save for later</LogoutButton>
      }
    </Fill>
  </>}</WithForm>
})
