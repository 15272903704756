import React, {Suspense, useState} from 'react'
import _ from 'lodash/fp'
import {navigate, Router} from '@reach/router'
import {ExpertList} from './ExpertList'
import {EditExpert} from './EditExpert'
import {ViewExpert} from './ViewExpert'
import {useRegisterExpertDeleted} from './hooks/useDeleteExpertDialog'
import {postFetcher} from '@startlibs/utils'
import {authPostFetcher, jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {willUseSuspense} from '../hooks/useSuspense'
import {useConstant} from '@startlibs/core'
import {getOrigin, useRegisterJwt, useRegisterOrigin} from './hooks/useJwt'
import {Button} from '@startlibs/components'

const DEV_JWT = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NUeXBlIjoiQ01TIiwicHJvdmlkZXJOYW1lIjoiZGVtbyJ9.wP0YaVyvXVtKtRw0jgLf7-f2PXPrYWlJQy5ApoPEJfY'

const useAuthSuspense = willUseSuspense((jwt) =>
  jwtGetFetcher(jwt)(`/api/experts?nocache=${new Date().getTime()}`)
)


export const InvitedExpertRoutes = ({jwt = DEV_JWT}) => {
  useRegisterJwt(() => jwt)
  const origin = useConstant(new URL(window.document.referrer || window.location.href).origin)

  const addOrUpdateExpert = (expert) =>
    jwtPostFetcher(jwt)('/api/expert', expert, {method: 'PUT'})

  const onAddSuccess = () => {
    window.top.location = (origin+"/consultant/home.php")
    return new Promise(res => {})
  }

  const useExpertViewSuspense = useConstant(willUseSuspense(() =>
    jwtGetFetcher(jwt)(`/api/expert/?nocache=${new Date().getTime()}`)
      .then(_.update('info.specialization', (v) => v || []))
  ))

  const incompleteFooter =  (form) => <>
    <div className="left-block">
      <Button.a target="_top" href={"/api/expert/passettings?t="+jwt}>Account settings</Button.a>
    </div>
    <div className="right-block">
      <Button.a target="_top" href={origin+"/consultant/home.php"}>Maybe later</Button.a>
      <Button isLoading={form.isLoading} highlight type="submit">Finish profile</Button>
    </div>
  </>

  const expertFooter =  (form) => <>
      <div className="left-block">
        <Button.a target="_top" href={"/api/expert/passettings?t="+jwt}>Account settings</Button.a>
      </div>
    <div className="right-block">
      <Button.a target="_top" href={origin+"/consultant/home.php"}>Cancel</Button.a>
      <Button isLoading={form.isLoading} highlight type="submit">Update details</Button>
    </div>
  </>

  return <Suspense fallback={null}>
    <Router>
      <EditExpert onSuccess={onAddSuccess} isIncomplete footer={incompleteFooter} isExpert path="finish" useExpertViewSuspense={useExpertViewSuspense} action={addOrUpdateExpert} id=""/>
      <EditExpert onSuccess={onAddSuccess} isExpert footer={expertFooter} default useExpertViewSuspense={useExpertViewSuspense} action={addOrUpdateExpert} id=""/>
    </Router>
  </Suspense>
}
