import {Button, ContextMenu, Icon, Li, Loading} from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import {getColor, media} from '@startlibs/utils';
import {usePopupToggle, useToggle,useIsUnmounted} from '@startlibs/core'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {AssociatesRequestCard} from './dashboard/AssociatesRequestCard'
import {PageContainer} from '../components/PageLayout';
import {STATE_LABELS} from '../enums/CaseState'
import {SearchInput, EmptyListPlaceholder} from '../administrator/RequestList'
import {authGetFetcher} from '../utils/authFetch'
import {willUseSuspense} from '../hooks/useSuspense'
import {useRecentlySaved} from './hooks/RecentlySaved'

const DashboardHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  padding: 0 .5rem;
  h2 {
    margin: 0 1rem 0 0;
    font-size: 16px;
    color: ${getColor('gray90')};
  }
  .heading-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 0;
  }
  .left-content, .right-content {
    display: flex;
    align-items: center;
  }
  .left-content {
    ${SearchInput} {
      flex-basis: 19rem;
      margin-right: 1rem;
    }
  }
  ${media.min(841)`
    .mobile-visible {
      display: none;
    }
  `}
  ${media.max(840)`
    .heading-actions {
      flex-basis: 100%;
      flex-wrap: wrap;
      .left-content {
        flex-basis: 0;
        ${SearchInput} {
          flex-basis: 14rem;
        }
      }
      .right-content {
        ${SortButton} {
          ${Icon} {
            display: none;
          }
        }
      }
    }
  `}
  ${media.between(650, 840)`
    .mobile-hidden {
      display: none;
    }
    .mobile-visible {
      display: inline;
    }
  `}
  ${media.max(649)`
    .heading-actions {
      .left-content, .right-content {
        flex-basis: 100%;
      }
      .left-content {
        margin-bottom: 1rem;
        ${SearchInput} {
          flex-grow: 1;
        }
        .mobile-visible {
          display: inline;
        }
        .mobile-hidden {
          display: none;
        }
      }
      .right-content {
        flex-grow: 1;
        ${SortButton} {
          ${Icon} {
            display: inline-block;
          }
        }
      }
    }
  `}
  ${media.max(380)`
    .heading-actions {
      .left-content {
        ${SearchInput} {
          flex-basis: 0;
        }
      }
      .right-content {
        ${SortButton} {
          ${Icon} {
            display: none;
          }
        }
      }
    }
  `}
  /* IE11 Adjustments */
  @media all and (-ms-high-contrast:none) {

     .left-content ${SearchInput} { min-width: 12rem; } /* IE10 */
     *::-ms-backdrop, ${SearchInput} { min-width: 12rem; } /* IE11 */

      .left-content ${Button} { min-width: 125px; } /* IE10 */
      *::-ms-backdrop, ${Button} { min-width: 125px; } /* IE11 */

   }
`

const SortButton = styled(Button)`
  flex-shrink: 0;
  ${Icon} {
    margin-right: 4px;
  }
  ${media.max(649)`
    flex-grow: 1;
  `}
  ${media.max(380)`
    padding: 10px 0.5rem;
  `}
`

const RequestsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${EmptyListPlaceholder} {
    flex-basis: 100%;
    margin: 0 .5rem;
  }
`

const RequestsLoader = styled.div`
  margin: 3rem auto 0;
  flex-basis: 100%;
  ${Loading} {
    margin: auto;
  }
`

const useAuthSuspense = willUseSuspense((url) => authGetFetcher(url))

const ALPHABETICAL_SORT = 'Alphabetically'
const SORT_OPTIONS = ['By request date', 'By date modified', ALPHABETICAL_SORT]
const FILTER_OPTIONS = [''].concat(Object.keys(STATE_LABELS))

// SCROLL PAGINATION
const PAGE_SIZE = 18
const LOAD_MORE_WHEN_PX_SCROLL_TO_BOTTOM = 200

export const AssociatesDashboard = () => {
  const [recentlySaved, setRecentlySaved] = useRecentlySaved()

  useEffect(() => {
    setTimeout(() => setRecentlySaved(null), 4000)
  }, [recentlySaved])

  const [requests,setRequests] = useState(useAuthSuspense('/api/associate/caseRequests'))

  const isUnmounted = useIsUnmounted()

  useEffect(() => {
    const timer = setInterval(() => {
      authGetFetcher('/api/associate/caseRequests')
        .then((v) => !isUnmounted() && setRequests(v))
        .catch((r) => {
          if (r[1] && r[1].status === 401) {
            window.location.href = "/api/associate/paslogin"
          }
        } )
    },30e3);
    return () => clearInterval(timer)
  },[])

  const query = useToggle('')

  const filter = useToggle('')
  const filterMenu = usePopupToggle()

  const sorting = useToggle(SORT_OPTIONS[1])
  const sortMenu = usePopupToggle()


  const numberOfPages = useToggle(1)
  const loadingRequests = useToggle()
  const loaderPromise = useRef()

  const checkWindowSizeAndLoadMore = () => {
    if (window.innerHeight === document.body.scrollHeight) {
      loadMoreRequests()
    }
  }
  const loadMoreRequests = (resetPagesOrEvent) => {
    const resetPages = resetPagesOrEvent === true
    const finished = (numberOfPages.get() * PAGE_SIZE) >= requests.length
    loaderPromise.current = resetPages ? null : loaderPromise.curent
    if ((finished && !resetPages) || loaderPromise.current || (requests.length === 0)) {
      return
    }
    if (window.innerHeight + window.scrollY + LOAD_MORE_WHEN_PX_SCROLL_TO_BOTTOM < document.body.scrollHeight) return
    const nextPage = (resetPages ? 0 : numberOfPages.get()) + 1
    loadingRequests.open()
    const thisLoader = loaderPromise.current = new Promise((res) => setTimeout(res, 500)).then((result) => {
      if (thisLoader === loaderPromise.current) {
        loadingRequests.close()
        loaderPromise.current = false
        numberOfPages.openWith(nextPage)
        checkWindowSizeAndLoadMore()
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', loadMoreRequests)
    checkWindowSizeAndLoadMore()
    return () => window.removeEventListener('scroll', loadMoreRequests)
  },[])

  const queriedRequests = useMemo(() => {
    numberOfPages.openWith(1)
    const queried = query.isOpen
      ? requests.filter(request =>
        [
          request.patientInfo.firstName + " " + request.patientInfo.lastName,
          request.caseInfo && request.caseInfo.patientDiagnosis,
          request.caseInfo && request.caseInfo.clinicalSummary,
          request.requestCode,
          request.requestId
        ].join("      ").toLowerCase().indexOf(query.isOpen.toLowerCase()) >= 0)
      : requests
    const filtered = filter.isOpen
      ? queried.filter(request => request.state === filter.isOpen)
      : queried
    return _.orderBy([(request) => {
      switch (SORT_OPTIONS.indexOf(sorting.isOpen)) {
        case 0:
          return request.whenModifiedEpochMilli
        case 1:
          return request.whenCreatedEpochMilli
        case 2:
          return (request.patientInfo.firstName + " " + request.patientInfo.lastName).toLowerCase()
      }
    }], [sorting.isOpen === ALPHABETICAL_SORT ? 'asc' : 'desc'], filtered)
  }, [query.isOpen, filter.isOpen, sorting.isOpen,requests])

  const shownRequests = useMemo(() => queriedRequests.slice(0,numberOfPages.isOpen*PAGE_SIZE),[queriedRequests,numberOfPages.isOpen])

  return <PageContainer fullWidth>
    <DashboardHeader>
      <h2>Expert opinion requests</h2>
      <div className="heading-actions">
        <div className="left-content">
          <SearchInput>
            <Icon icon="search"/>
            <TextInput
              raw
              setValue={query.openWith}
              value={query.isOpen}
              placeholder="Search requests"
            />
          </SearchInput>
          <Button.Link highlight icon="plus-circle" to="/associate/caseRequest">
            <span className="mobile-hidden">Request new expert opinion</span>
            <span className="mobile-visible">New request</span>
          </Button.Link>
        </div>
        <div className="right-content">
          <SortButton outline icon="sort"
                      onClick={filterMenu.open}>Filter: {STATE_LABELS[filter.isOpen] || "All Status"}
            {
              filterMenu.isOpen &&
              <ContextMenu>
                {FILTER_OPTIONS.map(option => <Li icon={option === filter.isOpen ? "check" : undefined}
                                                  label={STATE_LABELS[option] || "All Status"} key={option}
                                                  onClick={filter.willOpenWith(option)}/>)}
              </ContextMenu>
            }
          </SortButton>
          <SortButton outline icon="sort" onClick={sortMenu.open}>Sort: {sorting.isOpen}
            {
              sortMenu.isOpen &&
              <ContextMenu>
                {SORT_OPTIONS.map(option => <Li key={option} icon={option === sorting.isOpen ? "check" : undefined}
                                                label={option} onClick={sorting.willOpenWith(option)}/>)}
              </ContextMenu>
            }
          </SortButton>
        </div>
      </div>
    </DashboardHeader>

    <RequestsContainer>
      {
        shownRequests.map(request => <AssociatesRequestCard recentlySaved={recentlySaved === request.requestId}
                                                              key={request.requestId} request={request}/>)
      }
      {
        loadingRequests.isOpen
          ? <RequestsLoader><Loading size={36} borderWidth={5}/></RequestsLoader>
          : queriedRequests.total === 0
          ? <EmptyListPlaceholder>
            {requests.length > 0 && queriedRequests.length === 0 && <span>No requests matching your criteria.</span>}
            {requests.length === 0 && <>
              <div>You have not created a request yet.</div>
              <Button.Link highlight icon="plus-circle" to="/associate/caseRequest" css="margin-top: 1rem;">Create your first request
                now</Button.Link></>
            }
          </EmptyListPlaceholder>
          : null
      }
    </RequestsContainer>
  </PageContainer>
}
