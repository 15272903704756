import React from 'react'
import styled from 'styled-components'

const SavingFeedbackText = styled.div `
  ${props => props.saving ? `
    font-weight: 600;
    `:`
    color: rgba(0,0,0,0.5);
  `}
`

class Timer extends React.Component {
  interval
  state = {}

  tick = () => {
    this.setState({secondsElapsed: this.state.secondsElapsed + 1})
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, 60 * 1000)
  }


  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {time, alwaysDate, noHours, dateFormat, shortMonth, noPrefix, onInvalid} = this.props

    if (time + 60 * 60 * 1000 > Date.now()) {
      return <span>{Math.round((Date.now() - time) / (60 * 1000)) + ' minutes ago'}</span>
    }
    else {
      return <span>More than one hour ago</span>
    }
  }
}

export const SavingFeedback = styled(({lastSaved,isSaving, className}) =>
  isSaving ?
    <SavingFeedbackText saving className={className}>Saving...</SavingFeedbackText>
  : lastSaved && <SavingFeedbackText className={className}>Automatically saved <Timer time={lastSaved} /></SavingFeedbackText>)`
  `