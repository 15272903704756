import React from 'react'

export const NOT_SUBMITTED = 'NOT_SUBMITTED'
export const SUBMITTED = 'SUBMITTED'
export const ACCEPTED = 'ACCEPTED'
export const REVIEWED = 'REVIEWED'
export const REJECTED = 'REJECTED'

export const STATE_LABELS = {
  [NOT_SUBMITTED]:'Draft',
  [SUBMITTED]:'Requested',
  [ACCEPTED]:'Under review',
  [REVIEWED]:'Reviewed',
  [REJECTED]:'Declined',
}
