import {Button} from '@startlibs/components'
import {Errors, useProvideErrors} from '@startlibs/form'
import {Fill, useRefState, useToggle} from '@startlibs/core'
import {navigate} from '@reach/router'
import React, {useImperativeHandle} from 'react'

import {LogoutButton} from '../../components/Navbar';
import {PageFooter} from '../../components/PageLayout';
import {PaymentErrorDialog} from './components/PaymentErrorDialog'
import {RequestView} from './components/RequestView'

import {SUBMITTED} from '../../enums/CaseState'
import {useSetConfirmExitPage} from '../../hooks/useConfirmExitPage'
import {persistMedicalRecord, updateMedicalRecordsInfo} from './step3/utils'
import {EMPTY_MEDICAL_RECORDS} from './Step3'
import {jwtPostFetcher} from '../../utils/authFetch'
import {callIfFunction} from '@startlibs/utils'

export const Step4 = ({caseRequest, prevStep, nextStep, submitRequest, setMedicalRecords, updateCardAction, setCardInfo, isAssociate, associateInfo, isApp, allowUpload, getJwt, allowSubmit = true}) => {

  const [ErrorsProvider, errorsContext, errorsUtils] = useProvideErrors()

  const loading = useToggle()
  const paymentDialog = useToggle()
  const uploaderRef = React.useRef()

  const confirmUploads = () =>
    uploaderRef.current
      ? uploaderRef.current.confirm()
      : Promise.resolve()

  const confirmExitPage = () => confirmUploads().then(() => ({prevStep, nextStep}))
  useSetConfirmExitPage(confirmExitPage())

  const submitAndNext = () => {
    confirmUploads().then(() =>
      loading.wait(submitRequest().then(nextStep).catch(([text, {status}]) => {
        if (status === 560) {
          paymentDialog.openWith(text)
        } else {
          errorsUtils.setError("", "Some problem has occured")
        }
      })))
  }

  const medicalRecordsStore = useRefState(caseRequest.medicalRecordsInfo || EMPTY_MEDICAL_RECORDS)
  const medicalRecordsUpdater = updateMedicalRecordsInfo(jwtPostFetcher(callIfFunction(getJwt)))(medicalRecordsStore, setMedicalRecords, caseRequest.requestId)

  const persistRecord = persistMedicalRecord(medicalRecordsUpdater)


  return <>
    <RequestView uploaderRef={uploaderRef} caseRequest={caseRequest} associateInfo={associateInfo} isApp={isApp}
                 persistRecord={persistRecord}
                 allowUpload={allowUpload}/>
    <ErrorsProvider value={errorsContext}>
      <Errors/>
    </ErrorsProvider>
    {isApp ?
      (caseRequest.state === SUBMITTED &&
        <PageFooter>
          <Button highlight isLoading={loading.isOpen} onClick={nextStep}>Check status</Button>
        </PageFooter>)
      :
      <PageFooter>
        {
          !isAssociate ?
            <Button onClick={() => confirmExitPage().then(() => navigate("/access"))} tabIndex={3}>
              {caseRequest.state === SUBMITTED ? 'Exit' : 'Exit and save for later'}
            </Button>
            : <Button onClick={() => navigate("/associate")}>View all requests</Button>
        }
        <div className="right-wrapper">
          {
            prevStep &&
            <Button onClick={prevStep} tabIndex={2}>{caseRequest.state === SUBMITTED ? 'Edit request' : 'Previous'}</Button>
          }
          {caseRequest.state === SUBMITTED ?
            <Button highlight isLoading={loading.isOpen}
                    onClick={() => confirmExitPage().then(() => window.location = window.location.pathname + "?to=check")}>Check status</Button>
            :
            (allowSubmit && <Button highlight isLoading={loading.isOpen} onClick={submitAndNext}>Submit request</Button>)
          }
        </div>
      </PageFooter>
    }
    <Fill name="Navbar-Exit">
      <LogoutButton onClick={() => confirmExitPage().then(() => navigate("/access"))}>
        {caseRequest.state === SUBMITTED ? 'Exit' : 'Exit and save for later'}
      </LogoutButton>
    </Fill>
    {
      paymentDialog.isOpen &&
      <PaymentErrorDialog setCardInfo={setCardInfo} error={paymentDialog.isOpen} closeDialog={paymentDialog.close}
                          caseRequest={caseRequest} onSuccess={nextStep} action={submitRequest}
                          updateCardAction={updateCardAction}/>
    }
  </>


}
