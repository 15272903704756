import {callIfFunction, getFetcher, postFetcher} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'
import React, {useRef, useState} from 'react'
import _ from 'lodash/fp'
import {PageContainer} from '../components/PageLayout'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {ACCEPTED, REJECTED, REVIEWED, SUBMITTED} from '../enums/CaseState'
import {StepNavigation} from '../components/StepNavigation'
import {WaitingReview} from './WaitingReview'
import {DeclinedRequest} from './DeclinedRequest'
import {Header} from '../components/Header'
import {ReviewedRequest} from './ReviewedRequest'
import {willUseSuspense} from '../hooks/useSuspense'
import {authGetFetcher, authPostFetcher} from '../utils/authFetch'
import {navigate} from '@reach/router'
import {toLocalState} from '../utils/utils'

const useAuthSuspense = willUseSuspense((url) => authGetFetcher(url,{noCache:Date.now()}))

export const LoggedIn = ({providerInfo}) => {


  const [caseRequest, setCaseRequest] = useState(toLocalState(useAuthSuspense('/api/caseRequest')))
  const validated = useToggle()
  const step = useToggle(caseRequest.state === SUBMITTED ? 5 : 3)
  const stepRef = useRef()

  const confirmAndOpen = (nextStep) => () => {
    if (!stepRef.current) {
      step.openWith(nextStep)
    }
    if (step.isOpen!==nextStep) {
      stepRef.current.confirm()
        .then(step.willOpenWith(nextStep))
    }
  }

  const updatePatientAction = (values) =>
    authPostFetcher("/api/patientInfo", values, {method: "PUT"}).then(() => setCaseRequest(_.set('patientInfo', values)))

  const updateCardAction = (stripeInfo) => authPostFetcher("/api/stripeInfo", stripeInfo, {method: "PUT"})


  const saveCaseInfo = (values) => authPostFetcher("/api/caseInfo", values, {method: "PUT"}).then(() => setCaseRequest(_.set('caseInfo', values)))
  const setMedicalRecords = (values) => setCaseRequest(_.set('medicalRecordsInfo', values))

  const submitRequest = (v) => authPostFetcher("/api/submitCase")
    .then(() => setCaseRequest(_.set('state', SUBMITTED)))

  if (caseRequest.state === ACCEPTED) {
    return <WaitingReview caseRequest={caseRequest}/>
  }
  if (caseRequest.state === REJECTED) {
    return <DeclinedRequest caseRequest={caseRequest} providerInfo={providerInfo}/>
  }
  if (caseRequest.state === REVIEWED) {
    return <ReviewedRequest jwt={window.sessionStorage.getItem("jwt")} caseRequest={_.set(['report','version'],3,caseRequest)} providerInfo={providerInfo}/>
  }

  const withSteps = (children) => <PageContainer>
    <Header caseRequest={caseRequest}/>
    {
      caseRequest.state !== SUBMITTED &&
      <StepNavigation
        toggle={step}
        confirmAndOpen={confirmAndOpen}
        available={caseRequest.state === SUBMITTED ? 5 : callIfFunction(validated.isOpen) ? 4 : 3}
      />
    }
    {children}
  </PageContainer>

  if (step.isOpen === 1) {
    return withSteps(<Step1 ref={stepRef} action={updatePatientAction} onSuccess={step.willOpenWith(2)}
                            values={caseRequest.patientInfo} caseRequest={caseRequest}/>)
  }
  if (step.isOpen === 2) {
    return withSteps(
      <Step2 action={updateCardAction}
             values={{acceptedTerms:true}}
             ref={stepRef}
             onSuccess={(values,{cardInfo}) => { cardInfo && setCaseRequest(_.set('cardInfo',cardInfo)); step.openWith(3)}}
             caseRequest={caseRequest}
             providerInfo={providerInfo}
             patientInfo={caseRequest.patientInfo}
             previousStep={step.willOpenWith(1)}
      />
    )
  }
  if (step.isOpen === 3) {
    if (caseRequest.state !== SUBMITTED) {
      return withSteps(
        <Step3 caseRequest={caseRequest}
               ref={stepRef}
               prevStep={step.willOpenWith(2)}
               nextStep={step.willOpenWith(4)}
               validated={validated}
               saveCaseInfo={saveCaseInfo}
               setMedicalRecords={setMedicalRecords}
        />
      )
    } else {
      return withSteps(
        <Step3 caseRequest={caseRequest}
               ref={stepRef}
               submitRequest={submitRequest}
               prevStep={step.willOpenWith(2)}
               nextStep={step.willOpenWith(6)}
               validated={validated}
               saveCaseInfo={saveCaseInfo}
               setMedicalRecords={setMedicalRecords}
        />
      )
    }
  }
  if (step.isOpen === 4) {
    return withSteps(
      <Step4
        caseRequest={caseRequest}
        ref={stepRef}
        setCardInfo={(cardInfo) => setCaseRequest(_.set('cardInfo',cardInfo))}
        updateCardAction={updateCardAction}
        prevStep={step.willOpenWith(3)}
        nextStep={step.willOpenWith(5)}
        submitRequest={submitRequest}
      />
    )
  }
  if (step.isOpen === 5) {
    return withSteps(
      <Step5
        caseRequest={caseRequest}
        ref={stepRef}
        providerInfo={providerInfo}
        goToEdit={step.willOpenWith(3)}
        goToReview={step.willOpenWith(4)}
      />
    )
  }
  if (step.isOpen === 6) {
    return withSteps(
      <Step5
        caseRequest={caseRequest}
        isUpdate
        ref={stepRef}
        providerInfo={providerInfo}
        goToEdit={step.willOpenWith(3)}
        goToReview={step.willOpenWith(4)}
      />
    )
  }
  return null
}
