import { Icon } from '@startlibs/components';
import {getColor} from '@startlibs/utils';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const InfoBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
  color: ${getColor('gray120')};
  ${props => props.dark && css`
    background: ${getColor('gray150')};
    border-color: ${getColor('gray150')};
    color: white;
  `}
  ${props => props.error && css`
    background: ${props => lighten(0.43, props.theme.colors.alert)};
    border-color: ${props => lighten(0.32, props.theme.colors.alert)};
    color: ${getColor('alert')};
  `}
  ${props => props.withIcon && css`
    display: flex;
    align-items: center;
    ${Icon} {
      font-size: 16px;
      margin-right: .5rem;
    }
  `}
  ${props => props.autoSaveNotice && css`
    margin: 0 0 2rem;
  `}
  span {
    font-weight: 600;
  }
  .patient-name, .contact-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .25rem;
  }
  .diagnosis {
    margin-bottom: .25rem;
  }
  .details {
    display: flex;
    flex-wrap: wrap;
    > div {
      &:nth-child(1) {
        margin-right: 1rem;
        flex-grow: 1;
      }
      &:nth-child(2) {
        align-self: flex-end;
      }
    }
  }
`
