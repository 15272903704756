import { getColor, media } from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';

export const CaseCardStyle = styled.div`
  background-color: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  position: relative;
  .main-content {
    padding: 1rem;
    h3 {
      font-size: 16px;
      font-weight: 600;
      ${media.min(420)`
        padding-right: 140px;
      `}
      margin-bottom: .5rem;
    }
    .label {
      font-weight: 600;
    }
    .status {
      position: absolute;
      top: .5rem;
      right: .5rem;
      background-color: ${getColor('gray240')};
      color: ${getColor('gray120')};
      font-weight: 600;
      padding: .25rem .5rem;
      border-radius: 5px;
    }
  }
  .card-footer {
    padding: 0.5rem 1rem;
    background: rgba(0,0,0,0.075);
    color: ${getColor('gray120')};
    border-top: 1px solid ${getColor('gray210')};
    font-size: 11px;
  }
  ${media.max(420)`
    .main-content .status {
      position: static;
      padding: 0;
      margin-top: .25rem;
      background: transparent;
    }
  `}
`

export const ExpertCaseCard = ({caseInfo}) => <CaseCardStyle>
  <div className="main-content">
    <h3>Case ID: {caseInfo.caseID}</h3>
    <div><span className="label">Patient:</span> {caseInfo.patientName}</div>
    <div><span className="label">Chief Complaint:</span> {caseInfo.chiefComplaint}</div>
    <div className="status">
      {caseInfo.status}
    </div>
  </div>
  <div className="card-footer">
    Created in: {caseInfo.caseDate}
  </div>
</CaseCardStyle>
