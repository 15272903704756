import {Button} from '@startlibs/components'
import {Fill, useToggle} from '@startlibs/core'
import {navigate} from '@reach/router'
import React from 'react'
import {Header} from '../components/Header'
import {
  HeaderButtons,
  PageContainer,
  PageFooter
} from '../components/PageLayout';
import { LogoutButton } from '../components/Navbar';
import {Preview} from './reviewedRequest/Preview'
import {RequestView} from './steps/components/RequestView'

export const ReviewedRequest = ({caseRequest, providerInfo, jwt, associateInfo, isApp, canShare}) => {

  const viewRequest = useToggle(new URLSearchParams(window.location.search).get("to")==="view")

  return <PageContainer noMarginOnPrint appLayout={isApp}>

    <Header caseRequest={caseRequest} title="Expert opinion report" className="hideOnPrint" canShare={canShare} isReport />

    {
      viewRequest.isOpen
      ? <>
          <HeaderButtons>
            <Button small onClick={viewRequest.toggle}>View expert report</Button>
          </HeaderButtons>
          <RequestView caseRequest={caseRequest} associateInfo={associateInfo}/>
          <PageFooter>
            <Button highlight onClick={viewRequest.toggle}>View expert report</Button>
          </PageFooter>
      </>
      :
      <>
        <HeaderButtons className="hideOnPrint">
          <Button small className="check-request" onClick={viewRequest.toggle}>View request</Button>
          <div className="right-wrapper">
            <Button.a small href={`/api/report/download?token=${jwt}&requestId=${caseRequest.requestId}`} icon="download" >Save PDF</Button.a>
            <Button.a small target="_blank" href={`/api/report/preview?token=${jwt}&requestId=${caseRequest.requestId}`} icon="print">Print</Button.a>
          </div>
        </HeaderButtons>
        <Preview reviewedDate={caseRequest.whenReturnedEpochMilli} studyCase={caseRequest.report} organization={{...providerInfo,...providerInfo.address}} checkRequest={viewRequest.open} />
        <PageFooter className="hideOnPrint reviewed-footer">
          <Button onClick={viewRequest.toggle}>View request</Button>
          <div className="right-wrapper">
            <Button.a href={`/api/report/download?token=${jwt}&requestId=${caseRequest.requestId}`}  icon="download" className="pdf-btn">Save PDF</Button.a>
            <Button.a target="_blank" href={`/api/report/preview?token=${jwt}&requestId=${caseRequest.requestId}`} icon="print">Print</Button.a>
          </div>
        </PageFooter>
      </>
    }
    <Fill name="Navbar-Exit">
      <LogoutButton onClick={() => navigate("/access")}>Exit</LogoutButton>
    </Fill>
  </PageContainer>
}
