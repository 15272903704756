import styled, {css} from 'styled-components'
import {getColor} from '@startlibs/utils'
import {Button} from '@startlibs/components'
import lighten from 'polished/lib/color/lighten'

export const StripeInput = styled.div`
  width: 100%;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  background: white;
  position: relative;
  height: 3rem;
  padding: 0.7rem;
  &.StripeElement--focus {
    border-color: ${getColor('gray180')};
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
  }
  &.StripeElement--invalid {
    border-color: ${getColor('alert')} !important;
  }
`

export const CreditCardIcon = styled.img`
  width: 29px;
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
`
