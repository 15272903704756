import { ContextMenu, DropdownIcon, Icon, Li } from '@startlibs/components';
import { formatDate, getColor, media, parseDate } from '@startlibs/utils';
import {navigate} from '@reach/router'
import { usePopupToggle } from '@startlibs/core';
import React from 'react'
import styled from 'styled-components'
import {DATE_FORMAT} from '../../config/DateFormat'
import { StyledTable, StyledTableHead, StyledRow, DropdownButton, TextButton } from '../../components/StyledTable'

export const RequestRow = ({caseRequest, jwt, acceptDialog, declineDialog}) => {

  const view = () => {
  }
  const contextMenu = usePopupToggle()

  const getViewUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.append("viewCase",caseRequest.requestCode)
    return window.location.pathname + "?" + urlParams.toString()
  }

  return <>
    <StyledRow>
      <div className="code">{caseRequest.requestCode}</div>
      <div className="patient-name">{caseRequest.patientInfo.firstName} {caseRequest.patientInfo.lastName}</div>
      <div className="diagnosis">{caseRequest.caseInfo.patientDiagnosis}</div>
      <div className="dob">{formatDate(parseDate(caseRequest.patientInfo.dob,new Date(),"yyyy-MM-dd"),DATE_FORMAT())}</div>
      <div className="date">{formatDate(parseDate(caseRequest.requestDate,new Date(),"yyyy-MM-dd"),DATE_FORMAT())}</div>
      <div className="updated-in">{formatDate(parseDate(caseRequest.whenModified,new Date(),"yyyy-MM-dd"),DATE_FORMAT(),undefined,true)}</div>
      <div className="actions">
        <TextButton onClick={() => navigate(getViewUrl())}>View</TextButton>
        <TextButton onClick={acceptDialog.willOpenWith(caseRequest)}>Accept</TextButton>
        <DropdownButton onClick={contextMenu.open}>
          <Icon icon="arrow-down"/>
          {
            contextMenu.isOpen &&
            <ContextMenu>
              <Li label="Decline request" onClick={declineDialog.willOpenWith(caseRequest)}/>
            </ContextMenu>
          }
        </DropdownButton>
      </div>
    </StyledRow>
  </>
}
