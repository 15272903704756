import { getColor, media } from '@startlibs/utils';
import React from 'react'
import styled, {css} from 'styled-components';

const StepNavigationComponent = styled.div `
  margin-bottom: 3rem;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  user-select: none;
  > * {
    flex-grow: 1;
  }
  @media print {
    display: none;
  }
`
const StepButton = styled.div `
  color: rgba(0,0,0,0.75);
  min-height: 4rem;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0rem 0.25rem 2.5rem;
  text-align: center;
  position: relative;
  :first-child {
    padding-left: 1rem;
  }
  :last-child {
    padding-right: 1rem;
  }
  .text-wrapper {
    position: relative;
    z-index: 3;
  }
  ${props => props.selected && css`
    background: ${getColor('main')};
    color: white;
    ${Arrow} {
      :before {
        background: ${getColor('main')};
      }
    }
  `}
  ${props => props.unavaiable && css`
    background: rgb(245,245,245);
    pointer-events: none;
    color: rgba(0,0,0,0.4);
    ${Arrow} {
      :before {
        background: rgb(245,245,245);
      }
    }
  `}
  ${props => !props.selected && !props.unavaiable && css`
    :hover {
      cursor: pointer;
      background: #d1e6f0;
      ${Arrow} {
        :before {
          background: #d1e6f0;
        }
      }
    }
  `}
  .number {
    &:after {
      content: ') ';
      margin-right: .1rem;
    }
  }
  ${media.max(530)`
    min-width: 4rem;
    .number:after {
      display: none;
    }
    .step-name {
      display: none;
    }
    ${props => props.selected && css`
      .number:after, .step-name {
        display: inline;
      }
    `}
  `}
`
const Arrow = styled.div `
  width: 27px;
  height: 4rem;
  z-index: 2;
  top: 0;
  right: -2rem;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  :before {
    pointer-events: auto;
    content: '';
    width: 4rem;
    height: 4rem;
    left: -32px;
    position: absolute;
    transform: rotate(45deg);
    background: white;
  }
  :after {
    pointer-events: auto;
    content: '';
    width: 4rem;
    height: 4rem;
    left: -31px;
    position: absolute;
    transform: rotate(45deg);
    border: 2px solid ${getColor('gray240')};
  }
`
const lessThan = (min) => (i) => i < min

export const StepNavigation = ({confirmAndOpen,toggle,available,skip = []}) => <StepNavigationComponent>
  {skip.indexOf(1) < 0 && <StepButton onClick={available < 1 ? undefined : confirmAndOpen(1)} selected={toggle.isOpen === 1} unavaiable={available < 1}><div className="text-wrapper"><span className="number">1</span> <span className="step-name">Patient details</span></div><Arrow/></StepButton>}
  {skip.indexOf(2) < 0 && <StepButton onClick={available < 2 ? undefined : confirmAndOpen(2)} selected={toggle.isOpen === 2} unavaiable={available < 2}><div className="text-wrapper"><span className="number">{2 - skip.filter(lessThan(2)).length}</span> <span className="step-name">Payment details</span></div><Arrow/></StepButton>}
  {skip.indexOf(3) < 0 && <StepButton onClick={available < 3 ? undefined : confirmAndOpen(3)} selected={toggle.isOpen === 3} unavaiable={available < 3}><div className="text-wrapper"><span className="number">{3 - skip.filter(lessThan(3)).length}</span> <span className="step-name">Request details</span></div><Arrow/></StepButton>}
  {skip.indexOf(4) < 0 && <StepButton onClick={available < 4 ? undefined : confirmAndOpen(4)} selected={toggle.isOpen === 4} unavaiable={available < 4}><div className="text-wrapper"><span className="number">{4 - skip.filter(lessThan(4)).length}</span> <span className="step-name">Review</span></div><Arrow/></StepButton>}
  {skip.indexOf(5) < 0 && <StepButton onClick={available < 5 ? undefined : confirmAndOpen(5)} selected={toggle.isOpen === 5} unavaiable={available < 5}><div className="text-wrapper"><span className="number">{5 - skip.filter(lessThan(5)).length}</span> <span className="step-name">Request submitted</span></div></StepButton>}
</StepNavigationComponent>
