import React, {useMemo, useState} from 'react'
import {willUseSuspense} from '../hooks/useSuspense'
import {authGetFetcher, jwtGetFetcher} from '../utils/authFetch'
import {useToggle} from '@startlibs/core'
import {ACCEPTED, REJECTED, REVIEWED, SUBMITTED} from '../enums/CaseState'
import {WaitingReview} from '../pages/WaitingReview'
import {DeclinedRequest} from '../pages/DeclinedRequest'
import {ReviewedRequest} from '../pages/ReviewedRequest'
import _ from 'lodash/fp'
import {PageContainer} from '../components/PageLayout'
import {Step4} from '../pages/steps/Step4'
import {Step5} from '../pages/steps/Step5'
import {navigate} from '@reach/router'
import {toLocalState, tryOrFail} from '../utils/utils'
import {Header} from '../components/Header'



const getJWT = () => window.sessionStorage.getItem('sharejwt')
const useShareSuspense = willUseSuspense((url) => jwtGetFetcher(getJWT())(url,{noCache:Date.now()}))

export const SharedCase = ({token,providerInfo}) => {

  const shareData = window.sessionStorage.getItem('sharedata')
  const sharejwt = window.sessionStorage.getItem('sharejwt')
  if (!shareData || !sharejwt) {
    navigate('/share/'+token)
    return null
  }
  const {reportJWT,sharingOptions} = tryOrFail(() => JSON.parse(shareData),() => navigate('/'))
  if (!reportJWT) {
    navigate('/share/'+token)
    return null
  }

  const [caseRequest, setCaseRequest] = useState(toLocalState(useShareSuspense('/api/caseRequest')))

  const reloadCase = () =>
    jwtGetFetcher(getJWT())('/api/caseRequest',{noCache:Date.now()}).then(setCaseRequest)

  const step = useToggle(new URLSearchParams(window.location.search).get("to")==="check" ? 5 : 4)

  if (caseRequest.state === ACCEPTED) {
    return <WaitingReview isSharedAccess caseRequest={caseRequest} reloadCase={reloadCase} canShare={false}/>
  }
  if (caseRequest.state === REJECTED) {
    return <DeclinedRequest isSharedAccess caseRequest={caseRequest} providerInfo={providerInfo} canShare={false}/>
  }
  if (caseRequest.state === REVIEWED) {
    return <ReviewedRequest isSharedAccess jwt={getJWT()} caseRequest={_.set(['report','version'],3,caseRequest)} providerInfo={providerInfo} canShare={false}/>
  }


  if (step.isOpen === 4) {
    return <PageContainer>
      <Header caseRequest={caseRequest} canShare={false}/>
      <Step4
        isSharedAccess
        canShare={false}
        caseRequest={caseRequest}
        setCardInfo={(cardInfo) => setCaseRequest(_.set('cardInfo',cardInfo))}
        nextStep={step.willOpenWith(5)}
        allowUpload={WithUploaderToken}
        getJwt={getJWT}
        allowSubmit={!!_.get('allowSubmit',sharingOptions)}
        setMedicalRecords={() => setCaseRequest(_.set('medicalRecords',caseRequest))}
      />
    </PageContainer>
  }
  if (step.isOpen === 5) {
    return <PageContainer>
      <Header caseRequest={caseRequest} canShare={false}/>
      <Step5
        isSharedAccess
        goToEdit={step.willOpenWith(4)}
        canShare={false}
        caseRequest={caseRequest}
        providerInfo={providerInfo}
        goToReview={step.willOpenWith(4)}
      />
    </PageContainer>
  }

}

export const WithUploaderToken = ({children,requestId}) => {
  const {jwt} = useShareSuspense('/api/uploaderToken'+(requestId ? "?requestId="+requestId : ""))
  return children(jwt)
}
