import React from 'react'
import {Button} from '@startlibs/components'
import styled, {css} from 'styled-components'
import {getColor} from '@startlibs/utils'
import {willUseSuspense} from '../../../hooks/useSuspense'
import {authGetFetcher} from '../../../utils/authFetch'

const CurrentCreditCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${Button} {
    margin: 0.5rem 0;
    flex-shrink: 0;
  }
`

const CurrentCreditCardIcon = styled.img`
  width: 29px;
  margin-right: 1rem;
  vertical-align: text-bottom;
  display: inline-block;
`

const CurrentCreditCardNumber = styled.div`
  font-size: 14px;
  margin: 0.5rem 1rem 0.5rem 0;
  color: ${getColor('gray90')};
  flex-shrink: 0;
  span {
    font-size: 18px;
    vertical-align: -2px;
  }
`
const useAuthSuspense = willUseSuspense((url) => authGetFetcher(url))

export const CurrentCardDetails = ({changeCard,caseRequest}) => {

  const {cardInfo} = caseRequest

  return <>
    <h4>Credit card being charged:</h4>
    <CurrentCreditCardContainer>
      <CurrentCreditCardNumber><CurrentCreditCardIcon
        src={`/public/icon/${cardInfo.brand.toLowerCase()}.svg`}/><span>•••• •••• •••• </span>{cardInfo.lastDigits}
      </CurrentCreditCardNumber>
      <Button small onClick={changeCard.open}>Change credit card</Button>
    </CurrentCreditCardContainer>
  </>
}