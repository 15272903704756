import {Button, ContextMenu, Icon, Li} from '@startlibs/components';
import {formatDate, getColor, media} from '@startlibs/utils';
import {lighten} from 'polished';
import {usePopupToggle} from '@startlibs/core'
import React from 'react'
import styled, {css} from 'styled-components';
import {ACCEPTED, NOT_SUBMITTED, REJECTED, REVIEWED, SUBMITTED} from '../../enums/CaseState'
import {DATE_FORMAT} from '../../config/DateFormat'
import {NotProvided} from '../../pages/steps/components/RequestView';
import {useShareDialog} from '../../hooks/useShareDialog'

const StatusLabel = styled.div``

export const AssociatesRequestCardStyle = styled.div`
  background-color: white;
  border-radius: 5px;
  flex-grow: 1;
  flex-basis: 32.5%;
  min-width: 290px;
  max-width: calc(50% - 1rem);
  margin: .5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid ${getColor('gray210')};
  color: ${getColor('gray90')};
  ${props => props.recentlySaved && css`
    &:after {
      display: block;
      animation-name: thisSaved;
      animation-duration: 2.5s;
      position: absolute;
      top: 3.5rem;
      right: -6rem;
      background-color: ${getColor('success')};
      content: 'Saved';
      color: white;
      padding: 3px 1rem;
      border-radius: 5px;
      @keyframes thisSaved {
        0% {right: -6rem}
        25% {right: 1rem;}
        75% {right: 1rem;}
        100% {right:-6rem;}
      }
    }
  `}
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    content: '';
    ${props => props.status === NOT_SUBMITTED && css`background-color: ${getColor('gray150')};`};
    ${props => props.status === SUBMITTED && css`background-color: ${getColor('warning')};`};
    ${props => props.status === ACCEPTED && css`background-color: ${getColor('main')};`};
    ${props => props.status === REVIEWED && css`background-color: ${getColor('success')};`};
    ${props => props.status === REJECTED && css`background-color: ${getColor('alert')};`};
  }
  &:only-child {
    max-width: 33%
    min-width: 310px;
  }
  .mobile-visible {
    display: none;
  }
  ${media.min(1476)`
    max-width: 32.5%;
    &:only-child {
      max-width: 33%;
    }
  `}
  ${media.max(840)`
    max-width: 100%;
    flex-basis: 100%;
    &:only-child {
      max-width: 100%;
    }
  `}
  ${media.max(480)`
    .mobile-hidden {
      display: none;
    }
    .mobile-visible {
      display: inline-block;
    }
    min-width: 280px;
  `}
  .main-content {
    padding: 1rem;
    .card-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .5rem;
      h3 {
        margin: 0 1rem 0 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        ${Icon} {
          color: ${getColor('gray180')};
          margin-right: .5rem;
        }
      }
      .right-container {
        display: flex;
        flex-shrink: 0;
        ${StatusLabel} {
          background-color: ${getColor('gray240')};
          color: ${getColor('gray120')};
          font-weight: 600;
          padding: .25rem .5rem;
          border-radius: 5px;
          margin-right: .5rem;
          display: inline-flex;
          align-items: center;
          line-height: 13px;
          ${props => props.status === NOT_SUBMITTED && css`
            color: ${getColor('gray120')};
            background-color:  ${getColor('gray240')};
          `};
          ${props => props.status === SUBMITTED && css`
            color: ${getColor('warning')};
            background-color: ${lighten(0.4, props.theme.colors.warning)};
          `};
          ${props => props.status === ACCEPTED && css`
            color: ${getColor('main')};
            background-color: ${lighten(0.55, props.theme.colors.main)};
          `};
          ${props => props.status === REVIEWED && css`
            color: ${getColor('success')};
            background-color: ${lighten(0.5, props.theme.colors.success)};
          `};
          ${props => props.status === REJECTED && css`
            color: ${getColor('alert')};
            background-color: ${lighten(0.5, props.theme.colors.alert)};
          `};
          ${Icon} {
            font-size: 16px;
            & ~ .mobile-hidden {
              margin-left: .25rem;
            }
          }
        }
        .dropdown {
          margin-left: .5rem;
          ${Icon} {
            margin: 0 -5px;
            font-size: 13px;
          }
        }
      }
    }
    .empty-details {
      font-style: italic;
      color: ${getColor('gray180')};
    }
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background: rgba(0,0,0,0.075);
    color: ${getColor('gray120')};
    border-top: 1px solid ${getColor('gray210')};
    font-size: 11px;
    .date {
      margin-right: 1rem;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      .nowrap {
        margin-right: .25rem;
      }
    }
    .code {
      text-align: right;
      .label {
        white-space: nowrap;
      }
    }
  }
`


export const AssociatesRequestCard = ({request, className, recentlySaved}) => {

  const contextMenu = usePopupToggle()
  const [openShareDialog, shareDialog] = useShareDialog(request)

  const statusLabel = {
    [NOT_SUBMITTED]: () => <StatusLabel><Icon icon="edit-box"/> <span
      className="mobile-hidden">Draft</span></StatusLabel>,
    [SUBMITTED]: () => <StatusLabel><Icon icon="arrow-right-line"/> <span
      className="mobile-hidden">Requested</span></StatusLabel>,
    [ACCEPTED]: () => <StatusLabel><Icon icon="clock"/> <span
      className="mobile-hidden">Under review</span></StatusLabel>,
    [REJECTED]: () => <StatusLabel><Icon icon="unavailable"/> <span
      className="mobile-hidden">Declined</span></StatusLabel>,
    [REVIEWED]: () => <StatusLabel><Icon icon="check"/> <span className="mobile-hidden">Reviewed</span></StatusLabel>
  }[request.state]

  const footerContent = {
    [NOT_SUBMITTED]: () => <>
      <span className="nowrap"
            css="margin-right: .5rem;">Created in: {formatDate(new Date(request.whenCreatedEpochMilli), DATE_FORMAT(), undefined, true)}</span>
      <span>Last modified in: {formatDate(new Date(request.whenModifiedEpochMilli), DATE_FORMAT() + ' at hh:mm', undefined, true)}</span>
    </>,
    [SUBMITTED]: () => <>
      <span className="nowrap">Submitted in:</span>
      <span>{formatDate(new Date(request.whenSubmittedEpochMilli), DATE_FORMAT() + ' at hh:mm', undefined, true)}</span>
    </>,
    [ACCEPTED]: () => <>
      <span className="nowrap">Submitted in:</span>
      <span>{formatDate(new Date(request.whenSubmittedEpochMilli), DATE_FORMAT() + ' at hh:mm', undefined, true)}</span>
    </>,
    [REJECTED]: () => <>
      <span className="nowrap">Rejected in:</span>
      <span>{formatDate(new Date(request.whenModifiedEpochMilli), DATE_FORMAT() + ' at hh:mm', undefined, true)}</span>
    </>,
    [REVIEWED]: () => <>
      <span className="nowrap">Report received in:</span>
      <span>{formatDate(new Date(request.whenReturnedEpochMilli), DATE_FORMAT() + ' at hh:mm', undefined, true)}</span>
    </>
  }[request.state]

  return <AssociatesRequestCardStyle recentlySaved={recentlySaved} status={request.state}>
    <div className="main-content">
      <div className="card-heading">
        <h3>
          <span className="mobile-hidden">Patient: </span>
          <Icon icon="user" className="mobile-visible"/>
          {request.patientInfo.firstName} {request.patientInfo.lastName}</h3>
        <div className="right-container">
          {statusLabel()}
          {
            request.state === NOT_SUBMITTED || request.state === SUBMITTED
              ? <Button.Link small to={'/associate/caseRequest/' + request.requestId}><span>Edit</span><span
                className="mobile-hidden"> request</span></Button.Link>
              : (
                request.state === REVIEWED
                  ? <Button.Link small to={'/associate/caseRequest/' + request.requestId + "?to=report"}><span>View</span><span
                    className="mobile-hidden"> report</span></Button.Link>
                  : <Button.Link small
                                 to={'/associate/caseRequest/' + request.requestId + "?to=view"}><span>View</span><span
                    className="mobile-hidden"> request</span></Button.Link>
              )
          }
          <Button small icon="arrow-down" className="dropdown" onClick={contextMenu.open}>
            {
              contextMenu.isOpen &&
              <ContextMenu>
                <Li label="Share case" onClick={openShareDialog}/>
                {
                  (request.state === REVIEWED || request.state === SUBMITTED) &&
                  <Li label="View request" icon="view"
                      linkTo={'/associate/caseRequest/' + request.requestId + '?to=view'}/>
                }
                {
                  request.state !== REVIEWED && request.state !== NOT_SUBMITTED &&
                  <Li label="Check status" linkTo={'/associate/caseRequest/' + request.requestId + '?to=check'}/>
                }
              </ContextMenu>
            }
          </Button>
        </div>
      </div>
      {shareDialog}
      {
        request.caseInfo ? <>
            <div className="diagnosis">
              <strong>Chief complaint:</strong> {request.caseInfo.patientDiagnosis ? request.caseInfo.patientDiagnosis :
              <NotProvided/>}
            </div>
          </>
          :
          <span className="empty-details">Request details not yet provided.</span>
      }
    </div>
    <div className="card-footer">
      <div className="date">
        {footerContent()}
      </div>
      <div className="code">
        <span className="label">Request code: </span> {request.requestCode}
      </div>
    </div>
  </AssociatesRequestCardStyle>
}
