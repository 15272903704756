import React from 'react'
import {navigate} from '@reach/router'
import {
  CardActionButton,
  CardHeader,
  SignInCard,
  SignInLayout,
  PurviewFooter,
  AdditionalActionButtons
} from '../components/SigninLayout'
import {WithForm, TextInput, Errors, DatePicker} from '@startlibs/form'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {formatDate, formFetch} from '@startlibs/utils'
import {Button} from '@startlibs/components'
import {useClearSession} from '../hooks/useClearSession'
import {buildValidation, required, responseFailure} from '../utils/validation'
import {DATE_FORMAT} from '../config/DateFormat'
import {useConstant} from '@startlibs/core'

const transformDoB = _.update('dob', (dob) => {
  return formatDate(dob, 'yyyy-MM-dd')
})

export const ACCESS_ERROR = 'Invalid patient data. Please check the fields above and try again.'


export const Login = ({providerInfo, navigate, location}) => {
  const requestCode = new URLSearchParams(window.location.search).get('code') || ''
  const DEFAULT_VALUES = useConstant({dob: undefined, lastName: '', requestCode})

  const preValidation = buildValidation({
    requestCode: [required],
    dob: [required],
    lastName: [required]
  })

  useClearSession()

  const onSuccess = (req, jwt) => {
    window.sessionStorage.setItem('jwt', jwt)
    navigate('/expert-opinion')
  }

  const onFailure = responseFailure((data, {status}) =>
    (status === 401 && {
      'lastName': ACCESS_ERROR,
      'dob': ACCESS_ERROR
    }) ||
    (status === 404 && {'requestCode': ['We couldn\'t find the access code in question. Please review it and try again.']})
  )

  return <SignInLayout providerInfo={providerInfo}>
    <SignInCard>
      <CardHeader>
        <h1>Expert View</h1>
        <p css="max-width:22rem;margin:1rem auto 1rem;">To access an existing expert opinion request enter that info
          here.</p>
      </CardHeader>
      <WithForm
        alwaysSave
        transform={transformDoB}
        preValidation={preValidation}
        onSuccess={onSuccess}
        onFailure={onFailure}
        values={DEFAULT_VALUES}
        action={formFetch('/api/authenticate')}
      >{form => <>
        <TextInput
          label="Expert request code"
          placeholder="Ex: 000000000"
          path="requestCode"
          disabled={!!requestCode}
        />
        <TextInput
          label="Patient last name"
          placeholder="Ex: Smith"
          path="lastName"
        />
        <DatePicker
          weekStartsMonday
          label="Patient date of birth"
          placeholder={`Ex: ${DATE_FORMAT().toUpperCase()}`}
          format={DATE_FORMAT()}
          path="dob"
        />
        <Errors/>
        <CardActionButton highlight isLoading={form.isLoading} type="submit">Access expert opinion
          request</CardActionButton>
      </>}</WithForm>
    </SignInCard>
    <AdditionalActionButtons>
      <p>Looking to obtain a new expert opinion from a healthcare professional?</p>
      <Button onClick={() => navigate('/start')}>Request expert opinion</Button>
    </AdditionalActionButtons>
  </SignInLayout>
}
