import React, {useRef, useState} from 'react'
import {Button} from '@startlibs/components'
import {willUseSuspense} from '../hooks/useSuspense'
import {authGetFetcher, authPostFetcher} from '../utils/authFetch'
import _ from 'lodash/fp'
import {useToggle} from '@startlibs/core'
import {ACCEPTED, NOT_SUBMITTED, REJECTED, REVIEWED, SUBMITTED} from '../enums/CaseState'
import {WaitingReview} from '../pages/WaitingReview'
import {DeclinedRequest} from '../pages/DeclinedRequest'
import {ReviewedRequest} from '../pages/ReviewedRequest'
import {PageContainer} from '../components/PageLayout'
import {Header} from '../components/Header'
import {StepNavigation} from '../components/StepNavigation'
import {callIfFunction} from '@startlibs/utils'
import {Step1} from '../pages/steps/Step1'
import {Step2} from '../pages/steps/Step2'
import {Step3} from '../pages/steps/Step3'
import {Step4} from '../pages/steps/Step4'
import {Step5} from '../pages/steps/Step5'
import {useRecentlySaved} from './hooks/RecentlySaved'

const useAuthSuspense = willUseSuspense((url) => authGetFetcher(url))

const EMPTY_CASE_INFO = {
  patientDiagnosis: "",
  clinicalSummary: "",
  questionsConsultant: [{id: Date.now() + "", answer: '', question: ''}],
  version: 3
}

const CHECKOUT = [2]

const toLocalState = caseRequest => _.flow(
  _.update('caseInfo', (c) => c ? _.set('version', 3, c) : EMPTY_CASE_INFO),
  _.set(['patientInfo', 'confirmEmail'], _.get(['patientInfo', 'email'], caseRequest))
)(caseRequest)

export const AssociatesCaseRequest = ({providerInfo,requestCode, requestId, associateInfo}) => {
  const requestIdParam = 'requestId='+requestId


  const [caseRequest, setCaseRequest] = useState(toLocalState(useAuthSuspense('/api/caseRequest?'+requestIdParam)))
  const validated = useToggle()
  const step = useToggle(
    caseRequest.state === NOT_SUBMITTED ? 3 :
      new URLSearchParams(window.location.search).get("to")==="view" ? 4 :
      new URLSearchParams(window.location.search).get("to")==="check" ? 5 : 3
  )
  const stepRef = useRef()

  const confirmAndOpen = (nextStep) => () => {
    if (!stepRef.current) {
      step.openWith(nextStep)
    }
    if (step.isOpen!==nextStep) {
      stepRef.current.confirm()
        .then(step.willOpenWith(nextStep))
    }
  }

  const updatePatientAction = (values) =>
    authPostFetcher("/api/patientInfo?"+requestIdParam, values, {method: "PUT"}).then(() => setCaseRequest(_.set('patientInfo', values)))


  const saveCaseInfo = (values) => authPostFetcher("/api/caseInfo?"+requestIdParam, values, {method: "PUT"}).then(() => setCaseRequest(_.set('caseInfo', values)))
  const setMedicalRecords = (values) => setCaseRequest(_.set('medicalRecordsInfo', values))

  const submitRequest = (v) => authPostFetcher("/api/submitCase?"+requestIdParam)
    .then(() => setCaseRequest(_.set('state', SUBMITTED)))

  if (caseRequest.state === ACCEPTED) {
    return <WaitingReview caseRequest={caseRequest} isAssociate associateInfo={associateInfo} />
  }
  if (caseRequest.state === REJECTED) {
    return <DeclinedRequest caseRequest={caseRequest} providerInfo={providerInfo} isAssociate associateInfo={associateInfo}/>
  }
  if (caseRequest.state === REVIEWED) {
    return <ReviewedRequest jwt={window.sessionStorage.getItem("jwt")} caseRequest={_.set(['report','version'],3,caseRequest)} providerInfo={providerInfo} isAssociate associateInfo={associateInfo}/>
  }

  const withSteps = (children) => <PageContainer>
    <Header caseRequest={caseRequest}/>
    {
      caseRequest.state !== SUBMITTED &&
      <StepNavigation
        toggle={step}
        confirmAndOpen={confirmAndOpen}
        skip={CHECKOUT}
        available={caseRequest.state === SUBMITTED ? 5 : callIfFunction(validated.isOpen) ? 4 : 3}
      />
    }
    {children}
  </PageContainer>

  if (step.isOpen === 1) {
    return withSteps(<Step1 isAssociate ref={stepRef} action={updatePatientAction} onSuccess={step.willOpenWith(3)}
                            values={caseRequest.patientInfo} caseRequest={caseRequest} requestId={requestId}/>)
  }
  if (step.isOpen === 3) {
    if (caseRequest.state !== SUBMITTED) {
      return withSteps(
        <Step3 caseRequest={caseRequest}
               requestId={requestId}
               ref={stepRef}
               prevStep={step.willOpenWith(1)}
               nextStep={step.willOpenWith(4)}
               validated={validated}
               saveCaseInfo={saveCaseInfo}
               setMedicalRecords={setMedicalRecords}
               isAssociate
        />
      )
    } else {
      return withSteps(
        <Step3 caseRequest={caseRequest}
               ref={stepRef}
               submitRequest={submitRequest}
               prevStep={step.willOpenWith(1)}
               nextStep={step.willOpenWith(5)}
               validated={validated}
               saveCaseInfo={saveCaseInfo}
               setMedicalRecords={setMedicalRecords}
               isAssociate

        />
      )
    }
  }
  if (step.isOpen === 4) {
    return withSteps(
      <Step4
        caseRequest={caseRequest}
        ref={stepRef}
        prevStep={step.willOpenWith(3)}
        nextStep={step.willOpenWith(5)}
        submitRequest={submitRequest}
        associateInfo={associateInfo}
        isAssociate
      />
    )
  }
  if (step.isOpen === 5) {
    return withSteps(
      <Step5
        caseRequest={caseRequest}
        ref={stepRef}
        providerInfo={providerInfo}
        goToEdit={step.willOpenWith(3)}
        goToReview={step.willOpenWith(4)}
        isAssociate
        associateInfo={associateInfo}
      />
    )
  }
  return null
}
