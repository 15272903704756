import React, {useMemo, useState} from 'react'
import _ from 'lodash/fp'
import {useToggle} from '@startlibs/core'
import {formatDate, parseDate} from '@startlibs/utils'
import {TextInput} from '@startlibs/form'
import { BoxWrapperHeading, BoxPageWrapper, BoxPageLayout } from '../components/BoxPageLayout'
import {RequestRow} from './components/RequestRow'
import { StyledTable, StyledTableHead, StyledRow, DropdownButton, TextButton } from '../components/StyledTable'
import {ConfirmAcceptDialog} from './components/ConfirmAcceptDialog'
import {ConfirmDeclineDialog} from './components/ConfirmDeclineDialog'
import {ViewRequest} from './ViewRequest'
import styled from 'styled-components'
import {getColor, media} from '@startlibs/utils';
import {Icon} from '@startlibs/components'
import {willUseSuspense} from '../hooks/useSuspense'
import {authGetFetcher, iframeAuthGetFetcher} from '../utils/authFetch'
import { CMSBackground } from '../components/PageLayout';
import {useNotification} from '../components/Notifications'
import {navigate} from '@reach/router'
import {DATE_FORMAT} from '../config/DateFormat'

const RequestsPage = styled(BoxPageLayout)`
  ${StyledRow} {
    > div {
      padding: 1rem .75rem;
      flex-basis: 15%;
      &.code {
        flex-basis: 100px;
        min-width: 85px;
      }
      &.patient-name, &.diagnosis {
        flex-grow: 1;
        flex-basis: 200px;
      }
      &.dob {
        flex-basis: 100px;
        min-width: 85px;
      }
      &.updated-in {
        flex-basis: 110px;
        min-width: 100px;
      }
      &.date {
        flex-basis: 100px;
        min-width: 90px;
      }
      &.actions {
        flex-basis: 160px;
        min-width: 160px;
        display: flex;
        justify-content: space-between;
        word-break: keep-all;
      }
    }
  }
  ${media.max(739)`
    ${StyledRow} {
      > div {
        padding: 0;
        &:before {
          font-weight: 600;
          display: block;
        }
        &.code {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          border-radius: 5px;
          background-color: rgba(0,0,0,0.05);
          padding: .25rem .5rem;
          text-align: right;
          &:before {
            content: "Request code: ";
            font-size: 12px;
          }
        }
        &.patient-name {
          font-size: 1.75rem;
          margin-bottom: .75rem;
          padding-right: 110px;
          &:before {
            content: "Patient name: ";
            font-size: 13px;
            display: block;
          }
        }
        &.diagnosis {
          margin-bottom: .75rem;
          &:before {
            content: "Chief complaint: ";
          }
        }
        &.dob {
          display: inline-block;
          float: left;
          padding-bottom: 1.5rem;
          &:before {
            content: "Date of birth: ";
          }
        }
        &.date {
          display: inline-block;
          text-align: right;
          float: right;
          padding-bottom: 1.5rem;
          &:before {
            content: "Request date: ";
          }
        }
        &.updated-in {
          clear: both;
          display: block;
          padding-bottom: 1.5rem;
          &:before {
            content: "Last updated: ";
          }
        }
        &.actions {
          display: flex;
          padding: 0.5rem;
          margin: 0 -1.5rem;
          background: rgba(41, 122, 163, 0.07);
          clear: both;
          > * {
            flex-grow: 1;
            text-align: center;
            flex-shrink: 0;
          }
        }
      }
    }
  `}
  ${media.max(520)`
    ${StyledRow} {
      > div {
        &.code {
          position: static;
          text-align: left;
          padding: 0 0 .25rem;
          border-bottom: 1px solid ${getColor('gray210')};
          margin-bottom: 1rem;
          background: transparent;
          border-radius: 0;
          &:before {
            display: inline;
          }
        }
        &.patient-name {
          padding-right: 0;
        }
      }
    }
  `}
`

export const SearchInput = styled.div`
  width: 20rem;
  position: relative;
  ${Icon} {
    font-size: 20px;
    color: rgba(0,0,0,0.25);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
  }
  input {
    padding-left: 2.5rem;
  }
  ${media.max(520)`
    width: 100%;
  `}
`

export const EmptyListPlaceholder = styled.div `
  padding: 5rem 1rem;
  text-align: center;
  background-color: rgba(0,0,0,0.05);
  border-radius: 5px;
  color: rgba(0,0,0,0.6);
  font-size: 16px;
  font-weight: 600;
  margin-top: 1.5rem;
  border: 1px solid ${getColor('gray210')};
`

const useAuthSuspense = willUseSuspense((url) => iframeAuthGetFetcher(url))

export const RequestList = () => {

  const jwt = new URLSearchParams(window.location.search).get("jwt")
  if (process.env.NODE_ENV === 'development') {
    const DEV_JWT = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjM4MDM4NzEsImlhdCI6MTU2MzgwMzkzMSwicHJvdmlkZXJOYW1lIjoiZGVtbyJ9.iZNwLcySmv1D8eGhDltksDHXuVAASJmwvyg0vYRZhO8"
    if (!jwt) {
      window.location.href = window.location.pathname + (window.location.search ? window.location.search + "&jwt="+DEV_JWT : ("?jwt="+DEV_JWT ))
    }
  }
  const [requests,setRequests] = useState(useAuthSuspense("/api/submittedCaseRequests"))

  const declineDialog = useToggle()
  const acceptDialog = useToggle()
  const [,setNotifications] = useNotification()

  const navigateToList = () => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("viewCase")
    navigate(window.location.pathname + "?" + urlParams.toString())
  }

  const acceptedCase = (requestCode) => {
    navigateToList()
    acceptDialog.close()
    setRequests(_.differenceBy(_.property('requestCode'), _,[{requestCode}]))
  }

  const declinedCase = (requestCode) => {
    navigateToList()
    declineDialog.close()
    setRequests(_.differenceBy(_.property('requestCode'), _ , [{requestCode}]))
    setNotifications("Request declined successfully")
  }

  const query = useToggle("")

  const queriedRequests = useMemo(() => {
    const toDateStr = (date) => formatDate(parseDate(date,new Date(),"yyyy-MM-dd"),DATE_FORMAT(),undefined,true)
    if (query.isOpen) {
      return requests.filter(r => [r.requestCode,toDateStr(r.requestDate),r.caseInfo.patientDiagnosis,toDateStr(r.patientInfo.dob),r.patientInfo.firstName + " " + r.patientInfo.lastName].join("      ").toLowerCase().indexOf(query.isOpen.toLowerCase())>=0)
    } else {
      return requests
    }
  },[query.isOpen,requests])

  const viewCaseCode = new URLSearchParams(window.location.search).get("viewCase")
  if (viewCaseCode) {
    return <ViewRequest code={viewCaseCode} acceptedCase={() => acceptedCase(viewCaseCode)} declinedCase={() => declinedCase(viewCaseCode)} jwt={jwt}/>
  }


  return <RequestsPage>
    <CMSBackground/>
    <BoxPageWrapper>
      <BoxWrapperHeading>
        <h2>Case requests</h2>
      </BoxWrapperHeading>
      <SearchInput>
        <Icon icon="search" />
        <TextInput placeholder="Search requests"
          raw
          value={query.isOpen}
          setValue={(value) => query.openWith(value)}
        />
      </SearchInput>
      {
        queriedRequests.length > 0 && requests.length > 0 ?
        <StyledTable>
          <StyledTableHead>
              <div className="code">Request code</div>
              <div className="patient-name">Patient name</div>
              <div className="diagnosis">Chief complaint</div>
              <div className="dob">Date of birth</div>
              <div className="date">Request date</div>
              <div className="updated-in">Last updated</div>
              <div className="actions"/>
          </StyledTableHead>
            {
              queriedRequests.map(request => <RequestRow
                key={request.requestCode}
                caseRequest={request}
                acceptDialog={acceptDialog}
                declineDialog={declineDialog}
              />)
            }
        </StyledTable>
        : (
          <EmptyListPlaceholder>
            { requests.length > 0 && queriedRequests.length === 0 && <span>No pending requests matching your search criteria.</span>}
            { requests.length === 0 && <span>No pending requests remaining to accept or decline.</span>}
          </EmptyListPlaceholder>
        )
      }
    </BoxPageWrapper>

    {
      acceptDialog.isOpen &&
      <ConfirmAcceptDialog closeDialog={acceptDialog.close} caseRequest={acceptDialog.isOpen} onSuccess={() => acceptedCase(acceptDialog.isOpen.requestCode)}/>
    }
    {
      declineDialog.isOpen &&
      <ConfirmDeclineDialog closeDialog={declineDialog.close} caseRequest={declineDialog.isOpen} onSuccess={() => declinedCase(declineDialog.isOpen.requestCode)} />
    }
  </RequestsPage>
}
