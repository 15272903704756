import {Button, ContextMenu, Icon, Li, Link} from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import {formatDate, media} from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  BoxWrapperHeading,
  BoxPageWrapper,
  BoxPageLayout
} from '../components/BoxPageLayout';
import {CMSBackground} from '../components/PageLayout';
import {DATE_FORMAT} from '../config/DateFormat'
import {
  EmptyListPlaceholder,
  SearchInput
} from '../administrator/RequestList';
import { NotProvided } from '../pages/steps/components/RequestView';
import {Pagination} from '../components/Pagination'
import {StyledTable, StyledTableHead, StyledRow, DropdownButton, TextButton} from '../components/StyledTable'
import {useDeleteExpertDialog} from './hooks/useDeleteExpertDialog'
import {useResetExpertPasswordDialog} from './hooks/useResetExpertPasswordDialog'
import {filterExpert} from '../utils/utils'

const ExpertBrowserPage = styled(BoxPageLayout)`
  .heading-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${StyledTable} {
    overflow: visible;
  }
  ${StyledTableHead} {
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  ${StyledRow} {
    > div {
      &.name {
        flex-basis: 230px;
        min-width: 190px;
        max-width: 250px;
      }
      &.email {
        flex-grow: 1;
        flex-basis: 180px;
        min-width: 160px;
      }
      &.date-added {
        flex-basis: 100px;
        min-width: 100px;
      }
      &.cases-count {
        flex-basis: 90px;
        min-width: 54px;
      }
      &.actions {
        flex-basis: 160px;
        min-width: 230px;
        .wide-only {
          margin-left: .25rem;
        }
      }
    }
    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    ${media.max(960)`
      > div.actions {
        min-width: 160px;
      }
      .wide-only {
        display: none;
      }
    `}
  }
  ${media.max(739)`
    ${StyledRow} {
      > div {
        &.cases-count {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          border-radius: 5px;
          background-color: rgba(0,0,0,0.05);
          padding: .25rem .5rem;
          text-align: right;
          &:before {
            content: "Open cases: ";
            font-size: 13px;
            display: inline;
          }
        }
        &.name {
          font-size: 1.75rem;
          margin-bottom: .75rem;
          padding-right: 110px;
          max-width: 100%;
          &:before {
            content: "Expert name: ";
            font-size: 13px;
            display: block;
          }
        }
        &.email {
          margin-bottom: .75rem;
          &:before {
            content: "Expert email: ";
          }
        }
        &.date-added {
          display: inline-block;
          float: left;
          padding-bottom: 1.5rem;
          &:before {
            content: "Added in: ";
          }
        }
      }
  `}
  ${media.max(520)`
    .heading-actions {
      flex-wrap: wrap;
      ${Button} {
        margin-top: 1rem;
        margin-left: auto;
      }
    }
    ${StyledRow} {
      > div {
        &.cases-count {
          position: static;
          text-align: right;
          padding: 0 0 .25rem;
          margin-bottom: 1rem;
          background: transparent;
          border-radius: 0;
          &:before {
            display: block;
          }
        }
        &.name {
          padding-right: 0;
        }
      }
    }
  `}
}
`

export const PaginationWrapper = styled.div`
  margin-top: 1.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ExpertList = ({experts}) => {

  const query = useToggle('')
  const pageToggle = useToggle(1)

  const filteredExperts = useMemo(() =>
      query.isOpen
        ? experts.filter(filterExpert(query.isOpen))
        : experts
    , [query.isOpen, experts])

  return <ExpertBrowserPage>
    <CMSBackground/>
    <BoxPageWrapper>
      <BoxWrapperHeading>
        <h2><Icon icon="physician"/> Experts</h2>
      </BoxWrapperHeading>
      <div className="heading-actions">
        <SearchInput>
          <Icon icon="search"/>
          <TextInput raw value={query.isOpen} setValue={(v) => { pageToggle.openWith(1); query.openWith(v)}} placeholder="Search by name or email"/>
        </SearchInput>
        <Button.Link highlight icon="plus-circle" to="new">Add new expert</Button.Link>
      </div>
      {
        filteredExperts.length > 0 && experts.length > 0 ?
          <StyledTable>
            <StyledTableHead>
              <div className="name">Expert name</div>
              <div className="email">Expert email</div>
              <div className="date-added">Added in</div>
              <div className="cases-count">Open cases</div>
              <div className="actions"/>
            </StyledTableHead>
            {
              filteredExperts.slice((pageToggle.isOpen-1)*10,pageToggle.isOpen*10).map(expert => <ExpertRow expert={expert} key={expert.id}/>)
            }
          </StyledTable>
          : (
            <EmptyListPlaceholder>
              {experts.length > 0 && filteredExperts.length === 0 &&
              <span>There are no experts matching your search criteria.</span>}
              {experts.length === 0 && <span>There are no experts registered in this system yet.</span>}
            </EmptyListPlaceholder>
          )
      }
      <PaginationWrapper>
        <Pagination setPageParam={(page) => () => pageToggle.openWith(page)}
                    currentPage={pageToggle.isOpen}
                    totalPages={Math.ceil((Number(filteredExperts.length)) / 10)}/>
      </PaginationWrapper>
    </BoxPageWrapper>
  </ExpertBrowserPage>
}

const ExpertRow = ({expert}) => {
  const [openResetPassword, resetPasswordDialog] = useResetExpertPasswordDialog(expert)
  const [openDeleteDialog, deleteDialog] = useDeleteExpertDialog(expert)
  const contextMenu = usePopupToggle()

  const dateAdded = formatDate(new Date(expert.whenCreatedEpochMilli), DATE_FORMAT())

  return <StyledRow>
    <div className="name">{expert.expertName.length > 2 ? expert.expertName : <NotProvided />}</div>
    <div className="email">{expert.expertEmail}</div>
    <div className="date-added">{dateAdded}</div>
    <div className="cases-count">{expert.openCases}</div>
    <div className="actions">
      <TextButton as={Link} to={`/experts/view/${expert.id}`}>View <span
        className="wide-only">profile</span></TextButton>
      <TextButton as={Link} to={`/experts/edit/${expert.id}`}>Edit <span
        className="wide-only">details</span></TextButton>
      <DropdownButton onClick={contextMenu.open}>
        <Icon icon="arrow-down"/>
        {
          contextMenu.isOpen && <ContextMenu>
            <Li label="Remove expert" onClick={openDeleteDialog}/>
            <Li label="Reset password" onClick={openResetPassword}/>
          </ContextMenu>
        }
      </DropdownButton>
      {resetPasswordDialog}
      {deleteDialog}
    </div>
  </StyledRow>
}
