import {Button, Icon, Loading} from '@startlibs/components'
import {Fill, useRefState, useToggle} from '@startlibs/core'
import {WithForm, TextInput, Errors, FormValue} from '@startlibs/form'
import {navigate} from '@reach/router'
import React, {forwardRef, Suspense, useEffect, useImperativeHandle} from 'react'
import _ from 'lodash/fp'
import {Uploader} from 'uploader-frontend'
import {Card, PageFooter, SectionHeading} from '../../components/PageLayout'
import {InfoBox} from '../../components/InfoBox'
import {LogoutButton} from '../../components/Navbar'
import {Question} from './components/Question'
import {SUBMITTED} from '../../enums/CaseState'
import {SavingFeedback} from '../../components/SavingFeedback'
import {appJwt, authGetFetcher, authPostFetcher} from '../../utils/authFetch'
import {buildValidation, required} from '../../utils/validation'
import {persistMedicalRecord, updateMedicalRecordsInfo} from './step3/utils'
import {willUseSuspense} from '../../hooks/useSuspense'
import {useNotification} from '../../components/Notifications'
import {isEmptyHtml} from '@startlibs/utils'
import {useRecentlySaved} from '../../associates/hooks/RecentlySaved'
import {useSetConfirmExitPage} from '../../hooks/useConfirmExitPage'

const preValidation = buildValidation({
  patientDiagnosis: [required],
  clinicalSummary: [required],
  questionsConsultant: [(list) =>
    (!list.length && 'At least one question required') ||
    (!list.find(item => !isEmptyHtml(item.question)) && 'At least one written question is needed.')
  ]
})

const transform = (values) => values.questionsConsultant.length === 1
  ? values
  : _.update('questionsConsultant', _.filter((item) => !isEmptyHtml(item.question)))(values)

const GET_EMPTY_QUESTION = () => ({question: '', answer: '', id: Date.now() + ''})
export const EMPTY_CASE_INFO = {questionsConsultant: [{question: '', answer: '', id: Date.now() + ''}]}
export const EMPTY_MEDICAL_RECORDS = {dicomStudies: [], nonCompliantDicom: [], nonDicomFiles: []}

export const Step3 = forwardRef(({saveCaseInfo, setMedicalRecords, submitRequest, caseRequest, prevStep, nextStep, validated, isAssociate}, ref) => {

  const save = (submitting) => saveCaseInfo(transform(formRef.current.getValues(), submitting))
  const confirmExitPage = () => confirmUploads().then(() => {
    if (submitRequest) {
      return formRef.current.confirm()
    } else if (formRef.current.hasChanged) {
      return save()
    }
  }).then(() => ({prevStep,nextStep}))
  useImperativeHandle(ref, () => ({
    confirm: confirmExitPage
  }), [])

  const confirmUploads = () =>
    uploaderRef.current
      ? uploaderRef.current.confirm()
      : Promise.resolve()

  const sendToReview = () => {
    confirmUploads().then(() => {
      isSaving.open()
      setMedicalRecords(medicalRecordsStore.get())
      return savingBeforeRefresh.wait(save(true)
        .then(() => submitRequest ? submitRequest() : Promise.resolve())
        .then(() => {
          validated.open()
          nextStep()
        }))
        .catch(() => {
          formRef.current.setError("An error has occurred", "")
        })
    })
    return Promise.resolve()
  }

  useSetConfirmExitPage(confirmExitPage)

  const medicalRecordsStore = useRefState(caseRequest.medicalRecordsInfo || EMPTY_MEDICAL_RECORDS)

  const formRef = React.useRef()
  const uploaderRef = React.useRef()

  const isSaving = useToggle()
  const savingBeforeRefresh = useToggle()
  const previousButtonLoading = useToggle()
  const [, setNotification] = useNotification()

  const saveToLeave = useToggle()
  const [,setRecentlySaved] = useRecentlySaved()
  const lastSaved = useToggle()

  React.useEffect(() => {
    if (submitRequest) {
      return;
    }
    let values = formRef.current.getValues()
    const showIsSaving = () => {
      if (formRef.current.getValues() !== values && !isSaving.get()) {
        isSaving.open()
      }
    }
    const saveInterval = setInterval(() => {
      if (formRef.current.getValues() !== values) {
        values = formRef.current.getValues()
        save()
          .then(() => {
            lastSaved.openWith(Date.now())
            isSaving.close()
            showIsSaving()
          })
      }
    }, 5000)

    const showIsSavingInterval = setInterval(() => {
      showIsSaving()
    }, 500)
    return () => {
      save()
      clearInterval(showIsSavingInterval)
      clearInterval(saveInterval)
    }
  }, [])

  const saveAndExit = () => {
    if (submitRequest) {
      return isSaving.wait(submitRequest().then(() => {
        navigate('/access')
      }))
    }
    isSaving.wait(save()).then(() => {
      navigate('/access')
      return new Promise(() => {
      })
    })
  }
  const saveAndViewAll = () => {
    saveToLeave.wait(save()).then(() => {
      setRecentlySaved(caseRequest.requestId)
      setNotification("Request saved successfully.")
      navigate('/associate')
      return new Promise(() => {})
    })
  }

  const medicalRecordsUpdater = updateMedicalRecordsInfo(authPostFetcher)(medicalRecordsStore, setMedicalRecords, caseRequest.requestId)

  const persistRecord = persistMedicalRecord(medicalRecordsUpdater)

  const removePersistedRecord = ({studyUID, fileUID, nonCompliant, nonCompliantFile}) => {
    if (studyUID) {
      if (medicalRecordsStore.get().dicomStudies.find(study => study.studyUID === studyUID)) {
        return medicalRecordsUpdater.remove(
          "DICOM_STUDY",studyUID,
          _.update('dicomStudies', _.differenceBy(_.property('studyUID'), _, [{studyUID}]))
        )
      }
    } else if (fileUID) {
      if (medicalRecordsStore.get().nonDicomFiles.find(file => file.uid === fileUID)) {
        return medicalRecordsUpdater.remove(
          "NON_DICOM_FILE",fileUID,
          _.update('nonDicomFiles', _.differenceBy(_.property('uid'), _, [{uid: fileUID}]))
        )
      }
    } else if (nonCompliant) {
      return medicalRecordsUpdater.remove(
        "ALL_NON_COMPLIANT_DICOM",
        "",
        _.set('nonCompliantDicom', nonCompliant)
      )
    } else if (nonCompliantFile) {
      return medicalRecordsUpdater.remove(
        "NON_COMPLIANT_DICOM",
        nonCompliantFile.uid,
        _.update(['nonCompliantDicom'], _.differenceBy(_.property('uid'), _, [{uid: nonCompliantFile.uid}]))
      )
    }
    return Promise.resolve()
  }

  return <WithForm
    alwaysSave
    values={caseRequest.caseInfo || EMPTY_CASE_INFO}
    transform={transform}
    action={sendToReview}
    ref={formRef}
    preValidation={preValidation}
  >{form => <>
    <UpdateValidated hasChanged={form.hasChanged} validated={validated}/>
    <SavingFeedback isSaving={isSaving.isOpen} lastSaved={lastSaved.isOpen}
                    css={caseRequest.state === SUBMITTED ? 'margin: 0 0 1rem;' : 'margin: -2rem 0 2rem;'}/>
    <SectionHeading>
      <h3>Request summary</h3>
    </SectionHeading>
    <Card>
      <TextInput
        label="Chief complaint"
        mandatory
        path="patientDiagnosis"
        descText="Describe in few words the medical condition (you can mention symptoms or previous diagnosis)."
        placeholder="Ex: Kidney stones"
      />
      <TextInput
        label="Describe your request"
        textarea
        mandatory
        path="clinicalSummary"
        descText="Write a detailed description to about your medical condition and story for the expert learn about your case."
        placeholder="Describe your request here"
      />
      <TextInput
        label="Referring physician"
        path="careProviders"
        descText="If applicable, enter the care providers you have already consulted."
        placeholder="Ex: Dr. John Doe"
      />
    </Card>

    <SectionHeading>
      <h3>Medical record files</h3>
      <p>Upload medical records: exam results, pathology reports, previous medical reports, videos or pictures of
        symptoms,
        etc.</p>
    </SectionHeading>
    {caseRequest.state === SUBMITTED &&
    <InfoBox dark autoSaveNotice withIcon>
      <Icon icon="failure"/>
      <div>
        Medical records uploads, deletions and changes will be instantly saved
        and made available to the expert.
      </div>
    </InfoBox>}
    <Card>
      <Suspense fallback={<Loading size={36} borderWidth={5} css="margin:3rem auto;"/>}>
        <WithUploaderToken requestId={caseRequest.requestId}>{jwt =>
          <Uploader
            jwt={jwt}
            ref={uploaderRef}
            appJwt={appJwt}
            persistRecord={persistRecord}
            setNotification={setNotification}
            removePersistedRecord={removePersistedRecord}
            medicalRecords={caseRequest.medicalRecordsInfo || EMPTY_MEDICAL_RECORDS}
          />
        }</WithUploaderToken>
      </Suspense>
    </Card>
    <SectionHeading>
      <h3>Questions</h3>
      <p>List up to five specific questions.</p>
    </SectionHeading>
    <Card>
      <FormValue path="questionsConsultant.length">{quantQuestionsConsultant =>
        <>
          {form.getValue('questionsConsultant').map((question, i) =>
            <Question i={i} key={question.id} form={form}/>
          )}
          {
            quantQuestionsConsultant < 5 &&

            <Button icon="plus-circle" small
                    onClick={() => form.setValue('questionsConsultant', (l) => l.concat(GET_EMPTY_QUESTION()))}>Add
              question</Button>
          }
        </>
      }</FormValue>
    </Card>
    <Errors/>
    <PageFooter>
      {
        !isAssociate ?
          <Button isLoading={saveToLeave.isOpen} onClick={() => confirmUploads().then(() => saveAndExit())} tabIndex={3}>
            {caseRequest.state === SUBMITTED ? 'Exit' : 'Exit and save for later'}
          </Button>
          :
          <Button isLoading={saveToLeave.isOpen} onClick={() => confirmUploads().then(() => saveAndViewAll())} tabIndex={3}>
            Save and view requests
          </Button>
      }
      <div className="right-wrapper">
        {
          caseRequest.state === SUBMITTED
            ? <Button onClick={() => confirmExitPage().then(() => window.location = window.location.pathname + "?to=check")}>Check status</Button>
            : <Button isLoading={previousButtonLoading.isOpen} onClick={() => confirmUploads().then(() => previousButtonLoading.wait(save().then(() => prevStep())))} tabIndex={2}>Previous</Button>
        }
        <Button highlight isLoading={savingBeforeRefresh.isOpen} type="submit">
          {caseRequest.state === SUBMITTED ? 'Submit changes' : 'Review'}
        </Button>
      </div>
    </PageFooter>
    <Fill name="Navbar-Exit">
      <LogoutButton onClick={() => confirmUploads().then(() => saveAndExit())}>
        {caseRequest.state === SUBMITTED ? 'Exit' : 'Exit and save for later'}
      </LogoutButton>
    </Fill>
  </>
  }</WithForm>
})

const useAuthSuspense = willUseSuspense((url) => authGetFetcher(url))

const WithUploaderToken = ({children,requestId}) => {
  const {jwt} = useAuthSuspense('/api/uploaderToken'+(requestId ? "?requestId="+requestId : ""))
  return children(jwt)
}

const UpdateValidated = ({hasChanged, validated}) => {
  useEffect(() => {
    if (hasChanged) {
      validated.close()
    }
  }, [hasChanged])
  return null
}
