import React from 'react'
import {willUseSuspense} from '../hooks/useSuspense'
import {getFetcher} from '@startlibs/utils'
import {ShareOrigin} from '../config/ShareOrigin'
import {ShareAccessWrapper, ShareBottomBox} from '../components/SigninLayout'
import {ShareLogin} from 'sharecomponents/src/pages/ShareLogin'
import {Button} from '@startlibs/components'
import {SharedReport} from './SharedReport'
import {SharedCase} from './SharedCase'


const useShareInfo = willUseSuspense((jwt) => getFetcher(ShareOrigin()+'/api/share/info',undefined,{headers:{ 'Content-Type': 'application/json','Authorization': `Bearer ${jwt}`}}))

const SHARE_LOGIN_LABELS = {
  title:"Confirm details to access expert view",
  subtitle:"Please confirm the patient identity to access the expert view",
  button: "Access shared expert view"
}

export const ShareRoutes = ({jwt,login,navigate, providerInfo}) => {
  const {authenticationResponse,...shareInfo} = useShareInfo(jwt)
  if (login) {
    if (authenticationResponse) {
      return <ShareLogin labels={SHARE_LOGIN_LABELS} withoutFooter providerInfo={providerInfo} authenticationResponse={authenticationResponse} shareInfo={shareInfo} navigate={navigate} token={jwt}/>
    } else {
      return <ShareAccessWrapper>
        <div>
          <ShareLogin labels={SHARE_LOGIN_LABELS} withoutFooter providerInfo={providerInfo} authenticationResponse={authenticationResponse} shareInfo={shareInfo} navigate={navigate} token={jwt}/>
          <ShareBottomBox>
            <p>Looking to obtain a new expert opinion from a healthcare professional?</p>
            <Button onClick={() => navigate('/')}>Learn more about Expert View™</Button>
          </ShareBottomBox>
        </div>
      </ShareAccessWrapper>
    }
  } else {
    return <SharedCase providerInfo={providerInfo} token={jwt} />
  }
}
