import {Button, Dialog, Icon, Loading, willUseFlipSwitch} from '@startlibs/components';
import {
  FormValue,
  SimpleRadiobox,
  TextInput,
  ToggleCheckbox,
  WithForm
} from '@startlibs/form';
import {getColor, media, twoWayAnimation} from '@startlibs/utils';
import {useLazyConstant, useToggle} from '@startlibs/core'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import styled, {css} from 'styled-components';
import {LoadingErrorDialog} from 'sharecomponents/src/pages/LoadingErrorDialog'
import {BoxWrapperFooter} from '../components/BoxPageLayout';
import {CMSNewExpertShareDialog} from './cmsexpertshare/CMSNewExpertShareDialog'
import {
  EmptyListPlaceholder,
  SearchInput
} from '../administrator/RequestList';
import {ExpertCaseCard} from './components/ExpertCaseCard'
import { NotProvided } from '../pages/steps/components/RequestView';
import { RecentlyInvited } from './components/ExpertProfileCard';
import {
  StyledRow,
  StyledTable,
  StyledTableHead
} from '../components/StyledTable';
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {filterExpert} from '../utils/utils'

const FAKE_EXPERT_LIST = [
  {
    id: 10,
    firstName: "Felipe",
    lastName: "Silva",
    email: "felipeko@gmail.com",
    whenCreated: "1987-01-22",
    cases: 10,
    specialization: ["Ophtalmology"]
  },
  {
    id: 20,
    firstName: "Gio",
    lastName: "Pizza",
    email: "gio@gmail.com",
    whenCreated: "2001-01-01",
    cases: 20,
    specialization: []
  }
]
const [onEnter, onLeave] = twoWayAnimation('opacity:0; transform:translateY(50%)', 'opacity:1;transform:translateY(0)')
const [loadingEnter, loadingLeave] = twoWayAnimation('opacity:0;', 'opacity:1;')

const ExpertShareDialog = styled(Dialog)`
  max-width: 48rem;
  ${StyledTable} {
    margin-top: 1rem;
    ${media.min(739)`
      height: 16rem;
      overflow-y: auto;
      position: relative;
    `}
    ${StyledTableHead} {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 3;
    }
  }
  ${EmptyListPlaceholder} {
    margin-top: 1rem;
    padding: 4rem 1rem;
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${StyledRow} {
    > div {
      &.name {
        flex-basis: 220px;
      }
      &.email {
        flex-grow: 1;
        flex-basis: 185px;
        min-width: 125px;
      }
      &.check {
        flex-basis: 30px;
        min-width: 30px;
        flex-shrink: 0;
        position: relative;
      }
    }
  }
  ${ToggleCheckbox} {
    margin-top: 1rem;
  }
  ${media.max(739)`
    ${StyledTable} {
      margin-top: 0;
    }
    ${StyledRow} {
      padding: 1.5rem 1.5rem 1rem;
      & ~ ${StyledRow} {
        margin-top: .5rem;
      }
      > div {
        &.name {
          font-size: 1.75rem;
          margin-bottom: .75rem;
          padding-right: 50px;
          display: inline-block;
          width: 50%;
          font-size: 13px;
          &:before {
            content: "Expert name: ";
            display: block;
          }
        }
        &.email {
          margin-bottom: .75rem;
          display: inline-block;
          width: 50%;
          &:before {
            content: "Expert email: ";
          }
        }
        &.check {
          position: absolute;
          top: 1.5rem;
          right: -.5rem;
          flex-basis: 50px;
          min-width: 50px;
        }
      }
    }
  `}
  ${media.max(520)`
    ${StyledRow} {
      > div {
        &.name, &.email {
          display: block;
          width: 100%;
        }
      }
    }
  `}
`

const ExpertShareHeading = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    p {
      flex-grow: 1;
      margin: .5rem .5rem .5rem 0 ;
    }
    ${SearchInput} {
      flex-basis: 210px;
      flex-shrink: 0;
      flex-grow: 1;
    }
`

const StyledExpertRow = styled(StyledRow)`
  cursor: pointer;
  &:hover {
    background: rgba(0,139,210,0.15);
  }
  ${props => props.selected && css`
    &:nth-child(odd), &:nth-child(even) {
      background-color: ${getColor('main')};
      color: white;
      &:hover {
        background: ${getColor('main')};
      }
      ${RecentlyInvited}, ${NotProvided} {
        color: white;
      }
    }
    ${Icon} {
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%,-50%);
      color: ${getColor('main')};
      width: 10px;
      height: 10px;
      font-weight: 600;
    }
  `}
`

const ExpertRadioBox = styled(SimpleRadiobox)`
  position: absolute;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  > div > div {
    position: static;
    > div {
      left: 50%;
      transform: translate(-50%,-50%);
      ${props => props.selected && css`
        background: white;
        border: transparent;
        &:before {
          background: transparent;
        }
      `}
    }
    > span {
      display: none;
    }
  }
`
const EmailConfirmationListContainer = styled.div`
  background-color: rgba(0,0,0,.07);
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  margin-bottom: 1rem;
  font-size: 13px;
  padding: 1rem;
  p {
    margin-bottom: 0;
  }
  ul {
    list-style-type: disc;
    margin-top: .5rem;
    margin-left: 2rem;
  }
`

const SecurityDataWarning = styled.div`
  color: rgba(0,0,0,0.5);
  h4 {
    font-size: 13px;
    margin-bottom: 0.25rem;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
`

const FooterButtons = styled(BoxWrapperFooter)`
  margin-top: 0;
`

const StickyWrapper = styled.div`
  height: auto;
`

const FlipDiv = ({useAnimation, onEnter = onEnter, onLeave = onLeave, ...props}) => {
  const ref = useRef()
  useAnimation(ref, onEnter, onLeave)
  return <div css="position:relative;" {...props} ref={ref}/>
}

const LoadingDialog = ({closeDialog, form, flipAnimation}) => <ExpertShareDialog
  closeDialog={closeDialog} withFormWrap
  title="Share case with an expert"
>
  <FlipDiv
    key="loading"
    css="padding:12rem 0"
    onEnter={loadingEnter}
    onLeave={loadingLeave}
    useAnimation={flipAnimation}
  >
    <Loading autoFocus absolute size={40} borderWidth={6}/>
  </FlipDiv>
</ExpertShareDialog>

export const CMSExpertShareDialog = ({notifyAndClose, jwt, closeDialog, caseInfo}) => {

  const onSuccess = () => notifyAndClose("Case shared successfully.")
  const [experts,setExperts] = useState()

  useEffect(() => {
    if (jwt) {
      jwtGetFetcher(jwt)(`/api/experts?nocache=${new Date().getTime()}`)
        .then(setExperts)
        .catch(() => setExperts("fail"))
    }
  },[jwt])


  const flipAnimation = useLazyConstant(willUseFlipSwitch)

  const query = useToggle("")
  const confirmDialog = useToggle("")
  const newExpertDialog = useToggle()

  const action = ({selectedExpert,allowDownload}) => confirmDialog.isOpen ? jwtPostFetcher(jwt)(`/api/expert/${selectedExpert.id}/share/${caseInfo.caseID}/download/${!!allowDownload}`) : confirmDialog.open()

  const filteredExperts = useMemo(() =>
      query.isOpen
        ? experts.filter(filterExpert(query.isOpen))
        : experts
    , [query.isOpen, experts])

  if (experts === 'fail') {
    return <LoadingErrorDialog errorCode="02" closeDialog={closeDialog}/>
  }

  return <WithForm
    alwaysSave
    action={action}
    onSuccess={confirmDialog.isOpen ? onSuccess : undefined}
  >{form =>
    (caseInfo && experts)
      ? ShareDialog({
        closeDialog,
        notifyAndClose,
        caseInfo,
        experts,
        action,
        confirmDialog,
        flipAnimation,
        form,
      query,
      newExpertDialog,
      filteredExperts,
      setExperts
      })
      : LoadingDialog({closeDialog, form, flipAnimation})
  }</WithForm>
}

const ShareDialog = ({closeDialog, notifyAndClose, flipAnimation, setExperts, confirmDialog, experts, action, form,caseInfo, query, newExpertDialog, filteredExperts}) => {

  return !confirmDialog.isOpen
  ?  <ExpertShareDialog
    title="Share case with an expert"
    closeDialog={closeDialog}
    footer={<>
      <FooterButtons>
        <Button icon="plus-circle" onClick={newExpertDialog.open}>Share with new expert</Button>
        <div className="right-block">
          <Button onClick={closeDialog}>Cancel</Button>
          <FormValue path="selectedExpert">{selectedExpert=><Button disabled={!selectedExpert} highlight type="submit" onClick={form.willSubmitForm}>Share with selected</Button>}</FormValue>
        </div>
      </FooterButtons>
    </>}
  >
    <FlipDiv
      key="share"
      onEnter={loadingEnter}
      onLeave={loadingLeave}
      useAnimation={flipAnimation}
    >
    <p>You are about to share the following case:</p>
    <ExpertCaseCard caseInfo={caseInfo}/>
    <ExpertShareHeading>
      <p><strong>Select an expert below to share this case with:</strong></p>
      <SearchInput>
        <Icon icon="search"/>
        <TextInput placeholder="Search by name or email" raw value={query.isOpen} setValue={query.openWith}/>
      </SearchInput>
    </ExpertShareHeading>
    {
      filteredExperts.length > 0 && experts.length > 0 ?
        <StyledTable>
          <StickyWrapper>
            <StyledTableHead>
              <div className="name">Expert name</div>
              <div className="email">Expert email</div>
              <div className="check"></div>
            </StyledTableHead>
            {
              filteredExperts.map(expert =>
                <FormValue path="selectedExpert">{(selectedExpert, setSelectedExpert) =>
                  <ExpertRow selected={selectedExpert === expert} setSelectedExpert={setSelectedExpert}
                             expert={expert}/>
                }</FormValue>
              )
            }
          </StickyWrapper>
        </StyledTable>
        : (
          <EmptyListPlaceholder>
            {experts.length > 0 && filteredExperts.length === 0 &&
            <span>There are no experts matching your search criteria.</span>}
            {experts.length === 0 && <span>There are no experts registered in this system yet.</span>}
          </EmptyListPlaceholder>
        )
    }
    <ToggleCheckbox
      label="Enable expert to download image files from this case"
      path="allowDownload"
      css="font-weight:600"
    />
    {
      newExpertDialog.isOpen &&
      <CMSNewExpertShareDialog setExperts={setExperts} closeDialog={newExpertDialog.close} action={action} outerForm={form} notifyAndClose={notifyAndClose}
                               caseInfo={caseInfo}/>
    }
    </FlipDiv>
  </ExpertShareDialog>
    :  <SecondStepDialog
      form={form}
      notifyAndClose={notifyAndClose}
      closeDialog={closeDialog}
      caseInfo={caseInfo}
      confirmDialog={confirmDialog}
    />
}

const SecondStepDialog = ({closeDialog, notifyAndClose, confirmDialog, form, caseInfo}) => {
  return <ExpertShareDialog
    title="Confirm case sharing"
    closeDialog={closeDialog}
    form={form}
    footer={<>
      <Button onClick={confirmDialog.close}>Return</Button>
      <Button autoFocus highlight isLoading={form.isLoading} type="submit" onClick={form.willSubmitForm}>Confirm sharing</Button>
    </>}
  >
    <p>Please review the details before sharing:</p>
    <ExpertCaseCard caseInfo={caseInfo}/>
    <EmailConfirmationListContainer>
      <p><b>Sharing case with:</b></p>
      <ul className="sending-email-list">
        <FormValue path="selectedExpert">{selectedExpert =>
          <li>{selectedExpert.expertEmail}</li>
        }</FormValue>
      </ul>
    </EmailConfirmationListContainer>
    <SecurityDataWarning>
      <h4>This email may contain confidential and protected health care information.</h4>
      <p>Please be sure that the email of the recipient has been entered correctly
        and that you are using appropriately confidential mechanisms for this transmission.</p>
    </SecurityDataWarning>
  </ExpertShareDialog>
}

const ExpertRow = ({selected, expert, setSelectedExpert}) => {
  return <StyledExpertRow selected={selected} onClick={() => setSelectedExpert(expert)}>
    <div className="name">{(expert.expertName && expert.expertName.length >2) ? `${expert.expertName}` : (expert.justCreated ? <RecentlyInvited>Recently added expert</RecentlyInvited> : <NotProvided />) }</div>
    <div className="email">{expert.expertEmail}</div>
    <div className="check">
      <ExpertRadioBox selected={selected} path="selectedExpert" fieldValue={expert}/>
      {selected && <Icon icon="check"/>}
    </div>
  </StyledExpertRow>
}
