import {formatDate, parseDate} from '@startlibs/utils'
import React, {Suspense} from 'react'
import styled from 'styled-components'

import {Card, SectionHeading} from '../../../components/PageLayout'
import {AddressHeading} from '../Step1'
import {dicomDateToDate} from '../../../utils'
import {DATE_FORMAT} from '../../../config/DateFormat'
import {Uploader} from 'uploader-frontend'
import {EMPTY_CASE_INFO, EMPTY_MEDICAL_RECORDS} from '../Step3'
import {appJwt, authGetFetcher} from '../../../utils/authFetch'
import {willUseSuspense} from '../../../hooks/useSuspense'
import {Loading} from '@startlibs/components'

const FieldsList = styled.div`
  margin: -0.5rem;
  ${props => props.twoColumns && `
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 46%;
      flex-grow: 1;
      &.full-width {
        flex-basis: 100%;
      }
    }
  `}
`

export const ReadOnlyField = styled.div`
  font-size: 14px;
  margin: 0.5rem 0.5rem 2rem 0.5rem;
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
`
const ReadOnlyQuestion = styled.div`
  font-size: 14px;
  font-weight: 600;
  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    font-weight: 400;
  }
  & ~ & {
    margin-top: 1rem;
  }
`
export const NotProvided = styled(({className}) => <i className={className}>Not provided</i>)`
  color: rgba(0,0,0,0.3);
 `
export const EmptyMedicalRecordsList = styled.div`
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 1rem 0;
  font-size: 14px;
 `

const GENDER_LABELS = {M: "Male", F: "Female"}

const IdentityFn = (({children}) => children())

export const RequestView = ({associateInfo, caseRequest: {requestId, associate, patientInfo, caseInfo: optCaseInfo, medicalRecordsInfo}, isApp, allowUpload, uploaderRef, persistRecord}) => {

  const caseInfo = optCaseInfo || EMPTY_CASE_INFO
  const caseAssociate = associateInfo || associate

  const hasMedicalRecords = medicalRecordsInfo && (
    medicalRecordsInfo.dicomStudies.length ||
    medicalRecordsInfo.nonCompliantDicom.length ||
    medicalRecordsInfo.nonDicomFiles.length
  )


  const GetUploaderToken = allowUpload || IdentityFn

  return <>
    <SectionHeading>
      <h3>Patient details</h3></SectionHeading>
    <Card css="padding-bottom:1rem">
      <FieldsList twoColumns>
        <ReadOnlyField><label>First name</label>
          <div>{patientInfo.firstName}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Last name</label>
          <div>{patientInfo.lastName}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Date of birth</label>
          <div>{formatDate(parseDate(patientInfo.dob, new Date(), "yyyy-MM-dd"), DATE_FORMAT())}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Gender</label>
          <div>{GENDER_LABELS[patientInfo.gender]}</div>
        </ReadOnlyField>
      </FieldsList>
      <AddressHeading>Mailing address</AddressHeading>
      <FieldsList twoColumns>
        <ReadOnlyField className="full-width"><label>Street</label>
          <div>{patientInfo.address ? patientInfo.address : <NotProvided/>}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>City</label>
          <div>{patientInfo.city ? patientInfo.city : <NotProvided/>}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>State</label>
          <div>{patientInfo.state ? patientInfo.state : <NotProvided/>}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Country</label>
          <div>{patientInfo.country ? patientInfo.country : <NotProvided/>}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Postal code</label>
          <div>{patientInfo.zipCode ? patientInfo.zipCode : <NotProvided/>}</div>
        </ReadOnlyField>
      </FieldsList>
    </Card>

    <SectionHeading>
      <h3>{caseAssociate ? 'Associate contact details' : 'Contact details'}</h3>
    </SectionHeading>
    <Card css="padding-bottom:1rem">
      <FieldsList twoColumns>
        <ReadOnlyField><label>Email</label>
          <div>{caseAssociate ? caseAssociate.email : (patientInfo.email ? patientInfo.email : <NotProvided/>)}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Phone</label>
          <div>{(caseAssociate ? caseAssociate.phoneNumber : patientInfo.phoneNumber) || <NotProvided/>}</div>
        </ReadOnlyField>
        {caseAssociate
          ? (caseAssociate.contactName && <ReadOnlyField><label>Contact Name</label>
            <div>{caseAssociate.contactName}</div>
          </ReadOnlyField>)
          : (patientInfo.contactName && <ReadOnlyField><label>Contact Name</label>
            <div>{patientInfo.contactName}</div>
          </ReadOnlyField>)
        }
      </FieldsList>
    </Card>

    <SectionHeading>
      <h3>Request summary</h3>
    </SectionHeading>
    <Card css="padding-bottom:1rem">
      <FieldsList>
        <ReadOnlyField><label>Chief complaint</label>
          <div>{caseInfo.patientDiagnosis? caseInfo.patientDiagnosis : <NotProvided/>}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Request description</label>
          <div>{caseInfo.clinicalSummary ? caseInfo.clinicalSummary : <NotProvided/>}</div>
        </ReadOnlyField>
        <ReadOnlyField><label>Referring physician</label>
          <div>{caseInfo.careProviders ? caseInfo.careProviders : <NotProvided/>}</div>
        </ReadOnlyField>
      </FieldsList>
    </Card>

    <SectionHeading>
      <h3>Medical record files</h3>
    </SectionHeading>
    <Card>
      {(hasMedicalRecords || allowUpload) ?
        <Suspense fallback={<Loading size={36} borderWidth={5} css="margin:3rem auto;"/>}>
          <GetUploaderToken requestId={requestId}>{(jwt) =>
            <Uploader
              jwt={jwt}
              disabled={!allowUpload}
              withoutDelete={allowUpload}
              isApp={isApp}
              ref={uploaderRef}
              medicalRecords={medicalRecordsInfo || EMPTY_MEDICAL_RECORDS}
              persistRecord={persistRecord}
              appJwt={appJwt}
            />
          }</GetUploaderToken>
        </Suspense>
        :
        <EmptyMedicalRecordsList>No medical records provided</EmptyMedicalRecordsList>
      }
    </Card>

    <SectionHeading>
      <h3>Questions</h3>
    </SectionHeading>
    <Card>
      {caseInfo.questionsConsultant.map(({question, id}, i) =>
        <ReadOnlyQuestion key={id}>
          <label>Question {i + 1}</label>
          <div>{question ? question : <NotProvided/>}</div>
        </ReadOnlyQuestion>
      )}
    </Card>
  </>
}
