import { Button, Icon } from '@startlibs/components';
import { Field } from '@startlibs/form';
import { media } from '@startlibs/utils';
import styled, { css } from 'styled-components';
import { BoxPageLayout } from '../../components/BoxPageLayout';

export const ProfilePage = styled(BoxPageLayout)`
  ${props => props.viewOnly && css`
    label {
      display: block;
      margin-top: 1rem;
      & ~ * {
        font-size: 14px;
        margin-bottom: 1rem;
      }
    }
    .resume {
      margin-top: 2rem;
    }
    h2 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .title-label {
        flex-shrink: 0;
        margin: 0;
        margin-right: .5rem;
      }
    }
    .specialization-item {
      background-color: rgb(230,230,230);
      padding: 2px 0.75rem 4px 0.75rem;
      border-radius: 12px;
      font-size: 13px;
      white-space: nowrap;
      display: inline-block;
      margin: .25rem;
    }
  `}
  .profile-content {
    display: flex;
    .fields-wrapper {
      flex-basis: 75%;
      flex-grow: 1;
    }
    .image-wrapper {
      flex-basis: 0;
      min-width: 20rem;
      padding: 3rem 0 3rem 3rem;
    }
    ${media.max(670)`
      display: flex;
      flex-direction: column-reverse;
      .image-wrapper {
        padding: 2rem;
      }
    `}
  }
`

export const ImageInputContainer = styled.div `
  background-color: rgba(0,0,0,0.075);
  border-radius: 7rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 13rem;
  height: 13rem;
  margin: 0 auto;
  position: relative;
  ${Button} {
    margin: 0 auto;
    top: 1rem;
  }
  ${props => props.hasImage && css`
    ${Button} {
      top: 5.5rem;
      pointer-events: none;
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
    }
    &:hover {
      ${ThumbnailContainer}:after {
        background: rgba(0,0,0,0.25);
      }
    }
  `}
`

export const ThumbnailContainer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 7rem;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: '';
    transition: all 0.25s ease;
  }
  ${Icon} {
    position: absolute;
    top: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(0,0,0,0.2);
    font-size: 36px;
    ${props => props.viewOnly && css`
      top: 48%;
      transform: translate(-50%,-50%);
      font-size: 48px;
    `}
  }
`
