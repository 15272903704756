import {useToggle} from '@startlibs/core'
import React, {useMemo, useState} from 'react'
import _ from 'lodash/fp'
import {ACCEPTED, REJECTED, REVIEWED, SUBMITTED} from '../enums/CaseState'
import {DeclinedRequest} from '../pages/DeclinedRequest'
import { PageContainer } from '../components/PageLayout';
import {ReviewedRequest} from '../pages/ReviewedRequest'
import {Step4} from '../pages/steps/Step4'
import {WaitingReview} from '../pages/WaitingReview'
import { jwtGetFetcher } from '../utils/authFetch';
import {willUseSuspense} from '../hooks/useSuspense'
import {Step5} from '../pages/steps/Step5'


const getJWT = () => new URLSearchParams(window.location.search).get("jwt")
const useAuthSuspense = willUseSuspense((url) =>
  Promise.all([jwtGetFetcher(getJWT())(url,{noCache:Date.now()}),jwtGetFetcher(getJWT())('/api/providerInfo',{noCache:Date.now()})])
)

export const MobileCase = () => {

  const caseId = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get("requestId")
  },[window.location.search])


  const [suspenseCase,providerInfo] = useAuthSuspense('/api/mob/case?requestId='+caseId)
  const [caseRequest, setCaseRequest] = useState(suspenseCase)

  const reloadCase = () =>
    jwtGetFetcher(getJWT())('/api/mob/case?requestId='+caseId,{noCache:Date.now()}).then(setCaseRequest)


  const step = useToggle(caseRequest.state === SUBMITTED ? 5 : 4)

  if (caseRequest.state === ACCEPTED) {
    return <WaitingReview caseRequest={caseRequest} isApp reloadCase={reloadCase}/>
  }
  if (caseRequest.state === REJECTED) {
    return <DeclinedRequest caseRequest={caseRequest} providerInfo={providerInfo} isApp/>
  }
  if (caseRequest.state === REVIEWED) {
    return <ReviewedRequest jwt={getJWT()} caseRequest={_.set(['report','version'],3,caseRequest)} providerInfo={providerInfo} isApp/>
  }


  if (step.isOpen === 4) {
    return <PageContainer appLayout>
      <Step4
        caseRequest={caseRequest}
        setCardInfo={(cardInfo) => setCaseRequest(_.set('cardInfo',cardInfo))}
        nextStep={step.willOpenWith(5)}
        isApp
      />
    </PageContainer>
  }
  if (step.isOpen === 5) {
    return <PageContainer appLayout>
      <Step5
        caseRequest={caseRequest}
        providerInfo={providerInfo}
        goToReview={step.willOpenWith(4)}
        isApp
      />
    </PageContainer>
  }

}