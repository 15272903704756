import React, {useMemo} from 'react'
import {useToggle} from '@startlibs/core'
import {Dialog, Loading} from '@startlibs/components'
import {authGetFetcher, jwtGetFetcher} from '../utils/authFetch'
import QRcode from 'qrcode'
import {useNotificationRef} from '../components/Notifications'
import { getColor } from '@startlibs/utils';
import styled, {css} from 'styled-components'

const QrCodeDialog = styled(Dialog) `
  max-width: 55rem;
  text-align: center;
  h2 {
    color: ${getColor('main')};
    padding-left: 2rem;
    margin-bottom: 1.5rem;
  }
  p {
    margin: 0 auto;
    font-size: 14px;
  }
`
const QrCodeContainer = styled.div `
  border-radius: 6px;
  border: 1px solid ${getColor('gray210')};
  padding: 0.5rem;
  min-width: 290px;
  min-height: 290px;
  margin: 1.5rem auto 1rem;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
const AboutAppContainer = styled.div`
  background: ${getColor('gray240')};
  margin: 2rem -2rem -3rem -2rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 2rem 2rem 2.5rem;
  color: rgba(0,0,0,0.5);
  h3 {
    font-weight: 600;
    margin-bottom: 1.5rem;
    position: relative;
    span {
      z-index: 2;
      background: ${getColor('gray240')};
      padding: 0 1.5rem;
      position: relative;
    }
    :after {
      content: '';
      border-bottom: 1px solid ${getColor('gray210')};
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
    }
  }
`
const MobileStoreBadges = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  a {
    text-decoration: none;
    color: inherit;
    ~ a {
      margin-left: 1.5rem;
    }
  }
  img {
    height: 44px;
  }
`

// const TEST_JWT = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXRpZW50TmFtZSI6IlUyRnNkR1ZrWDEremh1WkUwNyszdlB5U2NQZmpONGZxazZ2Mm84WWc1c2c9IiwicGF0aWVudElkIjoiVTJGc2RHVmtYMTlMMnJHRWhHWGVEZmVEaHFBYnZ1aDkveE91UGNJQWNZMD0iLCJyZWNvcmRVSUQiOiIiLCJyZWNvcmRVSURUeXBlIjoiQ01TX1JFUVVFU1QiLCJyZXBvcnRVSUQiOiIiLCJzaGFyZUVuZHBvaW50IjoiaHR0cHM6Ly9sb2NhbGhvc3Qvc2hhcmUvIiwiYWNjZXNzVHlwZSI6IkNNU19TWVNURU0iLCJwYXRpZW50RG9iIjoiVTJGc2RHVmtYMS8zK2hOdjZLdWhETTEwVDZ1ci9TWjcyMDcvOHhuc0Z5UT0iLCJzeXN0ZW0iOiJjbXNTeXN0ZW0iLCJwcm92aWRlcklkIjoiMSIsImV4cCI6NjAwMTU2ODA0NTA1OCwiaWF0IjoxNTY4MDQ1MTE4LCJwcm92aWRlckluZm8iOiJ7XCJuYW1lXCI6XCJkZW1vXCIsXCJsb2dvVVJMXCI6XCJodHRwczovL3d3dy5wdXJ2aWV3Lm5ldC9odWJmcy9QdXJ2aWV3JTIwTG9nbyUyMC0lMjBIb3Jpem9udGFsLTEucG5nXCIsXCJjdXN0b21NZXNzYWdlXCI6XCJFeHBlcnQgVmlld-KEoiBpcyB0aGUgcGVyZmVjdCBzb2x1dGlvbiBmb3IgeW91IHRvIGdldCBhbiBleHBlcnQgb3BpbmlvbiBmcm9tIGEgaGVhbHRoY2FyZSBwcm9mZXNzaW9uYWwgaW4gdGhlIE1lbW9yaWFsIFNsb2FuIEtldHRlcmluZyBDYW5jZXIgQ2VudGVyLlwiLFwiZXhwZXJ0T3BpbmlvbkZlZVwiOjMzMy4wLFwiYWRkcmVzc1wiOntcImVtYWlsXCI6XCJleGFtcGxlQGVtYWlsLmNvbVwiLFwicGhvbmVOdW1iZXJcIjpcIigrMTEpIDEyMy00NTYtNzg5XCIsXCJmYXhOdW1iZXJcIjpcIigrMTEpIDEyMy00NTYtNzg5XCIsXCJhZGRyZXNzXCI6XCJWaWxsYSBTZXNhbWUgU3RyZWV0LCA2MFwiLFwiemlwQ29kZVwiOlwiMjE0MDFcIixcImNpdHlcIjpcIk5ldyBZb3JrIENpdHlcIixcInN0YXRlXCI6XCJOZXcgWW9ya1wiLFwiY291bnRyeVwiOlwiVVNBXCJ9LFwicGhpUHJvdGVjdGlvbkltcGxlbWVudGVkXCI6dHJ1ZSxcInBoaVByb3RlY3Rpb25BdHRyaWJ1dGVzXCI6e1wibGFzdE5hbWVcIjp0cnVlLFwiZG9iXCI6dHJ1ZX0sXCJzaGFyaW5nT3B0aW9uc1wiOntcInNlbmRTZWN1cmVseVwiOnRydWUsXCJhbGxvd0ltYWdlRG93bmxvYWRcIjp0cnVlLFwiZXhwaXJhdGlvblRpbWVcIjowLFwic2hhcmVTdHVkeVJlcG9ydFwiOmZhbHNlLFwiaW5jbHVkZVN0dWR5UmVwb3J0XCI6Mn19In0.uWY9f3_bs_gjBJA7W98jylkNZEKJDze_WO3ZD8smVNc"

export const useQRCodeDialog = (caseRequest) => {
  const shareDialog = useToggle()
  const qrCode = useToggle()
  const shareJwtLoading = useToggle()

  const [, setNotification] = useNotificationRef()

  const notifyAndClose = (notification,notificationType) => {
    if (notificationType==='email') {
      setNotification("Report shared successfully")
    } else {
      setNotification(notification)
    }
    shareDialog.close()
  }

  const openQRCodeDialog = () => {
    shareDialog.open()
    if (!qrCode.isOpen) {
      shareJwtLoading.wait(
        jwtGetFetcher(window.sessionStorage.getItem("jwt") || window.sessionStorage.getItem('sharejwt'))(`/api/importCaseURL?requestId=${caseRequest.requestId}`)
          .then(({url}) => QRcode.toDataURL(url,{}))
          .then((dataURL) => qrCode.openWith(dataURL))
      )
    }
  }

  return [openQRCodeDialog,shareDialog.isOpen &&
    <QrCodeDialog
      closeDialog={() => {shareDialog.close(); qrCode.close()}}
      title="Open in My Expert View mobile app"
    >
      <p css="max-width:27rem">Open the app in your mobile device, press <b>Import Expert View</b> and scan the QR code below:</p>
      <QrCodeContainer>
        {qrCode.isOpen ?
          <QRCode image={qrCode.isOpen}/>
          :
          <Loading absolute size={36} borderWidth={5}/>
        }
      </QrCodeContainer>
      <p css="max-width:27rem">This will import the current expert view item to your app, making it accessible in the future.</p>
      <AboutAppContainer>
        <h3><span>Don't have the app yet?</span></h3>
        <MobileStoreBadges>
          <a href=""><img src="/public/app-store-badge.png" alt="App Store Button"/></a>
          <a href=""><img src="/public/play-store-badge.png" alt="Play Store Button"/></a>
        </MobileStoreBadges>
        <p><b>My Expert View app is your healthcare expert opinions wallet by Purview™.</b></p>
        <p>Keep your expert opinions a click away and carry them with you wherever you go.</p>
      </AboutAppContainer>
    </QrCodeDialog>]
}


const QRCode = ({image}) => {
  return <img src={image} />
}
