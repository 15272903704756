import React from 'react'
import {willUseSuspense} from '../hooks/useSuspense'
import {RequestView} from '../pages/steps/components/RequestView'
import {navigate} from '@reach/router'
import {Button} from '@startlibs/components'
import {ConfirmAcceptDialog} from './components/ConfirmAcceptDialog'
import {ConfirmDeclineDialog} from './components/ConfirmDeclineDialog'
import {useToggle} from '@startlibs/core'
import {PageContainer, CMSBackground} from '../components/PageLayout'
import styled from 'styled-components'
import {getColor, media} from '@startlibs/utils';
import {formatDate} from '@startlibs/utils'
import {iframeAuthGetFetcher} from '../utils/authFetch'

const PageWrapper = styled.div`
  background-color: #F3F4F8;
  width: 100%;
`

const HeaderViewRequest = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  .back-btn {
    margin-right: 1rem;
    span {
      font-size: 13px;
    }
  }
  .heading-text {
    flex-grow: 1;
    h2 {
      font-size: 18px;
      margin-bottom: .25rem;
      line-height: 1;
    }
    .details {
      color: ${getColor('gray120')};
      display: flex;
      .code {
        margin-right: 1rem;
      }
    }
  }
  ${media.max(739)`
    justify-content: space-between;
    .back-btn {
      order: 1;
    }
    .heading-text {
      order: 0;
      flex-basis: 100%;
      margin-bottom: 1rem;
    }
    .main-actions {
      order: 2;
    }
  `}
  ${media.max(360)`
    .back-btn {
      order: 0;
      margin-bottom: 1rem;
    }
    .heading-text {
      order: 1;
      .details {
        display: block;
      }
    }
    .main-actions {
      order: 2;
      display: flex;
      width: 100%;
      ${Button} {
        flex-grow: 1;
      }
    }
  `}
`

const FooterViewRequest = styled.div`
  text-align: right;
  border-top: 1px solid ${getColor('gray210')};
  padding-top: 2rem;
  ${media.max(360)`
    display: flex;
    ${Button} {
      flex-grow: 1;
    }
  `}
`

export const useSuspense = willUseSuspense((url) => iframeAuthGetFetcher(url))

export const ViewRequest = ({code,acceptedCase,declinedCase}) => {

  const caseRequest = useSuspense("/api/viewCaseRequest?requestCode="+code)

  const declineDialog = useToggle()
  const acceptDialog = useToggle()

  const getListUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("viewCase")
    return window.location.pathname + "?" + urlParams.toString()
  }

  const redirectToCase = (values,{id}) => {
    const appUrl = new URLSearchParams(window.location.search).get("appurl")
    window.parent.location = appUrl + "/clinical_presentation.php?id="+id
  }

  return <>
    <CMSBackground/>
    <PageWrapper>
      <PageContainer inCMS>
      <HeaderViewRequest>
        <Button className="back-btn" icon="arrow-left" onClick={() => navigate(getListUrl())}>Back</Button>
        <div className="heading-text">
          <h2>Expert opinion request</h2>
          <div className="details">
            <div className="code">Request code: {code}</div>
            <div className="date">Request date: {caseRequest.requestDate}</div>
          </div>
        </div>
        <div className="main-actions">
          <Button onClick={declineDialog.open}>Decline</Button>
          <Button highlight onClick={acceptDialog.open}>Accept Request</Button>
        </div>
      </HeaderViewRequest>
      <RequestView caseRequest={caseRequest} />
      <FooterViewRequest>
        <Button onClick={declineDialog.open}>Decline</Button>
        <Button highlight onClick={acceptDialog.open}>Accept Request</Button>
      </FooterViewRequest>
      </PageContainer>
    </PageWrapper>
    {
      acceptDialog.isOpen &&
      <ConfirmAcceptDialog closeDialog={acceptDialog.close} caseRequest={caseRequest} onSuccess={redirectToCase}/>
    }
    {
      declineDialog.isOpen &&
      <ConfirmDeclineDialog closeDialog={declineDialog.close} caseRequest={caseRequest} onSuccess={declinedCase} />
    }
  </>

}
