
export function dicomDateToDate(strDate) {
  if (!strDate) { return new Date(NaN) }
  const tryIso = new Date(strDate)
  if (!isNaN(tryIso.getTime())) { return tryIso}
  const ints = strDate.replace(/(\d{4})(\d\d)(\d\d)/, "$1 $2 $3").split(" ");
  return new Date(
    parseInt(ints[0], 10),
    parseInt(ints[1], 10)-1,
    parseInt(ints[2], 10)
  );
}

const splitAt = (str,pos) => [str.slice(0,pos),str.slice(pos)]

export const formatCurrency = (price) => {
  const [left,right] = splitAt(("00"+Math.round(Math.abs(price*100))),-2)
  return left.replace(/^0+(\d)/,'$1').replace(/\d(?=(\d{3})+$)/g, '$&,') + '.' + right
}
