import React from 'react'
import {useToggle} from '@startlibs/core'
import {authGetFetcher} from '../utils/authFetch'
import {ShareDialog} from 'sharecomponents/src/pages/ShareDialog'
import {CaseRequestInfo} from '../components/CaseRequestInfo'
import {useNotificationRef} from '../components/Notifications'


// const TEST_JWT = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXRpZW50TmFtZSI6IlUyRnNkR1ZrWDEremh1WkUwNyszdlB5U2NQZmpONGZxazZ2Mm84WWc1c2c9IiwicGF0aWVudElkIjoiVTJGc2RHVmtYMTlMMnJHRWhHWGVEZmVEaHFBYnZ1aDkveE91UGNJQWNZMD0iLCJyZWNvcmRVSUQiOiIiLCJyZWNvcmRVSURUeXBlIjoiQ01TX1JFUVVFU1QiLCJyZXBvcnRVSUQiOiIiLCJzaGFyZUVuZHBvaW50IjoiaHR0cHM6Ly9sb2NhbGhvc3Qvc2hhcmUvIiwiYWNjZXNzVHlwZSI6IkNNU19TWVNURU0iLCJwYXRpZW50RG9iIjoiVTJGc2RHVmtYMS8zK2hOdjZLdWhETTEwVDZ1ci9TWjcyMDcvOHhuc0Z5UT0iLCJzeXN0ZW0iOiJjbXNTeXN0ZW0iLCJwcm92aWRlcklkIjoiMSIsImV4cCI6NjAwMTU2ODA0NTA1OCwiaWF0IjoxNTY4MDQ1MTE4LCJwcm92aWRlckluZm8iOiJ7XCJuYW1lXCI6XCJkZW1vXCIsXCJsb2dvVVJMXCI6XCJodHRwczovL3d3dy5wdXJ2aWV3Lm5ldC9odWJmcy9QdXJ2aWV3JTIwTG9nbyUyMC0lMjBIb3Jpem9udGFsLTEucG5nXCIsXCJjdXN0b21NZXNzYWdlXCI6XCJFeHBlcnQgVmlld-KEoiBpcyB0aGUgcGVyZmVjdCBzb2x1dGlvbiBmb3IgeW91IHRvIGdldCBhbiBleHBlcnQgb3BpbmlvbiBmcm9tIGEgaGVhbHRoY2FyZSBwcm9mZXNzaW9uYWwgaW4gdGhlIE1lbW9yaWFsIFNsb2FuIEtldHRlcmluZyBDYW5jZXIgQ2VudGVyLlwiLFwiZXhwZXJ0T3BpbmlvbkZlZVwiOjMzMy4wLFwiYWRkcmVzc1wiOntcImVtYWlsXCI6XCJleGFtcGxlQGVtYWlsLmNvbVwiLFwicGhvbmVOdW1iZXJcIjpcIigrMTEpIDEyMy00NTYtNzg5XCIsXCJmYXhOdW1iZXJcIjpcIigrMTEpIDEyMy00NTYtNzg5XCIsXCJhZGRyZXNzXCI6XCJWaWxsYSBTZXNhbWUgU3RyZWV0LCA2MFwiLFwiemlwQ29kZVwiOlwiMjE0MDFcIixcImNpdHlcIjpcIk5ldyBZb3JrIENpdHlcIixcInN0YXRlXCI6XCJOZXcgWW9ya1wiLFwiY291bnRyeVwiOlwiVVNBXCJ9LFwicGhpUHJvdGVjdGlvbkltcGxlbWVudGVkXCI6dHJ1ZSxcInBoaVByb3RlY3Rpb25BdHRyaWJ1dGVzXCI6e1wibGFzdE5hbWVcIjp0cnVlLFwiZG9iXCI6dHJ1ZX0sXCJzaGFyaW5nT3B0aW9uc1wiOntcInNlbmRTZWN1cmVseVwiOnRydWUsXCJhbGxvd0ltYWdlRG93bmxvYWRcIjp0cnVlLFwiZXhwaXJhdGlvblRpbWVcIjowLFwic2hhcmVTdHVkeVJlcG9ydFwiOmZhbHNlLFwiaW5jbHVkZVN0dWR5UmVwb3J0XCI6Mn19In0.uWY9f3_bs_gjBJA7W98jylkNZEKJDze_WO3ZD8smVNc"

export const useShareDialog = (caseRequest,isAssociate) => {
  const shareDialog = useToggle()
  const shareToken = useToggle()
  const shareJwtLoading = useToggle()

  const [, setNotification] = useNotificationRef()

  const notifyAndClose = (notification,notificationType) => {
    shareToken.close()
    if (notificationType==='email') {
      setNotification("Expert view successfully shared.")
    } else {
      setNotification(notification)
    }
    shareDialog.close()
  }

  const openShareDialog = () => {
    shareDialog.open()
    if (!shareToken.isOpen) {
      shareJwtLoading.wait(
        authGetFetcher(`/api/system/jwt?requestId=${caseRequest.requestId}`)
          .then(({jwt}) => shareToken.openWith(jwt))
      )
    }
  }

  return [openShareDialog,shareDialog.isOpen &&
    <ShareDialog
      caseRequestInfo={<CaseRequestInfo sharing caseRequest={caseRequest}/>}
      caseRequest={caseRequest}
      suggestionLabel="Include patient: "
      suggestedEmails={(isAssociate && caseRequest.patientInfo.email) ? [caseRequest.patientInfo.email] : []}
      notifyAndClose={notifyAndClose}
      closeDialog={() => {shareDialog.close(); shareToken.close()}}
      jwt={shareToken.isOpen}
    />]
}