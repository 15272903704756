import { Button } from '@startlibs/components';
import { Errors, SimpleCheckbox, WithForm } from '@startlibs/form';
import {Fill, useEnsureRef, useToggle} from '@startlibs/core'
import {navigate} from '@reach/router'
import React, {forwardRef, Suspense, useRef} from 'react';

import { Card, PageFooter, SectionHeading } from '../../components/PageLayout';
import {CurrentCardDetails} from './components/CurrentCardDetails'
import { LogoutButton } from '../../components/Navbar';
import {UpdatePayment} from './components/UpdatePayment'
import {formatCurrency} from '../../utils'
import { responseFailure } from '../../utils/validation';


const onFailure = responseFailure(({status, errors,message,...rest}) =>
  (message && ({'':["This card could not be processed"]}) ) ||
  !!errors || ({'': ['Something went wrong. Please try again. If the error persists please contact us.']})
)

export const Step2 = forwardRef(({values, action, onSuccess, isSignUp, providerInfo, patientInfo, caseRequest, previousStep},maybeRef) => {
  const saveAndExit = (form) => () => form.submitForm(undefined, {onSuccess: () => navigate("/access")})

  const changeCard = useToggle(!caseRequest)
  const stripeRef = useRef()

  const ref = useEnsureRef(maybeRef)

  const submit = (values) => changeCard.isOpen
    ? stripeRef.current.submit(values).then((stripe) => action(stripe).then((server) => ({...stripe,...server})))
    : true

  React.useEffect(() => {
    if (ref.current) {
      ref.current.clearErrors()
    }
  },[changeCard.isOpen])

  return <WithForm
    alwaysSave
    ref={ref}
    values={values}
    onFailure={onFailure}
    action={submit}
    onSuccess={onSuccess}
  >{form => <>
      <SectionHeading>
        <h3>Payment details</h3>
        <p>You will be charged <b>${formatCurrency(providerInfo.expertOpinionFee)} USD</b>. Please fill in your credit card information below to proceed:</p>
      </SectionHeading>
      <Card css="margin-bottom:1rem;">
        {
          changeCard.isOpen
            ? <UpdatePayment ref={stripeRef} caseRequest={caseRequest} changeCard={changeCard} patientInfo={patientInfo}/>
            : <CurrentCardDetails caseRequest={caseRequest} changeCard={changeCard}/>
        }
      </Card>
      <p>You will not be charged now, just when you conclude and submit your expert opinion request.</p>
    {
      isSignUp && changeCard.isOpen && <SimpleCheckbox
        path="acceptedTerms"
        css="margin: 2rem 0;display:block;"
        label={<span>I confirm to have read and I accept the <a href="https://www.purview.net/expert-opinion-portal-terms"
                                                   className="link nowrap" target="_blank"
                                                   onClick={e => e.stopPropagation()}>License Terms</a>.</span>}
      />
    }
    <Errors/>
    <PageFooter>
    {
      isSignUp
          ? <Button onClick={() => navigate("/access")} tabIndex={3}>Discard request</Button>
        : <Button onClick={saveAndExit(form)} tabIndex={3}>Exit and save for later</Button>
    }
    <div className="right-wrapper">
      <Button onClick={previousStep} tabIndex={2}>Previous</Button>
      <Button highlight type="submit" isLoading={form.isLoading}>Next</Button>
    </div>
    </PageFooter>
    <Fill name="Navbar-Exit">
      {
        isSignUp
          ? <LogoutButton onClick={() => navigate("/access")}>Discard request</LogoutButton>
          : <LogoutButton onClick={saveAndExit(form)}>Exit and save for later</LogoutButton>
      }
    </Fill>
  </>
  }</WithForm>
})