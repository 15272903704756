
export const isRectEmpty = (rect) => rect.top === 0 && rect.bottom === 0 && rect.left === 0 && rect.right === 0

export const getTextRect = (node, pos = 0) => {
  const range = document.createRange()
  range.setStart(node, pos)
  range.setEnd(node, pos)
  const rect = range.getBoundingClientRect()
  if (isRectEmpty(rect)) { // safari fix
    const span = document.createElement('span')
    span.innerHTML = '&#8203;'
    if (pos === 0) {
      node.parentNode.insertBefore(span, node)
    } else {
      range.insertNode(span)
    }
    const spanRect = span.getBoundingClientRect()
    const parent = span.parentNode
    parent.removeChild(span)
    parent.normalize()
    return spanRect
  }
  return rect
}


export const binarySearchNextLine = (node, bottomBoundary, begin, textWindow) => {
  const max = node.textContent.length
  const end = Math.max(0, Math.min(begin + textWindow, max))
  const realWindow = end - begin
  const rect = getTextRect(node, end)
  if (realWindow <= 1) {
    if (end <= 1 && node.parentNode.nodeName.toUpperCase() === 'LI') { // LI fix
      if (rect.bottom < bottomBoundary)
        return null
      const newNode = document.createElement('span')
      if (node.parentNode.previousSibling) {
        node.parentNode.previousSibling.appendChild(newNode)
        return {node: newNode, rect: rect, position: 0}
      } else if (node.parentNode.parentNode.className.indexOf('field-content' >= 0)) {
        return {node: node.parentNode.parentNode, rect: node.parentNode.parentNode.getBoundingClientRect()}
      } else {
        node.parentNode.parentNode.insertBefore(node.parentNode, newNode)
        return {node: newNode, react: rect, position: 0}
      }
    }

    const range = document.createRange()
    if (end > 0) {// firefox fix
      range.setStart(node, end - 1)
      range.setEnd(node, Math.min(end + 1, node.textContent.length))
    }
    const nonCollapsedRect = range.getBoundingClientRect()
    const endTextRect = getTextRect(node, end - 1)
    if (end > 0 && (endTextRect.bottom >= bottomBoundary ||
      (nonCollapsedRect.left < rect.left && nonCollapsedRect.bottom >= bottomBoundary))) // firefox fix
    {
      return {
        position: end - 1,
        node: node,
        rect: isRectEmpty(nonCollapsedRect) ? endTextRect : nonCollapsedRect
      }
    } else if (rect.bottom >= bottomBoundary) {
      return {position: end, node: node, rect}
    } else if (node.textContent.length > end) {
      return {position: end + 1, node: node, rect: getTextRect(node, end + 1)}
    } else {
      return null
    }
  } else {
    if (rect.bottom <= bottomBoundary) {
      if (textWindow === realWindow) {
        return binarySearchNextLine(node, bottomBoundary, begin + textWindow, Math.ceil(textWindow / 2))
      } else {
        return null
      }
    } else {
      return binarySearchNextLine(node, bottomBoundary, begin, Math.ceil(textWindow / 2))
    }
  }
}

export const treeWalker = (element) =>
  document.createTreeWalker(
    element,
    NodeFilter.SHOW_ALL,
    (e) => {
      if (e.nodeType === Node.TEXT_NODE) {
        return NodeFilter.FILTER_ACCEPT
      } else {
        return e.childNodes.length ? NodeFilter.FILTER_SKIP : NodeFilter.FILTER_ACCEPT
      }
    },
    false
  )

export const getTextLine = (element, bottom) => {
  const textWalker = treeWalker(element, NodeFilter.SHOW_ALL)
  while (textWalker.nextNode()) {
    const current = textWalker.currentNode
    if (current.nodeType === Node.TEXT_NODE) {
      const length = current.textContent.length
      const result = binarySearchNextLine(current, bottom, 0, length)
      if (result) {
        return result
      }
    } else {
      const rect = current.getBoundingClientRect()
      if (rect.bottom > bottom) {
        return {node: current}
      }
    }
  }
  return null
}


export const getEmptySpace = (size) => {
  const div = document.createElement('div')
  div.className = 'page-filler'
  div.style.height = size + 'px'
  return div
}

export const getPageBreak = (page, totalPages) => {
  const div = document.createElement('div')
  const span = document.createElement('span')
  span.innerHTML = 'Page ' + (page + 1) + ' / ' + (totalPages + 1)
  span.className = 'page-counter'
  div.appendChild(span)
  div.className = 'page-break'
  return div
}

export const getLineHeight = (contents) => {
  const content = Array.prototype.slice.call(contents).find(el => el.textContent)
  if (!content)
    return 0
  const textWalker = document.createTreeWalker(content, NodeFilter.SHOW_TEXT, null, false)
  const text = textWalker.nextNode()
  return getTextRect(text).height
}

export const clearPagination = (container) =>
{
  Array.prototype.slice.call(container.querySelectorAll('.page-filler,.page-break,.report-header-fix-container'))
    .forEach(e => e.parentNode.removeChild(e))
  container.normalize()
}

export const getLastDiff = (separators) =>
{
  if (!separators.length) return 0
  return separators[separators.length - 1].diff
}


export const getSeparators = (nextNodes, top, opts, lastDiff, firstPage = false) => {
  const {contentPageHeight, minBreakSize, minContentSizeToBreak, contentLastPageHeight} = opts
  let contentBottom = top + contentPageHeight - lastDiff
  let index = nextNodes.findIndex(el => el.getBoundingClientRect().bottom > contentBottom)
  if (index < 0) {
    if (opts.canBeLastPage) {
      const contentLastPageBottom = top + contentLastPageHeight - lastDiff
      const lastPageIndex = nextNodes.findIndex(el => el.getBoundingClientRect().bottom > contentLastPageBottom)
      if (lastPageIndex >= 0) {
        index = lastPageIndex
        contentBottom = contentLastPageBottom
      } else {
        return {top: top, separators: []}
      }
    } else {
      return {top: top, separators: []}
    }
  }
  const nodeToBreak = nextNodes[index]
  let result = {}
  let bottom = 0
  if (nodeToBreak.offsetHeight < minContentSizeToBreak || nodeToBreak.getBoundingClientRect().top + minBreakSize > contentBottom) {
    let parent = null
    parent = nodeToBreak.closest('.field')
    bottom = nodeToBreak.parentNode.getBoundingClientRect().top
    result = {node: parent}
  } else {
    const {position, node, rect} = getTextLine(nodeToBreak, contentBottom)
    if (position !== undefined) {
      bottom = rect.top
      result = {node, position}
    } else {
      bottom = node.getBoundingClientRect().top
      result = {node}
    }
  }
  const diff = contentBottom - bottom
  const {top: lastTop, separators: nextSeparators} = getSeparators(nextNodes.slice(index), contentBottom, opts, diff)
  if (nextSeparators.length === 0 && contentLastPageHeight !== contentPageHeight && !firstPage) {
    return getSeparators(nextNodes, top, {...opts, contentPageHeight: contentLastPageHeight}, lastDiff)
  }
  return {top: lastTop, separators: [{...result, diff}, ...nextSeparators]}
}