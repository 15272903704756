import {Button} from '@startlibs/components'
import { Fill } from '@startlibs/core';
import {navigate} from '@reach/router'
import React from 'react'
import {HeaderButtons, PageContainer, PageFooter} from '../components/PageLayout'
import { LogoutButton } from '../components/Navbar';
import {Preview} from '../pages/reviewedRequest/Preview'
import {jwtGetFetcher} from '../utils/authFetch'
import {willUseSuspense} from '../hooks/useSuspense'
import {tryOrFail} from '../utils/utils'

const useReportSuspense = willUseSuspense((reportJWT) =>
  jwtGetFetcher(reportJWT)(`/api/report/json?nocache=${new Date().getTime()}`)
)

export const SharedReport = ({token}) => {
  const shareData = window.sessionStorage.getItem('sharedata')
  if (!shareData) {
    navigate('/share/'+token)
    return null
  }
  const {reportJWT} = tryOrFail(() => JSON.parse(shareData),() => navigate('/'))
  if (!reportJWT) {
    navigate('/share/'+token)
    return null
  }
  const {whenReturnedEpochMilli, report, info} = useReportSuspense(reportJWT)

  const exitAndInvalidateSession = () => { window.sessionStorage.clear(); navigate("/../../") }

  return <PageContainer noMarginOnPrint>
    <HeaderButtons className="hideOnPrint">
      <Button className="exit-shared" onClick={exitAndInvalidateSession}>Exit</Button>
      <div className="right-wrapper">
        <Button.a href={`/api/report/download?token=${reportJWT}`} icon="download" >Save PDF</Button.a>
        <Button.a target="_blank" href={`/api/report/preview?token=${reportJWT}`} highlight icon="print">Print</Button.a>
      </div>
    </HeaderButtons>
    <Preview reviewedDate={whenReturnedEpochMilli} studyCase={report} organization={{...info,...info.address}} />
    <PageFooter className="hideOnPrint reviewed-footer">
      <Button onClick={() => navigate("/../../")}>Exit</Button>
      <div className="right-wrapper">
        <Button.a href={`/api/report/download?token=${reportJWT}`}  icon="download">Save PDF</Button.a>
        <Button.a target="_blank" href={`/api/report/preview?token=${reportJWT}`}  highlight icon="print">Print</Button.a>
      </div>
    </PageFooter>
    <Fill name="Navbar-Exit">
      <LogoutButton onClick={exitAndInvalidateSession} >Exit</LogoutButton>
    </Fill>
  </PageContainer>
}
