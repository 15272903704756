import {Button} from '@startlibs/components'
import {Fill, useToggle} from '@startlibs/core'
import { media } from '@startlibs/utils';
import {navigate} from '@reach/router'
import React from 'react'
import styled from 'styled-components';
import {
  Card,
  PageContainer,
  PageFooter,
  RequestStatusIcon
} from '../components/PageLayout';
import {Header} from '../components/Header'
import { InfoBox } from '../components/InfoBox';
import { LogoutButton } from '../components/Navbar';
import {RequestView} from './steps/components/RequestView'
import {formatCurrency} from '../utils'
import {WaitingReview} from './WaitingReview'

const DeclinedRequestWrapper = styled(Card)`
  padding-right: 12rem;
  position: relative;
`

const DeclinedIcon = styled(RequestStatusIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  ${media.max(520)`
    display: none;
  `}
`

export const DeclinedRequest = ({caseRequest,providerInfo,isAssociate, associateInfo, isApp, canShare, isSharedAccess}) => {

  const toParam = new URLSearchParams(window.location.search).get("to")
  const viewRequest = useToggle(toParam === undefined ? isSharedAccess : toParam === "view")


  return <PageContainer appLayout={isApp}>
    <Header caseRequest={caseRequest} canShare={canShare}/>
    {
      viewRequest.isOpen
        ? <RequestView caseRequest={caseRequest} associateInfo={associateInfo}/>
      : <DeclinedRequestWrapper>
          <DeclinedIcon icon="x"/>
          <h3>This request for an expert opinion has been declined.</h3>
          {caseRequest.caseInfo.declineReason &&
            <>
              <p css="margin-bottom:0.5rem;"><b>The reason this request was declined was:</b></p>
              <InfoBox>{caseRequest.caseInfo.declineReason}</InfoBox>
            </>
          }
          <p css="margin:0;">
            {
              !isSharedAccess ?
              (!isAssociate ?
                <span>A refund for your payment of ${formatCurrency(providerInfo.expertOpinionFee)} is already being processed.</span>
                : <span>This request will not be charged at the end of your billing period.</span>
            ) : <span>An expert opinion could not be provided for this case.</span>
            }
          </p>
        </DeclinedRequestWrapper>
    }
    <PageFooter>
      {
        !isApp &&
        (!isAssociate ?
          <Button onClick={() => navigate("/access")}>Exit</Button>
          : <Button onClick={() => navigate("/associate")}>View all requests</Button>)
      }
      <Button highlight onClick={viewRequest.toggle}>{viewRequest.isOpen ? "Check status" : "View request"}</Button>
    </PageFooter>
    <Fill name="Navbar-Exit">
      <LogoutButton onClick={() => navigate("/access")}>Exit</LogoutButton>
    </Fill>
  </PageContainer>
}
