import React from 'react'
import styled from 'styled-components'
import _ from 'lodash/fp'
import {useToggle} from '@startlibs/core'
import {getColor} from '@startlibs/utils'
import {TextInput} from '@startlibs/form'
import {Dialog, Button, Icon} from '@startlibs/components'

const QuestionContainer = styled.div `
  position: relative;
  margin-bottom: 2rem;
`

const RemoveIcon = styled(Icon) `
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  color: ${getColor('gray150')};
  cursor: pointer;
`

export const Question =  ({question,i,form}) => {

  const deleteDialog = useToggle()

  const removeQuestion = () => {
    if (form.getValue(['questionsConsultant',i,'question'])) {
      deleteDialog.open()
    } else {
      confirmRemove()
    }
  }

  const confirmRemove = () => form.setValue('questionsConsultant',_.pullAt([i]))

  return <QuestionContainer><TextInput
    label={"Question "+(i+1)}
    path={['questionsConsultant',i,"question"]}
    placeholder="Your question here"
    textarea
  />
    <RemoveIcon icon='x' onClick={removeQuestion}/>
    {
      deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title="Confirm question deletion"
        footer={<>
          <Button onClick={deleteDialog.close}>Cancel</Button>
          <Button alert onClick={confirmRemove}>Delete</Button>
        </>}
      >
        <p><b>Are you sure you want to delete this question?</b> Notice that at least one written question is needed.</p>
        <p>Once confirmed this cannot be undone.</p>
      </Dialog>
    }
  </QuestionContainer>
}
