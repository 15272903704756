import { Icon } from '@startlibs/components';
import { getColor, media } from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {getAvatarBackgroundImage} from '../utils/avatar'

export const ExpertCardStyle = styled.div`
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  position: relative;
  padding: 1.5rem;
  margin-bottom: 1rem;
  align-items: center;
  ${media.max(440)`
    padding: 2.5rem 1.5rem;
  `}
  .avatar-wrapper {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-width: 5rem;
    min-height: 5rem;
    width: 5rem;
    height: 5rem;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
    border-radius: 3rem;
    margin-right: 1.5rem;
    ${props => props.noPic && css`
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${getColor('gray240')};
      ${Icon} {
        font-size: 2rem;
        margin-top: -.25rem;
        color: ${getColor('gray180')};
      }
    `}
  }
  .main-info {
    flex-grow: 1;
    .expert-name {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: .25rem;
    }
  }
  .case-counter {
    position: absolute;
    bottom: .5rem;
    right: .5rem;
  }
  .label {
    font-weight: 600;
  }
`

export const RecentlyInvited = styled.span`
    color: ${getColor('gray150')};
    font-style: italic;
    font-weight: 400;
`

export const ExpertProfileCard = ({expert}) => <ExpertCardStyle noPic={!expert.picture}>
  <div className="avatar-wrapper" css={expert.picture ? getAvatarBackgroundImage(expert) : ""}>{!expert.picture && <Icon icon="physician"/>}</div>
  <div className="main-info">
    <div className="expert-name">{
      (expert.expertName && expert.expertName.length >2)
      ? <span>{expert.expertName}</span>
      : (expert.firstName || expert.lastName)
        ? <span>{expert.firstName} {expert.lastName}</span>
        : <RecentlyInvited>Recently added expert</RecentlyInvited>
    }</div>
    <div className="email"><span className="label">Email:</span> {expert.email || expert.expertEmail}</div>
  </div>
  <div className="case-counter">
    {_.isNumber(expert.openCases) && <span><span className="label">Open cases:</span> {expert.openCases}</span>}
  </div>
</ExpertCardStyle>
