import React, {Suspense, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {useSuspense, willUseSuspense} from '../hooks/useSuspense'
import {navigate, Redirect, Router} from '@reach/router'
import {Navbar} from '../components/Navbar'
import {PurviewFooter} from '../components/PurviewFooter'
import {cold} from 'react-hot-loader'
import {AssociatesDashboard} from './AssociatesDashboard'
import {AssociatesCaseRequest} from './AssociatesCaseRequest'
import {AssociatesNewCaseRequest} from './AssociatesNewCaseRequest'
import {LoadingPage} from '../App'
import {callIfFunction} from '@startlibs/utils'
import {PageLoader} from '@startlibs/components'
import {authGetFetcher} from '../utils/authFetch'
import {PageLoadingSuspense} from '../components/PageLoading'
import {useIsAssociate} from '../hooks/useIsAssociate'

const ColdRedirect = cold(Redirect)

const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}
  <div ref={ref}/>
</React.Fragment>)

export const AssociatesApp = () => {

  if (window.location.pathname.indexOf("/associate/jwt/") >= 0) {
    const jwt = window.location.pathname.split("/").pop()
    window.sessionStorage.setItem("jwt", jwt)
    navigate("/associate/", {replace: true})
  } else if (!sessionStorage.getItem("jwt")) {
    window.location = "/api/associate/paslogin"
    return null
  }

  return <EnsureLoggedIn>
    <LoggedInApp/>
  </EnsureLoggedIn>
}

const useAuthSuspense = willUseSuspense((url) => authGetFetcher(url))


const LoggedInApp = () => {
  const providerInfo = useSuspense('/api/providerInfo')
  const associateInfo = useAuthSuspense('/api/associate/info')
  const [,setIsAssociate] = useIsAssociate()
  useEffect(() => {
    setIsAssociate(true)
    return () => setIsAssociate(false)
  },[])
  return <>
    <Router component={Identity}>
      <Identity path="login"/>
      <Navbar providerInfo={providerInfo} default isAssociate associateInfo={associateInfo}/>
    </Router>
    <PageLoadingSuspense>
      <Router component={Identity}>
        <AssociatesDashboard providerInfo={providerInfo} path="/"/>
        <AssociatesNewCaseRequest providerInfo={providerInfo} path="caseRequest"/>
        <AssociatesCaseRequest providerInfo={providerInfo} path="caseRequest/:requestId"
                               associateInfo={associateInfo}/>
      </Router>
    </PageLoadingSuspense>
    <PurviewFooter/>
  </>
}

class EnsureLoggedIn extends React.Component {
  state = {}

  componentDidCatch(error) {
    if (error.invalidateCache) {
      callIfFunction(error.invalidateCache)
      window.location.href = "/api/associate/paslogin"
    }
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  render() {
    return !this.state.hasError
      ? this.props.children
      : null
  }
}
