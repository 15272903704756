import React, {useRef, useState} from 'react'
import {postFetcher} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import { Header } from '../components/Header';
import { PageContainer } from '../components/PageLayout';
import {Step1} from '../pages/steps/Step1'
import {Step2} from '../pages/steps/Step2'
import {StepNavigation} from '../components/StepNavigation'
import {navigate} from '@reach/router'
import {authPostFetcher} from '../utils/authFetch'

const EMPTY_CASE_INFO = {
  patientDiagnosis: "",
  clinicalSummary: "",
  questionsConsultant: [{id: Date.now() + "", answer: '', question: ''}],
  version: 3
}

const CHECKOUT = [2]

export const AssociatesNewCaseRequest = ({providerInfo}) => {

  const stepRef = useRef()
  const step = useToggle(1)

  const goToPayment = (patientData) => {
    return authPostFetcher("/api/associate/createCaseRequest",{patientInfo:_.unset('confirmEmail',patientData)})
      .then(({requestId}) => {
        navigate("/associate/caseRequest/"+requestId)
      })
  }

  return <PageContainer>
    <Header/>
    <StepNavigation toggle={step} confirmAndOpen={_.identity} available={1} skip={CHECKOUT} />
    <Step1 isAssociate ref={stepRef} isSignUp action={goToPayment} />
  </PageContainer>
}