import {Button} from '@startlibs/components'
import {Fill, useToggle} from '@startlibs/core'
import {navigate} from '@reach/router'
import React from 'react'

import {
  Card,
  PageContainer,
  PageFooter,
  RequestStatusIcon
} from '../components/PageLayout';
import {Header} from '../components/Header'
import { LogoutButton } from '../components/Navbar';
import {RequestView} from './steps/components/RequestView'

export const WaitingReview = ({caseRequest, isAssociate,associateInfo, isApp, reloadCase, canShare, isSharedAccess}) => {

  const toParam = new URLSearchParams(window.location.search).get("to")

  const viewRequest = useToggle(toParam === undefined ? isSharedAccess : toParam === "view")
  const loading = useToggle()

  const checkStatus = () => {
    if (reloadCase) {
      loading.wait(reloadCase()).then(viewRequest.close)
    } else {
      window.location = window.location.pathname + "?to=check"
    }
  }

  const isAssociateRequest = !caseRequest.cardInfo || (caseRequest.cardInfo.brand === "N/A" && caseRequest.cardInfo.lastDigits === "0000")

  return <PageContainer appLayout={isApp}>

    <Header caseRequest={caseRequest} canShare={canShare}/>

    {
      viewRequest.isOpen
      ? <RequestView caseRequest={caseRequest} associateInfo={associateInfo}/>
      : <Card>
          <RequestStatusIcon icon="clock"/>
          <h3>Waiting for the expert to review this request.</h3>
          <p>This request has been accepted and is now being reviewed by an expert.</p>
          {!isSharedAccess && <>
            {
              (!isAssociateRequest || isAssociate)
              && <p css="margin:0">A notification will be sent to your email <b>({isAssociate ? associateInfo.email : caseRequest.patientInfo.email})</b> once the expert review is available.</p>
            }
            </>
          }
        </Card>
    }
    <PageFooter>
      {
        !isApp &&
        (!isAssociate ?
          <Button onClick={() => navigate("/access")}>Exit</Button>
          : <Button onClick={() => navigate("/associate")}>View all requests</Button>)
      }
      <div className="right-wrapper">
        {viewRequest.isOpen ?
          <Button highlight isLoading={loading.isOpen} onClick={checkStatus}>Check status</Button>
          :
          <Button onClick={viewRequest.toggle}>View request</Button>
        }
      </div>
    </PageFooter>
    <Fill name="Navbar-Exit">
      <LogoutButton onClick={() => navigate("/access")}>Exit</LogoutButton>
    </Fill>
  </PageContainer>
}
