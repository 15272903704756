import { Button, ContextMenu, Icon, Li } from '@startlibs/components';
import { formatDate, getColor, media } from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core';
import React from 'react'
import styled from 'styled-components';
import {DATE_FORMAT} from '../../config/DateFormat'
import {
  DropdownButton,
  StyledRow,
  StyledTable,
  StyledTableHead,
  TextButton
} from '../../components/StyledTable';
import {Pagination} from '../../components/Pagination'
import { PaginationWrapper } from '../ExpertList';
import {useToggleImageDialog} from './useToggleImageDialog'
import {useUnshareDialog} from './useUnshareDialog'
import {getOrigin} from '../hooks/useJwt'

const ExpertCaseTable = styled(StyledTable)`
  position: relative;
  a.share-case {
    position: absolute;
    top: .75rem;
    right: 1.25rem;
  }
  ${StyledRow} {
    &:last-of-type {
      border-bottom: 0;
    }
    > div {
      &.case-id {
        flex-basis: 70px;
        min-width: 70px;
      }
      &.patient {
        flex-grow: 1;
        flex-basis: 290px;
        .patient-id {
          margin-right:0.75rem
        }
      }
      &.chief-complaint {
        flex-basis: 180px;
      }
      &.case-date {
        flex-basis: 90px;
        min-width: 90px;
      }
      &.status {
        flex-basis: 100px;
        min-width: 100px;
      }
      &.actions {
        flex-basis: 260px;
        min-width: 260px;
        .small-screen-visible {
          display: none;
        }
      }
    }
  }
  ${media.max(1024)`
    .small-screen-hidden {
      display: none;
    }
    ${StyledRow} {
      > div {
        &.actions {
          flex-basis: 180px;
          min-width: 180px;
          .small-screen-visible {
            display: flex;
          }
        }
      }
    }
  `}
  ${media.max(880)`
    border: 0px;
    overflow: visible;
    a.share-case {
      top: -3.25rem;
      right: 0;
    }
    ${StyledRow} {
      display: block;
      border: 1px solid ${getColor('gray210')};
      border-radius: 5px;
      padding: 1.5rem 1.5rem 0;
      font-size: 13px;
      position: relative;
      margin-bottom: 1rem;
      &:last-of-type {
        border: 1px solid ${getColor('gray210')};
        margin-bottom: 0;
      }
      &:nth-child(even) {
        background-color: white;
      }
      > div {
        padding: 0;
        margin-bottom: .75rem;
        &:before {
          font-weight: 600;
          display: inline-block;
          margin-right: .25rem;
        }
        &.case-id {
          position: absolute;
          display: inline-block;
          top: 1.5rem;
          right: 1.5rem;
          &:before {
            content: "Case ID: ";
            display: inline;
          }
        }
        &.patient {
          .patient-name {
            font-size: 1.75rem;
            margin-bottom: .5rem;
            &:before {
              content: "Patient: ";
              font-size: 13px;
              display: block;
            }
          }
          .patient-id:before {
            content: "Patient ";
            font-size: 13px;
            font-weight: 600;
          }
        }
        &.chief-complaint {
          &:before {
            content: "Chief complaint: ";
          }
        }
        &.case-date {
          &:before {
            content: "Case date: ";
          }
        }
        &.status {
          &:before {
            content: "Status: ";
          }
        }
        &.actions {
          display: flex;
          padding: 0.5rem;
          margin: 0 -1.5rem;
          background: rgba(41, 122, 163, 0.07);
          clear: both;
          .small-screen-visible {
            display: none;
          }
          > * {
            flex-grow: 1;
            text-align: center;
            flex-shrink: 0;
          }
        }
      }
    }
    ${StyledTableHead} {
      display: none;
    }
    .small-screen-hidden {
      display: inline;
    }
  `}
  ${media.max(440)`
    ${StyledRow} {
      > div {
        &.case-id, &.patient-id {
          position: static;
          font-size: 12px;
        }
        &.patient-id {
          text-align: right;
          float: right;
        }
      }
    }
  `}
`

export const ExpertCaseList = ({cases,expert}) => {

  const pageToggle = useToggle(1)

  return <>
    <ExpertCaseTable>
      <StyledTableHead>
        <div className="case-id">Case ID</div>
        <div className="patient">Patient</div>
        <div className="chief-complaint">Chief complaint</div>
        <div className="case-date">Case date</div>
        <div className="status">Status</div>
        <div className="actions" ></div>
      </StyledTableHead>
      {
        cases.slice((pageToggle.isOpen-1)*10,pageToggle.isOpen*10).map(sharedCase => <CaseRow expert={expert} sharedCase={sharedCase} key={sharedCase.id} />)
      }
      <Button.a href={getOrigin()+"/cp_browser.php"} target="_top" className="share-case" small icon="plus-circle">Share a case</Button.a>
    </ExpertCaseTable>
    <PaginationWrapper>
      <Pagination setPageParam={(page) => () => pageToggle.openWith(page)}
                  currentPage={pageToggle.isOpen}
                  totalPages={Math.ceil((Number(cases.length)) / 10)}/>
    </PaginationWrapper>
    </>
}

const CaseRow = ({expert,sharedCase}) => {

  const [openToggleImageDialog,toggleImageDialog] = useToggleImageDialog(expert,sharedCase)
  const [openUnshareDialog,unshareDialog] = useUnshareDialog(expert,sharedCase)
  const {caseInfo} = sharedCase
  const contextMenu = usePopupToggle()
  const caseDate = formatDate(new Date(caseInfo.caseDate),DATE_FORMAT() + " at hh:mm")
  return <StyledRow>
    <div className="case-id">{caseInfo.caseID}</div>
    <div className="patient">
      <div className="patient-name"><strong>{caseInfo.patientName}</strong></div>
      <div>
        <span className="patient-id">
          <strong>ID:</strong> {caseInfo.patientCustomerId}
        </span> <span
        className="nowrap"><strong>DOB:</strong> {caseInfo.patientDOB}</span>
      </div>
    </div>
    <div className="chief-complaint">{caseInfo.chiefComplaint}</div>
    <div className="case-date">{caseDate}</div>
    <div className="status">{caseInfo.status}</div>
    <div className="actions">
      <TextButton onClick={openToggleImageDialog}>{sharedCase.allowStudyDownload ? <span>Disable<span className="small-screen-hidden"> image</span> download</span> : <span>Enable image download</span>}</TextButton>
      <TextButton className="small-screen-hidden" onClick={openUnshareDialog}>Unshare</TextButton>
      <DropdownButton className="small-screen-visible" onClick={contextMenu.open}>
        <Icon icon="arrow-down"/>
        {
          contextMenu.isOpen && <ContextMenu>
            <Li label="Unshare case" onClick={openUnshareDialog}/>
          </ContextMenu>
        }
      </DropdownButton>
    </div>
    {toggleImageDialog}
    {unshareDialog}
  </StyledRow>
}
