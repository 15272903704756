import {navigate} from '@reach/router'
import {postFetcher} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'
import React, {useRef, useState} from 'react'
import _ from 'lodash/fp'

import { Header } from '../components/Header';
import { PageContainer } from '../components/PageLayout';
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {StepNavigation} from '../components/StepNavigation'

export const SignUp = ({providerInfo}) => {

  const step = useToggle(1)
  const stepRef = useRef()

  const [patientData, setPatientData] = useState()

  const goToPayment = (data) => {
    setPatientData(data)
    step.openWith(2)
  }

  const signUpAction = (stripeInfo) => postFetcher("/api/createCaseRequest",{patientInfo:_.unset('confirmEmail',patientData),stripeInfo})

  const signUpSuccess = (v,{jwt}) => new Promise(() => {
    window.sessionStorage.setItem('jwt', jwt)
    navigate("/expert-opinion")
  })

  const confirmAndOpen = (nextStep) => () => {
    if (!stepRef.current) {
      step.openWith(nextStep)
    }
    if (step.isOpen!==nextStep) {
      stepRef.current.confirm()
        .then(step.willOpenWith(nextStep))
    }
  }

  return <PageContainer>
    <Header/>
    <StepNavigation toggle={step} available={patientData ? 2 : 1} confirmAndOpen={confirmAndOpen} />
    {
      step.isOpen === 1
        ? <Step1 ref={stepRef} isSignUp action={goToPayment} values={patientData}/>
        : step.isOpen === 2
        ? <Step2 isSignUp action={signUpAction} onSuccess={signUpSuccess} providerInfo={providerInfo} patientInfo={patientData} previousStep={step.willOpenWith(1)}/>
        : null
    }
  </PageContainer>
}