import { Button, Dialog, SplitColumnsContainer } from '@startlibs/components';
import { Errors, TextInput, ToggleCheckbox, WithForm } from '@startlibs/form';
import { getColor } from '@startlibs/utils';
import React from 'react';
import _ from 'lodash/fp';
import styled from 'styled-components';
import {ExpertCaseCard} from '../components/ExpertCaseCard'
import {
  buildValidation,
  confirmEmails,
  emailValidation,
  required
} from '../../utils/validation';
import {jwtPostFetcher} from '../../utils/authFetch'
import {getJwt} from '../hooks/useJwt'

const ExpertEmailBox = styled.div`
    padding: 1.5rem 1.5rem 1rem;
    background: ${getColor('gray240')};
    border-radius: 5px;
    margin-bottom: 1rem;
`


const preValidation = buildValidation({
  email: [required, emailValidation, confirmEmails('confirmEmail')],
  confirmEmail: [required],
})

export const CMSNewExpertShareDialog = ({notifyAndClose, outerForm, action, setExperts, closeDialog, caseInfo = {}}) => {

  const onSuccess = (values,serverId) => {
    const expert = {...values,...serverId,justCreated:true,expertEmail:values.email}
    outerForm.setValues({selectedExpert:expert,allowDownload:values.allowDownload})
    setExperts(_.concat(expert))
    closeDialog()
    outerForm.submitForm({selectedExpert:expert})
  }

  const addExpert = ({email}) => jwtPostFetcher(getJwt())("/api/expert",{email})

  return <WithForm
    alwaysSave
    action={addExpert}
    preValidation={preValidation}
    onSuccess={onSuccess}
  >{form =>
    <Dialog
      title="Share with a new expert"
      closeDialog={closeDialog}
      footer={<>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button highlight type="submit" isLoading={form.isLoading} onClick={form.willSubmitForm}>Add expert</Button>
      </>}
    >
      <p>You are about to share the following case:</p>
      <ExpertCaseCard caseInfo={caseInfo}/>
      <p>Type the expert’s email below to add him/her to the system and share the case:</p>
      <ExpertEmailBox>
        <SplitColumnsContainer viewportMinWidth={540}>
          <TextInput path="email" label="Expert's email" placeholder="expert@email.com" />
          <TextInput path="confirmEmail" label="Confirm email" placeholder="expert@email.com" />
        </SplitColumnsContainer>
      </ExpertEmailBox>
      <ToggleCheckbox
        label="Enable expert to download image files from this case"
        path="allowDownload"
        css="font-weight:600"
      />
    <p>Are you sure you want to invite this expert and share this case?</p>
      <Errors />
    </Dialog>
  }</WithForm>
}
