import _ from 'lodash/fp'
import {willUseSuspense} from '../hooks/useSuspense'
import {authGetFetcher} from './authFetch'


export const EMPTY_CASE_INFO = {
  patientDiagnosis: "",
  clinicalSummary: "",
  questionsConsultant: [{id: Date.now() + "", answer: '', question: ''}],
  version: 3
}


export const tryOrFail = (test,fail) => {
  try {
    return test()
  } catch(e) {
    fail()
  }
}

export const toLocalState = caseRequest => _.flow(
  _.update('caseInfo', (c) => c ? _.set('version', 3, c) : EMPTY_CASE_INFO),
  _.set(['patientInfo', 'confirmEmail'], _.get(['patientInfo', 'email'], caseRequest))
)(caseRequest)


export const filterExpert = (query) => expert => {
  const tokens = [...(expert.expertName || "").toLowerCase().split(",").map(v => v.trim()), (expert.expertEmail || "").toLowerCase()]
  const queryTokens = query.toLowerCase().trim().split(" ")
  return queryTokens.filter(q => tokens.find(t => t.indexOf(q.trim()) >= 0)).length === queryTokens.length
}

