import React from 'react'
import {useToggle} from '@startlibs/core'
import {Button,Dialog} from '@startlibs/components'
import {WithForm,Errors} from '@startlibs/form'
import {useNotificationRef} from '../../components/Notifications'
import {ExpertProfileCard} from '../components/ExpertProfileCard'
import {jwtGetFetcher} from '../../utils/authFetch'
import {getJwt} from './useJwt'

export const useResetExpertPasswordDialog = (expert) => {

  const dialog = useToggle()
  const [,setNotification] = useNotificationRef()

  const resetPassword = () => jwtGetFetcher(getJwt())(`/api/expert/${expert.id}/resetPassword`)
  const onSuccess = () => {
    setNotification("Password reset email sent")
    dialog.close()
  }

  const onFailure = (e,[resp,{status}],form) => {
    if (status === 404) {
      form.setError("","This account email has not yet been confirmed by its owner. Passwords can only be reset for accounts with confirmed email.")
    }
  }

  return [dialog.open, dialog.isOpen &&
  <WithForm
    action={resetPassword}
    onSuccess={onSuccess}
    onFailure={onFailure}
  >{form =>
    <Dialog
      closeDialog={dialog.close}
      footer={<>
        <Button onClick={dialog.close}>Cancel</Button>
        <Button onClick={form.submitForm} isLoading={form.isLoading} highlight>Yes, reset password</Button>
      </>}
      title="Confirm expert password reset"
    >
      <p>By reseting this expert's password he/she will receive an email to set a new one:</p>
      <ExpertProfileCard expert={expert}/>
      <p>Are you sure you want to reset this expert's password?</p>
      <Errors/>
    </Dialog>
  }</WithForm>]

}
