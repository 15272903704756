import React from 'react'
import styled from 'styled-components'
import purviewLogo from '../../../public/favicon.png'

export const PurviewFooter = styled(({className}) => {
  return (
    <div className={className}>
      <a href="https://www.purview.net/" target="_blank"><img src={purviewLogo} alt="Purview Logo"/></a>
      <div>
        <div className="main-text">Powered by <a href="https://www.purview.net/" target="_blank">Purview</a></div>
        <div>© 2019. All Rights Reserved.</div>
      </div>
    </div>
  )
})`
  color: rgba(0,0,0,0.4);
  padding: 3rem 1rem 1rem;
  height: 100px;
  margin: -100px auto 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  img {
    width: 50px;
    height: 50px;
    margin-right: 0.5rem;
  }
  .main-text {
    font-size: 13px;
    color: rgba(0,0,0,0.5);
    font-weight: 600;
    margin-bottom: 1px;
    display: inline-block;
    a {
      color: rgba(0,0,0,0.5);
      :hover {
        color: rgba(0,0,0,0.6);
      }
    }
  }
  @media print {
    display: none !important;
  }
`
