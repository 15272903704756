import React from 'react'
import {useToggle,willUseSharedCallback} from '@startlibs/core'
import {Button,Dialog} from '@startlibs/components'
import {WithForm,Errors} from '@startlibs/form'
import {useNotificationRef} from '../../components/Notifications'
import {ExpertProfileCard} from '../components/ExpertProfileCard'
import {jwtPostFetcher} from '../../utils/authFetch'
import {getJwt} from './useJwt'

export const [useRegisterExpertDeleted,triggerExportDeleted] = willUseSharedCallback()

export const useDeleteExpertDialog = (expert) => {

  const dialog = useToggle()
  const [,setNotification] = useNotificationRef()

  const action = () => jwtPostFetcher(getJwt())(`/api/expert/${expert.id}`,'',{method:"DELETE"})

  const onSuccess = () => {
    setNotification("Expert removed successfully.")
    triggerExportDeleted(expert)
    dialog.close()
  }

  return [dialog.open, dialog.isOpen &&
  <WithForm action={action} onSuccess={onSuccess}>{form =>
    <Dialog
      closeDialog={dialog.close}
      footer={<>
        <Button onClick={dialog.close}>Cancel</Button>
        <Button onClick={form.submitForm} isLoading={form.isLoading} alert>Yes, remove expert</Button>
      </>}
      title="Confirm expert removal"
    >
      <p>By removing this expert you will no longer be able to share cases:</p>
      <ExpertProfileCard expert={expert}/>
      <p>The expert will no longer be able to access cases previously shared.</p>
      <p>Are you sure you want to remove this expert? This action cannot be undone.</p>
      <Errors/>
    </Dialog>
  }</WithForm>]

}
