import {formFetch, getFetcher, postFetcher} from '@startlibs/utils'

export const jwtGetFetcher = (jwt) => (url, params = null, options) => getFetcher(url, {noCache:Date.now(),...params}, {...options,headers: new Headers({'Authorization': `Bearer ${jwt}`})})
export const jwtPostFetcher = (jwt) => (url, params, options) => postFetcher(url, params, {...options,headers: {'Authorization': `Bearer ${jwt}`}})
export const authGetFetcher = (url,params,...args) => jwtGetFetcher(window.sessionStorage.getItem("jwt"))(url,{noCache:Date.now(),...params},args)
export const authPostFetcher = (url, params, options) => jwtPostFetcher(window.sessionStorage.getItem("jwt"))(url,params,options)

export const iframeAuthGetFetcher = (url, params = null, options) => getFetcher(url, {noCache:Date.now(),...params}, {...options,headers: new Headers({'Authorization': `Bearer ${new URLSearchParams(window.location.search).get("jwt")}`})})
export const iframeAuthPostFetcher = (url, params, options) => postFetcher(url, params, {...options,headers: new Headers({'Authorization': `Bearer ${new URLSearchParams(window.location.search).get("jwt")}`,'Content-Type': 'application/json; charset=UTF-8'})})


export const appJwt = () => new URLSearchParams(window.location.search).get("jwt") || window.sessionStorage.getItem("jwt")
