import {callIfFunction, formatDate} from '@startlibs/utils'
import {useRefState, useToggle} from '@startlibs/core'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import { NotProvided } from '../steps/components/RequestView';
import {
  clearPagination,
  getEmptySpace,
  getLastDiff,
  getLineHeight,
  getPageBreak,
  getSeparators
} from './utils'
import {decode} from '../../utils/caseHelper'
import {en_US} from '../../components/date.i18n/en_US'
import {useSuspense} from '../../hooks/useSuspense'

const PageResizerContainer = styled.div`
  overflow: hidden;
`

const PAPER_SIZES = {
  A4: [8.27, 11.69],
  LETTER: [8.5, 11]
}

const decodeQuestions = (studyCase) => {
  switch (studyCase.version) {
    case 1: return [{question:studyCase.questionsConsultant}]
    case 2: return [{question:studyCase.questionsConsultant.questions,answer:studyCase.questionsConsultant.answers}]
    default: return studyCase.questionsConsultant
  }
}

export const PreviewFromUrl = (props) => {

  const id = new URLSearchParams(window.location.search).get("id")

  const studyCase = {...decode(useSuspense(`clinical_presentation_get.php?id=${id}`)),id}

  return <Preview {...props} studyCase={studyCase}/>
}

const dateMessages = (type,n) => en_US[type+'s'][n]
export const Preview = (
  {
    studyCase,
    previewReady,
    reviewedDate,
    organization,
    paperSize = PAPER_SIZES.LETTER,
    isPdfPrinter
  }) => {

  const lastPageFooter = true

  const isPreviewDone = useToggle()
  const isLogoLoaded = useToggle()

  const fieldsContainer = useRef()
  const compactHeaderContainer = useRef()
  const reportContainer = useRef()
  const footerFiller = useRef()
  const reportFooter = useRef()
  const reportPaginationPageFooter = useRef()

  // resize props
  const totalHeight = useRefState(null)
  const activeScale = useRefState(1)

  const resize = () => {
    if (!reportContainer.current || isPdfPrinter) return;
    const resizerContainer = reportContainer.current.parentNode
    const diff = resizerContainer.scrollWidth - resizerContainer.clientWidth
    if (activeScale.get() === 1 && diff <= 0) return;
    const pageWidth = Math.round(paperSize[0] * 96)
    if (totalHeight.get() === null) {
      totalHeight.set(parseInt(window.getComputedStyle(resizerContainer).height))
    }
    activeScale.set(Math.min(resizerContainer.clientWidth / (reportContainer.current.clientWidth+2), 1))
    reportContainer.current.style.transform = "scale(" + activeScale.get() + ")";
    reportContainer.current.style.transformOrigin = "left top";
    resizerContainer.style.height = (totalHeight.get() * activeScale.get()) + "px"
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])


  useEffect(() => {
    isPreviewDone.close()
    isLogoLoaded.close()
    const imagesToLoad = [organization.logoURL].map(imageSrc =>
      new Promise((resolve, reject) => {
        const img = document.createElement('img')
        img.onload = resolve
        img.onerror = resolve
        img.src = imageSrc
      })
    )
    Promise.all(imagesToLoad).then(loaded => isLogoLoaded.open())
  },[previewReady])

  useEffect(() => {
    reportPaginationPageFooter.current.className = 'report-page-footer'
    compactHeaderContainer.current.style.display = 'block'
    footerFiller.current.style.height = '0'
    reportFooter.current.style.display = 'block'


    clearPagination(reportContainer.current)

    try {
      addPreviewPagination()
    } catch (e) {
      console.log(e)
      reportPaginationPageFooter.current.className = 'report-page-footer-failed'
      clearPagination(reportContainer.current)
    }
    if (!lastPageFooter)
      reportFooter.current.style.display = 'none'
    compactHeaderContainer.current.style.display = 'none'
    reportContainer.current.style.height = 'auto'
    if (!isPdfPrinter) {
      resize()
    }
    isPreviewDone.open()

  },[previewReady])

  useEffect(() => {
    if (isPreviewDone.isOpen && isLogoLoaded.isOpen) {
      callIfFunction(previewReady,paperSize)
    }
  },[isPreviewDone.isOpen, isLogoLoaded.isOpen])


  const addPreviewPagination = () => {

    footerFiller.current.style.height = 0
    reportContainer.current.style.width = Math.round(paperSize[0] * 96) + 'px'
    reportContainer.current.style.height = (Math.round(paperSize[1] * 96) +
      parseInt(getComputedStyle(reportContainer.current).borderTopWidth, 10) +
      parseInt(getComputedStyle(reportContainer.current).borderBottomWidth, 10)) + 'px'
    const {height: paperHeight, top: containerTopWithBorder} = reportContainer.current.getBoundingClientRect()
    const containerTop = containerTopWithBorder
    const reportContainerStyle = window.getComputedStyle(reportContainer.current)
    const contentMarginTop = (parseFloat(reportContainerStyle.paddingTop, 10) || 0) + (parseFloat(reportContainerStyle.borderTop, 10) || 0)
    const contentMarginBottom = (parseFloat(reportContainerStyle.paddingBottom, 10) || 0) + (parseFloat(reportContainerStyle.borderBottom, 10) || 0)
    const contents = Array.prototype.slice.call(fieldsContainer.current.querySelectorAll('.field-content'))
    const lineHeight = getLineHeight(contents)
    const minBreakSize = lineHeight * 3
    const minContentSizeToBreak = lineHeight * 6

    const compactHeaderHeight = compactHeaderContainer.current.offsetHeight
    const reportFooterHeight = lastPageFooter ? reportFooter.current.offsetHeight : 0

    const contentPageHeight = paperHeight - contentMarginBottom - contentMarginTop - compactHeaderHeight
    const contentLastPageHeight = contentPageHeight - reportFooterHeight

    const top = containerTop + contentMarginTop + compactHeaderHeight

    const {top: lastContentsTop, separators} = getSeparators(contents, top, {
      contentPageHeight,
      minBreakSize,
      contentLastPageHeight,
      canBeLastPage: true,
      minContentSizeToBreak
    }, 0, true)

    const footerDiff = Math.floor(
      lastContentsTop + contentPageHeight -
      footerFiller.current.getBoundingClientRect().bottom -
      getLastDiff(separators) +
      // TODO FIX THIS:
      (separators.length === 0 ? -20 : 0)
    )

    footerFiller.current.style.height = (footerDiff) + 'px'

    separators.reverse().forEach(({diff, position, node}, i) => {
      const page = separators.length - 1 - i
      if (position !== undefined) {
        const range = document.createRange()
        range.setStart(node, position)
        range.setEnd(node, position)
        range.insertNode(getPageHeader())
        range.insertNode(getPageBreak(page, separators.length))
        range.insertNode(getEmptySpace(diff))
      } else {
        node.parentNode.insertBefore(getEmptySpace(diff), node)
        node.parentNode.insertBefore(getPageBreak(page, separators.length), node)
        node.parentNode.insertBefore(getPageHeader(), node)
      }
    })
    if (separators.length === 0) {
      reportFooter.current.style.display = 'none'
    }
  }

  const getPageHeader = () => {
    const clone = compactHeaderContainer.current.cloneNode(true)
    const height = compactHeaderContainer.current.offsetHeight
    const container = document.createElement('div')
    container.style.height = height + 'px'
    container.className = 'report-header-fix-container'
    container.appendChild(clone)
    return container
  }


  const fields1 = [
    {label:'Chief complaint',value:studyCase.patientDiagnosis},
    {label:'Summary',value:studyCase.clinicalSummary}
  ]
  const questionsAndAnswers = decodeQuestions(studyCase)
  const fields2 = [
    {label:'Care Providers',value:studyCase.careProviders}
  ]

  return (
    <PageResizerContainer>
        <div ref={reportContainer} className="report-page">
          <div className="institution-header">
            {organization.logoURL && <div className="organization-logo" style={{backgroundImage: 'url("' + organization.logoURL + '")'}}/>}
            <div className="organization-details">
                {organization.name.trim() &&
                <span className="organization-name">{organization.name}</span>}
                <div className="address-details">
                    {organization.address.trim() && <span className="address">{organization.address} </span>}
                    {organization.city.trim() && <span className="city">{organization.city}</span>}
                    {organization.state.trim() && <span className="state">{organization.state}</span>}
                    {organization.zipCode.trim() && <span className="zipcode">{organization.zipCode}</span>}
                    {organization.country.trim() && <span className="country">{organization.country} </span>}
                </div>
                {organization.phoneNumber.trim() && <span className="phone">Phone: {organization.phoneNumber} </span>}
                {organization.faxNumber.trim() && <span className="fax">Fax: {organization.faxNumber} </span>}
                {organization.email.trim() && <span className="email">Email: {organization.email} </span>}
            </div>
          </div>
          <h2 className="case-heading">Case Report</h2>
          <div className="report-date-container">
            {reviewedDate && <span className="report-date">{formatDate(new Date(reviewedDate), "LL dd, yyyy", dateMessages)}</span>}
            {/*
              lang &&
              <span className="report-date">{
                finished ?
                  formatDate(new Date(lastSaved), lang, layout.dateFormat + ' - hh:mm') :
                  formatDate(new Date(lastSaved), lang, layout.dateFormat)
              }</span>
            */}
          </div>
          <div className="report-header">
            {studyCase.id && <div className="case-id">Case {studyCase.id}</div>}
            <div className="header-label">Patient name</div>
            <div className="patient-name">
              {studyCase.patientName}
            </div>
          </div>
          <div ref={fieldsContainer}>
            {fields1.map(field =>
              <div key={field.label} className="field ">
                <label>{field.label}</label>
                <div className="field-content" dangerouslySetInnerHTML={{__html: field.value}}/>
              </div>
            )}
            <div className="field">
              <label>Questions and Answers</label>
              {questionsAndAnswers.map(({question,answer},i)=>
                <div key={question+i} className="question-and-answer">
                  <div className="field-content question" dangerouslySetInnerHTML={{__html: question}}/>
                  {answer && <div className="field-content answer" dangerouslySetInnerHTML={{__html: answer}}/>}
                </div>
              )}
            </div>
            {
              fields2.map(field =>
                <div key={field.label} className="field">
                  <label>{field.label}</label>
                  { studyCase.careProviders ?
                    <div className="field-content" dangerouslySetInnerHTML={{__html: field.value}}/>
                    :
                    <NotProvided/>
                  }
                </div>
              )
            }
          </div>
          <div className="report-compact-header" ref={compactHeaderContainer}>
            <div className="report-compact-header-content">
              <span>{organization.name}</span>
              <span>Patient: {studyCase.patientName}</span>
            </div>
            <div className="report-compact-header-margin"/>
          </div>
          <div className="footer-filler" ref={footerFiller}>
            <div className="report-footer" ref={reportFooter}>
            <div className="organization-details">
              {organization.name.trim() &&
              <span className="organization-name">{organization.name}</span>}
              <div className="address-details">
                {organization.address.trim() && <span className="address">{organization.address} </span>}
                {organization.city.trim() && <span className="city">{organization.city}</span>}
                {organization.state.trim() && <span className="state"> {organization.state}</span>}
                {organization.zipCode.trim() && <span className="zipcode">{organization.zipCode}</span>}
                {organization.country.trim() && <span className="country">{organization.country} </span>}
              </div>
              {organization.phoneNumber.trim() && <span className="phone">Phone: {organization.phoneNumber} </span>}
              {organization.faxNumber.trim() && <span className="fax">Fax: {organization.faxNumber} </span>}
              {organization.email.trim() && <span className="email">Email: {organization.email} </span>}
            </div>
          </div>
          </div>
          <div className="report-page-footer" ref={reportPaginationPageFooter}/>
        </div>
    </PageResizerContainer>
  )
}
