import {Button} from '@startlibs/components';
import {Fill} from '@startlibs/core'
import {getColor, media} from '@startlibs/utils';
import {navigate} from '@reach/router'
import React from 'react'
import styled from 'styled-components'

import {
  Card,
  PageFooter,
  RequestStatusIcon
} from '../../components/PageLayout';
import {LogoutButton} from '../../components/Navbar';
import {formatCurrency} from '../../utils'

const RequestCodeBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  font-size: 16px;
  min-height: 4rem;
  width: 100%;
  font-size: 16px;
  max-width: 33rem;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    margin: 0.5rem 1rem 0.5rem 0;
  }
  ${Button} {
    margin: 0.5rem 0;
    justify-self: flex-end;
    flex-shrink: 0;
  }
`
const AddDetailsCard = styled(Card)`
  ${media.min(650)`
    display: flex;
    align-items: flex-start;
    ${Button} {
      margin-left: 1rem;
      flex-shrink: 0;
    }
    p {
      margin-bottom: 0;
    }
  `}
`

const RequestSubmittedPage = styled.div`
  @media print {
    ${Button}, ${AddDetailsCard} {
      display: none;
    }
    ${RequestCodeBox} {
      max-width: auto;
      display: inline-block;
      width: auto;
    }
  }
`

export const Step5 = ({providerInfo, caseRequest, goToEdit, goToReview, isAssociate, associateInfo, isUpdate, isApp, isSharedAccess}) => {
  const isAssociateRequest = !caseRequest.cardInfo || (caseRequest.cardInfo.brand === "N/A" && caseRequest.cardInfo.lastDigits === "0000")
  return <RequestSubmittedPage>
    <Card>
      <RequestStatusIcon icon="check" success/>
      <h3>This expert opinion request has been {isUpdate ? 'updated' : 'sent'} successfully.</h3>
      <p css="max-width:38rem;">This request will now be reviewed. We will reach out if additional information is
        needed.</p>
      {
        !isSharedAccess && <>
          {!isAssociate &&
          <p>Your card has been charged <b>${formatCurrency(providerInfo.expertOpinionFee)} USD</b>.</p>
          }
          <p><b>Use the following code to access your request in the future:</b></p>
          <RequestCodeBox>
            {caseRequest.requestCode && <div>Expert request code: <b>{caseRequest.requestCode}</b></div>}
            {!isApp && <Button small icon="print" onClick={() => window.print()}>Print code</Button>}
          </RequestCodeBox>
          {
            (!isAssociateRequest || isAssociate)
            && <p css="margin:0;">This code has also been sent to your
              email: <b>{associateInfo ? associateInfo.email : caseRequest.patientInfo.email}</b>.</p>
          }
        </>
      }
    </Card>
    {!isApp &&
    <AddDetailsCard>
      <p>
        {!isSharedAccess ? <span>If you need to add any additional information to your request, please <a
            className="link nowrap" onClick={goToEdit}>click here</a> to add the necessary details.</span>
          : <span>If you need to include additional medical records in this request, please <a className="link nowrap"
                                                                                               onClick={goToReview}>click here</a> to view and upload files.</span>
        }
      </p>
      {!isSharedAccess ?
        <Button onClick={goToEdit}>Add more details</Button> :
        <Button onClick={goToEdit}>Add medical records</Button>
      }
    </AddDetailsCard>
    }
    <PageFooter>
      {!isApp &&
      (!isAssociate ?
        <Button onClick={() => navigate("/access")}>Exit</Button>
        : <Button onClick={() => navigate("/associate")}>View all requests</Button>)
      }
      <div className="right-wrapper">
        <Button onClick={goToReview}>View request</Button>
      </div>
    </PageFooter>
    <Fill name="Navbar-Exit">
      <LogoutButton onClick={() => navigate("/access")}>Exit</LogoutButton>
    </Fill>
  </RequestSubmittedPage>
}
