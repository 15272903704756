import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {useRefState} from '@startlibs/core'
import load from 'little-loader'
import {STRIPE_KEY} from '../../../config/StripeConfig'
import _ from 'lodash'
import {Button, SplitColumnsContainer} from '@startlibs/components'
import {Field, SimpleCheckbox, TextInput} from '@startlibs/form'
import {CreditCardIcon, StripeInput} from '../../../components/CheckoutComponents'
import {buildValidation, required} from '../../../utils/validation'
import styled from 'styled-components'

const ChangeCardHeader = styled.div `
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const preValidation = buildValidation({
    acceptedTerms: (v) => !v && 'You must read and agree to our License Terms',
    cardholder: required
  }
)

export const UpdatePayment = forwardRef(({changeCard, patientInfo, caseRequest},ref) => {

  const [cardBrand, setCardBrand] = useState('unknown')
  const [stripe, setStripe] = useState()

  const cardRef = useRefState()
  const cardErrorsRef = useRefState({
    cardCvc: {message: 'Your card\'s security code is incomplete.'},
    cardExpiry: {message: 'Your card\'s expiration date is incomplete.'},
    card: {message: 'Your card number is incomplete.'}
  })
  const cardModifiedRef = useRefState()

  const loadStripe = () =>
    window.Stripe ? Promise.resolve(window.Stripe) : new Promise((res, rej) =>
      load('https://js.stripe.com/v3/', (err) => {
        if (err) {
          rej()
        }
        res(window.Stripe)
      })
    )


  const initStripe = (stripe) => {
    const elements = stripe.elements()
    const style = {
      base: {
        color: '#3c3c3c',
        lineHeight: '18px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: 'rgba(0,0,0,0.35)'
        }
      },
      invalid: {
        color: '#C3282D',
        iconColor: '#fa755a'
      }
    }

    const cardExpiry = elements.create('cardExpiry', {style})
    cardExpiry.mount('#card-expiry-element')
    cardExpiry.on('change', ({error}) => {
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, cardExpiry: error}))
    })

    const cardCvc = elements.create('cardCvc', {style})
    cardCvc.mount('#card-cvc-element')
    cardCvc.on('change', ({error}) => {
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, cardCvc: error}))
    })

    cardRef.set(elements.create('cardNumber', {style}))
    cardRef.get().on('change', (event) => {
      setCardBrand(event.brand)
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, card: event.error}))
    }).on('ready', (() => {
      setStripe(stripe)
    }))
    cardRef.get().mount('#card-element')
  }

  useEffect(() => {
    loadStripe().then(Stripe => {
        initStripe(Stripe(STRIPE_KEY()))
      }
    )
  }, [])


  const submit = (values) => {
    /*const {loading, nextStep, , orderDetails} = this.props
    const [[coupon,couponError]] = orderDetails*/
    const errors = preValidation(values, {})
    const cardErrors = _.entries(cardErrorsRef.get()).filter(([k, v]) => v).map(([k, v]) => [v.message])
    const stripeValidation = Object.keys(cardErrors).length
      ? stripe.createToken(cardRef.get()).then((result) => ({card: [result.error.message]}))
      : Promise.resolve({})

    if (errors) {
      return stripeValidation
        .then((stripeErrors) => Promise.reject([{errors: {...errors, ...cardErrors}}]))
    }
    return stripe.createToken(cardRef.get(), {
      address_zip: patientInfo.zipCode || "",
      address_country: patientInfo.country || "",
      name: values.cardholder
    })
      .then((stripeResult) => {
      if (stripeResult.error) {
        // Inform the customer that there was an error.
        if (stripeResult.error) {
          throw [{errors: {card: [stripeResult.error.message]}}]
        } else {
          throw [{errors: {}}]
        }
      } else {
        const cardInfo = stripeResult.token.card ? {lastDigits:stripeResult.token.card.last4,brand:stripeResult.token.card.brand} : {}
        return {stripeToken: stripeResult.token.id, cardInfo}
      }
    })
  }

  useImperativeHandle(ref,() => ({
    submit
  }),[stripe])

  return <>
    {caseRequest && <ChangeCardHeader><h4>Change credit card</h4>
      <Button small onClick={changeCard.close}>Cancel changing card</Button>
      </ChangeCardHeader>
    }
    <SplitColumnsContainer viewportMinWidth={500} margin="big">
      <Field label="Card number" mandatory>
        <StripeInput id="card-element"/>
        <CreditCardIcon src={`/public/icon/${cardBrand}.svg`}/>
      </Field>
      <TextInput
        type="text"
        path="cardholder"
        label="Name on card"
        placeholder="Ex: John Doe"
        tabIndex={0}
        mandatory
      />
    </SplitColumnsContainer>
    <SplitColumnsContainer viewportMinWidth={500} margin="big">
      <Field label="Expiration" mandatory>
        <StripeInput id="card-expiry-element"/>
      </Field>
      <Field label="CVC" mandatory>
        <StripeInput id="card-cvc-element"/>
      </Field>
    </SplitColumnsContainer>
  </>
})
