import React from 'react';
import { InfoBox } from '../../components/InfoBox'

export const RequestInfo = ({caseRequest}) => <InfoBox>

  <div className="patient-name"><span>Patient name:</span> {caseRequest.patientInfo.firstName} {caseRequest.patientInfo.lastName}</div>
  <div className="diagnosis"><span>Chief complaint:</span> {caseRequest.caseInfo.patientDiagnosis}</div>
  <div className="details">
    <div className="code"><span>Request code:</span> {caseRequest.requestCode}</div>
    <div className="date"><span>Request date:</span> {caseRequest.requestDate}</div>
  </div>
</InfoBox>
