import {
  AutoComplete,
  Errors,
  Field,
  TextInput,
  WithForm,
  WithImageInput
} from '@startlibs/form';
import {Button, Icon, Loading, SplitColumnsContainer} from '@startlibs/components'
import {callIfFunction, media} from '@startlibs/utils';
import {navigate} from '@reach/router'
import React, {useRef} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components';
import {CMSBackground} from '../components/PageLayout';
import {BoxWrapperHeading, BoxPageWrapper, BoxPageLayout, BoxWrapperFooter} from '../components/BoxPageLayout';
import {ProfilePage, ImageInputContainer, ThumbnailContainer} from './components/ExpertProfileLayout';
import {
  buildValidation,
  confirmEmails,
  emailValidation,
  required
} from '../utils/validation';
import {useResetExpertPasswordDialog} from './hooks/useResetExpertPasswordDialog'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {getJwt} from './hooks/useJwt'
import {useRefState, useToggle} from '@startlibs/core'

// Specialties autocomplete:

let fetchedSuggestions = null

const fetchSuggestions = (s) => {
  const fetch = fetchedSuggestions || jwtGetFetcher(getJwt())("/api/specializations").then(_.map(({label}) => label))
  fetchedSuggestions = fetch
  return fetch.then(specializations =>
    specializations.filter(t => t.toLowerCase().indexOf(s) >= 0)
  ).then(l => l.indexOf(s) >= 0 ? l : l.concat([{newValue: s}]))
}

const getLabel = (v) => v.newValue ? `Create option "${v.newValue}"` : v
const transform = (l) => l ? l.map(v => (v && v.newValue) || v) : l

/************************/

// Image upload
const uploadFetcher = (expertId) => (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return jwtPostFetcher(getJwt())(`/api/expert/${expertId}/picture`, formData).then(console.log)
}

export const checkImage = (file) => {
  if (file.size > 4194304/4) {
    return ['Your file needs to be smaller than 1mb.']
  }
  if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
    return ['Invalid file format. Please select a .JPG or .PNG file.']
  }
}

/*****************/

const preValidation = buildValidation({
    firstName: required,
    lastName: required,
    email: [required, emailValidation, confirmEmails('confirmEmail')],
    confirmEmail: required,
  }
)

const EMPTY_EXPERT = {info: {specialization: []}}
const addConfirmEmail = (expert) => _.update("picture",(image) => !!image, expert ? {...expert, confirmEmail: expert.email} : expert)

export const EditExpert = ({action, onSuccess = () => navigate("/experts"),id, useExpertViewSuspense, location, isExpert, footer, isIncomplete}) => {
  const expert = useExpertViewSuspense(id)
  const formRef = useRef()
  const [openResetPassword, resetPasswordDialog] = useResetExpertPasswordDialog(expert)

  const fileToUpload = useRefState()
  const uploadingFile = useToggle()

  const setFile = (file) => {
    fileToUpload.set(file)
    const image = URL.createObjectURL(file)
    formRef.current.setValue("picture",image)
    return Promise.resolve({image})
  }

  const onSubmit = (values) => {
    if (fileToUpload.get()) {
      uploadingFile.open()
    }
    return action(values)
      .then((expert) => {
        const uploadId = expert.id || id
        if (fileToUpload.get()) {
          return uploadFetcher(uploadId)(fileToUpload.get()).then(() => expert)
        } else {
          return expert
        }
      })
      .finally(() => uploadingFile.close())
  }

  const pictureId = (expert && expert.id) || id

  return <WithForm onSuccess={onSuccess} ref={formRef} preValidation={preValidation} action={onSubmit}
                   transform={_.update("info.specialization",_.filter(_.get("length")))}
                   values={addConfirmEmail(expert) || EMPTY_EXPERT}>{form =>
    <ProfilePage>
      <CMSBackground/>
      <BoxPageWrapper>

        <BoxWrapperHeading>
          {
            isIncomplete
              ? <h2>Please complete your profile</h2>
              : expert
              ? <h2>Edit expert details: {(expert.firstName || expert.lastName) ? <span>{expert.firstName} {expert.lastName}</span> : <span>{expert.email}</span>}</h2>
              : <h2>Add a new expert</h2>
          }
        </BoxWrapperHeading>

        <div className="profile-content">

          <div className="fields-wrapper">
            <SplitColumnsContainer viewportMinWidth={850}>
              <TextInput
                readOnly={form.isLoading}
                autoFocus
                label="First name:"
                path="firstName"
                placeholder="John"
                mandatory
              />
              <TextInput
                readOnly={form.isLoading}
                label="Last name:"
                path="lastName"
                placeholder="Doe"
                mandatory
              />
            </SplitColumnsContainer>
            <SplitColumnsContainer viewportMinWidth={850}>
              <TextInput
                readOnly={form.isLoading}
                label="Email:"
                path="email"
                placeholder="expert@email.com"
                mandatory
              />
              <TextInput
                readOnly={form.isLoading}
                label="Confirm email:"
                path="confirmEmail"
                placeholder="expert@email.com"
                mandatory
              />
            </SplitColumnsContainer>
            <SplitColumnsContainer viewportMinWidth={850}>
              <TextInput
                readOnly={form.isLoading}
                label="Phone:"
                constraint={/[^\d-,+ ]/}
                path="info.phone"
                placeholder="+1 123-456-789"
              />
              <AutoComplete
                readOnly={form.isLoading}
                label="Specialization:"
                confirmIfValueKeys={['Tab']}
                path="info.specialization"
                fetchSuggestions={fetchSuggestions}
                getLabel={getLabel}
                transform={transform}
                bellowDescText="Use comma, enter or tab to add multiple."
              />
            </SplitColumnsContainer>
            <TextInput
              readOnly={form.isLoading}
              label="Resume:"
              path="info.resume"
              textarea
            />
          </div>

          <div className="image-wrapper">
            <WithImageInput preValidation={checkImage} uploadFetcher={setFile}
                            path={["picture"]}>{(input, {image, isLoading}) =>
              <ImageInputContainer hasImage={image} image={image===true ? `/api/expert/${pictureId}/picture?t=${getJwt()}&no-cache=${expert.picture}` : image}>
                {!(uploadingFile.isOpen || form.isLoading || isLoading) && input}
                <ThumbnailContainer css={image === true ? `background-image:url('/api/expert/${pictureId}/picture?t=${getJwt()}&no-cache=${expert.picture}');` : (image && `background-image:url('${image}')`)}>
                  {!image && <Icon icon="physician"/>}
                  {(uploadingFile.isOpen || isLoading) && <Loading white absolute/>}
                </ThumbnailContainer>
                {!image ?
                  <Button small disabled={uploadingFile.isOpen || form.isLoading || isLoading}>Add picture</Button>
                  : <Button small highlight disabled={uploadingFile.isOpen || form.isLoading || isLoading}>Change image</Button>
                }
              </ImageInputContainer>
            }</WithImageInput>
          </div>

        </div>

        <Errors/>

        <BoxWrapperFooter>
          {
            callIfFunction(footer,form) || <>
              {
                expert &&
                <div className="left-block">
                  <Button onClick={openResetPassword}>Reset password</Button>
                </div>
              }
              <div className="right-block">
                <Button onClick={() => form.confirm(expert ? "discard-expert-edit" : "discard-expert-create").then(() => navigate((location.state && location.state.prevLocation) || "/experts/"))}>Cancel</Button>
                <Button isLoading={form.isLoading} highlight type="submit">{expert ? "Update details" : "Add expert"}</Button>
              </div>
            </>
          }
        </BoxWrapperFooter>
        {resetPasswordDialog}
      </BoxPageWrapper>
    </ProfilePage>
  }</WithForm>
}
