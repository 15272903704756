import { Button, Icon, SplitColumnsContainer } from '@startlibs/components';
import { getColor, media } from '@startlibs/utils';
import { navigate } from '@reach/router';
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components';
import {useToggle} from '@startlibs/core'

const LandingPageWrapper = styled.div`
  padding-bottom: 8rem;
  font-size: 14px;
  .nowrap {
    white-space: nowrap;
  }
  .row {
    padding: 6rem 2rem;
    &:nth-child(even) {
      background: white;
    }
  }
  .content-wrapper {
    max-width: 1240px;
    margin: 0 auto;
  }
  .intro-row {
    .logo-wrapper {
      min-width: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;
      img {
        max-width: 300px;
        margin: 2rem;
        width: 100%;
      }
    }
    .intro-text-col {
      min-width: 260px;
      h1 {
        font-size: 30px;
        color: ${getColor('main')};
        margin-bottom: 1rem;
      }
      p {
        max-width: 40rem;
        color: ${getColor('gray120')};
        margin: 2rem 0;
      }
      .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        ${media.max(994)`
          ${Button} {
            margin-bottom: 1rem;
            margin-left: 0;
            flex-basis: 250px;
          }
        `}
      }
    }
  }
  .feature-row {
    padding: 4rem 2rem;
    .content-wrapper {
      display: flex;
      align-items: center;
      .text-col {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
          font-size: 21px;
          color: ${getColor('main')};
          margin-bottom: .5rem;
        }
        .text-wrapper {
          color: ${getColor('gray120')};
          width: 100%;
          max-width: 450px;
        }
      }
      .image-col {
        flex-basis: 50%;
        padding: 0 2rem;
        img {
          width: 100%;
          ${media.min(768)`
            &.overflow-ipad {
              margin-top:-10rem;
              margin-bottom: -10rem;
            }
            &.overflow-phones {
              margin-top: -4rem;
              margin-bottom: -4rem;
            }
          `}
        }
      }
    }
  }
  .row.cta {
    background-color: ${getColor('main')};
    text-align: center;
    h2 {
      color: white;
      margin-bottom: 2rem;
      font-size: 21px;
    }
    .cta-btn {
      background-color: white;
      color: ${getColor('main')};
      min-width: 250px;
      padding: 1.5rem 3rem 1.75rem;
      font-size: 16px;
      font-weight: bold;
      &:hover {
        background-color: rgba(255,255,255,0.925);
      }
    }
  }
  ${media.max(768)`
    .intro-row {
      padding-top: 2rem;
      .content-wrapper {
        ${SplitColumnsContainer} {
          flex-wrap: wrap;
          > div {
            flex-basis: 100%;
            justify-content: center;
            text-align: center;
          }
        }
        .logo-wrapper {
          margin-bottom: 2rem;
        }
        .intro-text-col {
          p {
            max-width: 100%;
          }
          .buttons-wrapper {
            flex-basis: 100%;
            justify-content: center;
            ${Button} ~ ${Button} {
              margin-left: 1rem;
            }
          }
        }
      }
    }
    .feature-row {
      .content-wrapper {
        flex-direction: column;
        text-align: center;
        .text-col {
          h2 {
            font-size: 18px;
            line-height: 1.3;
          }
          order: 2;
        }
        .image-col {
          order: 1;
          margin-bottom: 2rem;
          img {
            max-width: 550px;
          }
        }
      }
    }
  `}
  ${media.max(562)`
    .intro-row .content-wrapper {
      .intro-text-col {
        .buttons-wrapper {
          display: block;
          ${Button} {
            display: block;
            margin: 0 auto;
            width: 240px;
          }
          ${Button} ~ ${Button} {
            margin: 1rem auto 0;
          }
        }
      }
    }
  `}
  /* IE11 Adjustments */
  @media (max-width: 486px) and (-ms-high-contrast:none) {

    .feature-row .content-wrapper { display: block; } /* IE10 */
     *::-ms-backdrop, .feature-row .content-wrapper { display: block; } /* IE11 */

   }
`

const NavbarOnScroll = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
  padding: .5rem;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  .logo-container {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 120px;
    height: 3rem;
  }
  ${props => props.hidden && "display: none !important;"}
  ${media.max(600)`
    .mobile-hide, ${Icon} {
      display: none;
    }
    .new-request:before {
      content: 'New request'
    }
  `}
`

export const LandingPage = ({providerInfo}) => {

  const navbarOnScroll = useToggle(false)
  const navbarTrigger = useRef()

  useEffect(() => {
    const addNavbarOnScroll = () => {
      navbarOnScroll.openWith(navbarTrigger.current.getBoundingClientRect().top < 0)
    }
    addNavbarOnScroll()
    window.addEventListener("scroll",addNavbarOnScroll)
    return () => window.removeEventListener("scroll",addNavbarOnScroll)
  },[])

  return <LandingPageWrapper>
    <NavbarOnScroll hidden={!navbarOnScroll.isOpen}>
      <div className="logo-container" css={`background-image:url('${providerInfo.logoURL}');`} />
      <div className="navbar-buttons">
        <Button highlight className="new-request" icon="plus-circle" onClick={() => navigate("/start")}><span className="mobile-hide">Request new expert opinion</span></Button>
        <Button icon="sign-out"  onClick={() => navigate("/access")}>Access<span className="mobile-hide"> an expert opinion request</span></Button>
      </div>
    </NavbarOnScroll>
    <div className="row intro-row">
      <div className="content-wrapper">
        <SplitColumnsContainer>
          <div className="logo-wrapper">
            <img src={providerInfo.logoURL} alt="Logo" />
          </div>
          <div className="intro-text-col">
            <h1>Expert View™</h1>
            <p>{providerInfo.customMessage}</p>
            <div className="buttons-wrapper">
              <Button highlight icon="plus-circle" onClick={() => navigate("/start")}>Request new expert opinion</Button>
              <Button icon="sign-out"  onClick={() => navigate("/access")}>Access an expert opinion request</Button>
            </div>
        </div>
        </SplitColumnsContainer>
      </div>
    </div>
    <div className="row feature-row" ref={navbarTrigger}>
      <div className="content-wrapper">
        <div className="image-col">
          <img src="/public/landing-page/mac-description.png" />
        </div>
        <div className="text-col">
          <div className="text-wrapper">
            <h2>Describe your <span className="nowrap">medical condition</span></h2>
            <p>Provide information regarding your medical condition and history by creating an Expert Opinion Request that will be shared with  one of our specialized healthcare experts.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row feature-row">
      <div className="content-wrapper">
        <div className="text-col">
          <div className="text-wrapper">
            <h2>Upload and attach your <span className="nowrap">medical records</span></h2>
            <p>Attach your medical records (previous diagnosis, medical images, pathology reports, test results, etc.) to your Expert Opinion Request and have it  shared with the expert for review.</p>
          </div>
        </div>
        <div className="image-col">
          <img src="/public/landing-page/mac-upload.png" />
        </div>
      </div>
    </div>
    <div className="row feature-row">
      <div className="content-wrapper">
        <div className="image-col">
          <img className="overflow-ipad" src="/public/landing-page/ipad-questions.png" />
        </div>
        <div className="text-col">
          <div className="text-wrapper">
            <h2>Ask specific questions</h2>
            <p>Ask the healthcare experts up to 5 questions to have them address your concerns directly.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row feature-row">
      <div className="content-wrapper">
        <div className="text-col">
          <div className="text-wrapper">
            <h2>Receive a detailed <span className="nowrap">expert report</span></h2>
            <p>Receive a detailed report, including an expert opinion and recommendations regarding your medical condition.</p>
          </div>
        </div>
        <div className="image-col">
          <img className="overflow-phones" src="/public/landing-page/phone-reports.png" />
        </div>
      </div>
    </div>
    <div className="row cta">
      <div className="content-wrapper">
        <h2>Request an expert opinion now with Expert View™</h2>
        <Button className="cta-btn" onClick={() => navigate("/start")}>Request expert opinion</Button>
      </div>
    </div>
  </LandingPageWrapper>
}
