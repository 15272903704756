import React from 'react'
import {useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import {useNotificationRef} from '../../components/Notifications'
import {Errors, WithForm} from '@startlibs/form'
import {Button, Dialog} from '@startlibs/components'
import {triggerCaseUpdate} from '../hooks/useRegisterCaseUpdate'
import {ExpertCaseCard} from '../components/ExpertCaseCard'
import {jwtPostFetcher} from '../../utils/authFetch'
import {getJwt} from '../hooks/useJwt'


export const useToggleImageDialog = (expert, sharedCase) => {

  const dialog = useToggle()
  const [, setNotification] = useNotificationRef()

  const isDisable = sharedCase.allowStudyDownload

  const onSuccess = () => {
    setNotification(`Image download ${isDisable ? 'disabled' : 'enabled'} successfully.`)
    triggerCaseUpdate(setCases => setCases(sharedCase, _.set('allowStudyDownload', !sharedCase.allowStudyDownload)))
    dialog.close()
  }

  const action = () =>
    jwtPostFetcher(getJwt())(
      isDisable ? `/api/expert/share/${sharedCase.id}/disableDownload` : `/api/expert/share/${sharedCase.id}/enableDownload`,
      '',
      {method: 'PUT'}
    )

  return [dialog.open, dialog.isOpen &&
  <WithForm action={action} onSuccess={onSuccess}>{form =>
    <Dialog
      closeDialog={dialog.close}
      footer={<>
        <Button onClick={dialog.close}>Cancel</Button>
        <Button highlight onClick={form.submitForm}
                isLoading={form.isLoading}>Yes, {isDisable ? 'disable' : 'enable'} image download</Button>
      </>}
      title={`Confirm ${isDisable ? 'disable' : 'enable'} image download`}
    >
      <p>
        {
          isDisable
          ? 'By disabling image download you will no longer allow this expert do download images that appear in the following case:'
          : 'By enabling image download you will allow this expert do download images that appear in the following case:'
        }
      </p>
      <ExpertCaseCard caseInfo={sharedCase.caseInfo}/>
      <p>Are you sure you want to {isDisable ? 'disable' : 'enable'} image download?</p>
      <Errors/>
    </Dialog>
  }</WithForm>]

}
