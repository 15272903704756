import {
  Button,
  Dialog,
  Loading,
  SplitColumnsContainer
} from '@startlibs/components';
import {Errors, WithForm, SimpleRadiobox} from '@startlibs/form'
import { getColor, media } from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { DialogErrorBoundary } from '../../pages/errors/ProviderError';
import { InfoBox } from '../../components/InfoBox'
import { ReadOnlyField, NotProvided } from '../../pages/steps/components/RequestView';
import { RequestInfo } from './RequestInfo';
import { buildValidation } from '../../utils/validation';
import {iframeAuthGetFetcher, iframeAuthPostFetcher} from '../../utils/authFetch'

const PatientsLoading = styled.div`
  margin: 4rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${getColor('gray150')};
  ${Loading} {
    margin-left: 1rem;
  }
`

const BoxyRadioBox = styled(SimpleRadiobox)`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding-top: .5rem;
  padding-right: 1rem;
  padding-left: 2.5rem;
  padding-bottom: .5rem;
  margin-bottom: .5rem;
  color: ${getColor('gray120')};
  &:hover {
    border-color: ${getColor('gray180')};
  }
  &.create-new {
    margin-bottom: 1rem;
  }
  > div {
    left: .5rem;
  }
`

const SplitsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: stretch;
  > ${SplitColumnsContainer} {
    flex-basis: 50%;
    flex-grow: 1;
    ${media.max(540)`
      flex-wrap: wrap;
    `}
    > div {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      min-width: 220px;
    }
  }
`

const AddressBox = styled(InfoBox)`
  padding: 1.5rem;
  margin-top: .25rem;
  flex-basis: 80%;
  flex-grow: 1;
  ${ReadOnlyField} {
    margin-bottom: 0;
  }
  ${SplitColumnsContainer} {
    flex-grow: 1;
    flex-basis: 50%;
  }
  ${SplitColumnsContainer} ~ ${SplitColumnsContainer} {
    margin-top: .5rem;
  }
`

const AddressDialogFooter = styled.div`
  ${media.max(395)`
    display: flex;
    flex-wrap: wrap;
    ${Button} {
      flex-basis: 100%;
      & ~ ${Button} {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  `}
`

const acceptRequest = (values) => iframeAuthPostFetcher("/api/acceptCase" , values)

export const ConfirmAcceptDialog = ({closeDialog, caseRequest, onSuccess}) => {

  const [similarPatients, setSimilarPatients] = useState()

  const checkAddress = useToggle()

  useEffect(() => {
    iframeAuthGetFetcher("/api/listPatient?requestCode=" + caseRequest.requestCode)
      .then(({patients}) => {
        setSimilarPatients(patients)
      })
      .catch(() => setSimilarPatients([null])) // make dialog fail
  }, [])

  const tryAcceptRequest = (values) => {
    if (values.patient && values.replaceAddress === undefined) {
      if (
        caseRequest.patientInfo.country !== values.patient.patientCountry ||
        caseRequest.patientInfo.city !== values.patient.patientCity ||
        caseRequest.patientInfo.state !== values.patient.patientState ||
        caseRequest.patientInfo.zipCode !== values.patient.patientZip
      ) {
        checkAddress.openWith(values.patient)
        return Promise.reject([]);
      }
    }
    return acceptRequest({requestCode: caseRequest.requestCode, patientId: values.patient ? values.patient.id : null, replaceAddress: values.replaceAddress})
  }

  const checkPatient = buildValidation({
    patient: [(patient) => similarPatients.length && patient===undefined && "It is required that you select one of the options above to accept the request."],
  })

  const appUrl = new URLSearchParams(window.location.search).get("appurl")

  return <WithForm
    alwaysSave
    action={tryAcceptRequest}
    preValidation={checkPatient}
    onSuccess={onSuccess}
  >{form =>
    <DialogErrorBoundary closeDialog={closeDialog}>
      <Dialog
        title="Request acceptance confirmation"
        closeDialog={closeDialog}
        withFormWrap
        footer={<>
          <Button disabled={!similarPatients} onClick={closeDialog}>Maybe later</Button>
          <Button disabled={!similarPatients} highlight isLoading={form.isLoading} type="submit">Accept request</Button>
        </>}
      >
        <RequestInfo caseRequest={caseRequest}/>
        {
          similarPatients
            ? <DialogContent appUrl={appUrl} similarPatients={similarPatients} caseRequest={caseRequest}/>
          : <PatientsLoading>Loading... <Loading/></PatientsLoading>
        }
        <Errors/>
      </Dialog>
      {
        checkAddress.isOpen &&
          <CheckAddress form={form} patientInfo={caseRequest.patientInfo} prevAddress={checkAddress.isOpen} closeDialog={checkAddress.close} />
      }
    </DialogErrorBoundary>
  }</WithForm>
}

const CheckAddress = ({patientInfo,prevAddress, closeDialog, form}) => {
  return <Dialog
    closeDialog={closeDialog}
    title="Patient address update"
    footer={<AddressDialogFooter>
      <Button isLoading={form.isLoading} onClick={() => {form.setValue('replaceAddress',false);form.submitForm();}}>Keep the previous address</Button>
      <Button highlight isLoading={form.isLoading} onClick={() => {form.setValue('replaceAddress',true);form.submitForm();}}>Update to new address</Button>
    </AddressDialogFooter>}
  >
    <p>
      We've identified that the patient mailing address for this request differs from
      the one previously registered in the system:
    </p>
    <SplitsWrapper>
      <SplitColumnsContainer>
        <div>
          <strong>New address:</strong>
          <AddressBox>
            <SplitColumnsContainer>
              <ReadOnlyField><label>Street</label><div>{patientInfo.address ? patientInfo.address : <NotProvided/>}</div></ReadOnlyField>
            </SplitColumnsContainer>
            <SplitColumnsContainer>
              <ReadOnlyField><label>City</label><div>{patientInfo.city ? patientInfo.city : <NotProvided/>}</div></ReadOnlyField>
              <ReadOnlyField><label>State</label><div>{patientInfo.state ? patientInfo.state : <NotProvided/>}</div></ReadOnlyField>
            </SplitColumnsContainer>
            <SplitColumnsContainer>
              <ReadOnlyField><label>Country</label><div>{patientInfo.country ? patientInfo.country : <NotProvided/>}</div></ReadOnlyField>
              <ReadOnlyField><label>Postal code</label><div>{patientInfo.zipCode ? patientInfo.zipCode : <NotProvided/>}</div></ReadOnlyField>
            </SplitColumnsContainer>
          </AddressBox>
        </div>
        <div>
          <strong>Previously registered address:</strong>
          <AddressBox>
            <SplitColumnsContainer>
              <ReadOnlyField><label>Street</label><div>{prevAddress.address ? prevAddress.address : <NotProvided/>}</div></ReadOnlyField>
            </SplitColumnsContainer>
            <SplitColumnsContainer>
              <ReadOnlyField><label>City</label><div>{console.log(prevAddress) || prevAddress.patientCity ? prevAddress.patientCity : <NotProvided/>}</div></ReadOnlyField>
              <ReadOnlyField><label>State</label><div>{prevAddress.patientState ? prevAddress.patientState : <NotProvided/>}</div></ReadOnlyField>
            </SplitColumnsContainer>
            <SplitColumnsContainer>
              <ReadOnlyField><label>Country</label><div>{prevAddress.patientCountry ? prevAddress.patientCountry : <NotProvided/>}</div></ReadOnlyField>
              <ReadOnlyField><label>Postal code</label><div>{prevAddress.patientZip ? prevAddress.patientZip : <NotProvided/>}</div></ReadOnlyField>
            </SplitColumnsContainer>
          </AddressBox>
        </div>
      </SplitColumnsContainer>
    </SplitsWrapper>
    <p>
      How would you like to procceed?
    </p>
  </Dialog>
}

const DialogContent = ({caseRequest, appUrl, similarPatients }) =>
  similarPatients.length > 0
    ? <>
      <p>
        We've identified that {similarPatients.lenght > 1 ? <span>there's already a patient </span> : <span>there are already patients </span> }
        in the system with the same details (Name, Gender & DOB) as
        <strong> {caseRequest.patientInfo.firstName} {caseRequest.patientInfo.lastName}</strong>.
      </p>
      <p>
        Would you like to create a new patient with the same data or to associate the
        request with an already existing patient?
      </p>
      {
        similarPatients.map(patient => <PatientOption key={patient.id} appUrl={appUrl} patient={patient}/>)
      }
      <BoxyRadioBox className="create-new"
        label="Create new patient with the same data and associate this request with it"
        path="patient"
        fieldValue={false}
      />
      <p>
        The request initiator will be notified of this acceptance by email.
      </p>
      <p>
        Are you sure to accept this request?
      </p>
    </>
    : <>
      <p>
        By accepting this request a new patient entry will be created in
        the system and this requested case will be associated with it.
      </p>
      <p>
        The request initiator will be notified of this acceptance by email.
      </p>
      <p>
        Are you sure to accept this request?
      </p>
    </>

const PatientOption = ({patient,appUrl}) => <BoxyRadioBox
    label={<>Associate this request with Customer ID{patient.id} - <a target="_blank" className="link"
                                                        onClick={(e) => e.stopPropagation()}
                                                        href={appUrl + "/patient.php?id="+patient.id}>View
      profile</a></>}
    path="patient"
    fieldValue={patient}
  />
