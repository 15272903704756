import React from 'react'
import {useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import {useNotificationRef} from '../../components/Notifications'
import {Errors, WithForm} from '@startlibs/form'
import {Button, Dialog} from '@startlibs/components'
import {triggerCasesUpdate, triggerCaseUpdate} from '../hooks/useRegisterCaseUpdate'
import {ExpertCaseCard} from '../components/ExpertCaseCard'
import {ExpertProfileCard} from '../components/ExpertProfileCard'
import {jwtPostFetcher} from '../../utils/authFetch'
import {getJwt} from '../hooks/useJwt'


export const useUnshareDialog = (expert,sharedCase) => {

  const dialog = useToggle()
  const [,setNotification] = useNotificationRef()

  const action = () =>
    jwtPostFetcher(getJwt())(
      `/api/expert/share/${sharedCase.id}`,'',{method: 'DELETE'}
    )


  const onSuccess = () => {
    setNotification("Case unshared successfully.")
    triggerCasesUpdate(setCases => setCases(_.filter(current => current.id !== sharedCase.id)))
    dialog.close()
  }

  return [dialog.open, dialog.isOpen &&
  <WithForm action={action} onSuccess={onSuccess}>{form =>
    <Dialog
      closeDialog={dialog.close}
      footer={<>
        <Button onClick={dialog.close}>Cancel</Button>
        <Button onClick={form.submitForm} isLoading={form.isLoading} alert>Yes, unshare case</Button>
      </>}
      title="Confirm unshare case"
    >
      <p>By unsharing this case, the expert will no longer be able to access it:</p>
      <ExpertCaseCard caseInfo={sharedCase.caseInfo}/>
      <p>Are you sure to unshare this case with <strong>{expert.firstName} {expert.lastName}</strong> (<strong>{expert.email}</strong>)?</p>
      <Errors/>
    </Dialog>
  }</WithForm>]

}
