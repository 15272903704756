import {authPostFetcher} from '../../../utils/authFetch'
import _ from 'lodash/fp'


export const updateMedicalRecordsInfo = (postFetcher) => (medicalRecordsStore, setMedicalRecords, requestId) => ({
  add: (object,updater) => {

    return postFetcher('/api/medicalRecord?requestId='+requestId,object,{method:"POST"})
      .then(() => {
        medicalRecordsStore.set(updater)
        setMedicalRecords(medicalRecordsStore.get())
      })
  },
  remove: (type, uid, updater) => {
    return postFetcher('/api/medicalRecord?requestId='+requestId,{deleteRequestType:type,mediaRecordID:uid},{method:"DELETE"})
      .then(() => {
        medicalRecordsStore.set(updater)
        setMedicalRecords(medicalRecordsStore.get())
      })
  }
})

export const persistMedicalRecord = (medicalRecordsUpdater) => ({dicomStudy, nonCompliant, nonDicomFile}) => {
  if (dicomStudy) {
    return medicalRecordsUpdater.add({dicomStudy},_.update(
      'dicomStudies',
      (studies) => {
        const studyIndex = studies.findIndex(({studyUID}) => studyUID === dicomStudy.studyUID)
        if (studyIndex >= 0) {
          return _.set([studyIndex], _.omit(['instances', 'isNew'], dicomStudy), studies)
        } else {
          return studies.concat(_.omit(['instances', 'isNew'], dicomStudy))
        }
      }
    ))
  } else if (nonDicomFile) {
    return medicalRecordsUpdater.add({nonDicomFile},_.update(
      'nonDicomFiles',
      _.unionBy(_.property('uid'), [nonDicomFile])
    ))
  } else if (nonCompliant) {
    return medicalRecordsUpdater.add({nonCompliantDicom:nonCompliant},
      _.update(
        ['nonCompliantDicom'],
        _.unionBy(_.property('uid'), [nonCompliant.file])
      )
    )
  }
  return Promise.resolve()
}