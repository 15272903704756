import { Button } from '@startlibs/components';
import {Redirect, Router} from '@reach/router'
import { StartlibsProvider, Slot } from '@startlibs/core';
import { ThemeProvider } from 'styled-components';
import {callIfFunction, getFetcher} from '@startlibs/utils'
import {cold} from 'react-hot-loader'
import React from 'react';
import { ShareLogin } from 'sharecomponents/src/pages/ShareLogin';
import {AssociatesApp} from './associates/AssociatesApp'
import {HelpButton} from './components/PageLayout'
import {LandingPage} from './pages/LandingPage'
import {LoggedIn} from './pages/LoggedIn'
import {Login} from './pages/Login'
import {Navbar} from './components/Navbar'
import {Notifications} from './components/Notifications'
import { PageLoadingSuspense } from './components/PageLoading';
import {ProviderErrorBoundary} from './pages/errors/ProviderError'
import {PurviewFooter} from './components/PurviewFooter'
import {RequestList} from './administrator/RequestList'
import {SignUp} from './pages/SignUp'
import {useSuspense} from './hooks/useSuspense'
import StartlibsConfig from './UIConfig/StartlibsConfig'
import StyledTheme from './UIConfig/StyledTheme'
import {ShareRoutes} from './share/ShareRoutes'
import {MobileApp} from './mobile-app/MobileApp'
import {EditExpert} from './expert/EditExpert'
import {ExpertRoutes} from './expert/ExpertRoutes'
import {CMSIframe} from './expert/CMSIframe'
import {InvitedExpertRoutes} from './expert/InvitedExpertRoutes'


const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}
  <div ref={ref}/>
</React.Fragment>)


export const App = () => {

  // const maxDuration = useToggle(1)
  // useEffect(() => {
  //   setTimeout(() => maxDuration.openWith(10000), 1000)
  // }, [])

  return <StartlibsProvider value={StartlibsConfig}>
    <ThemeProvider theme={StyledTheme}>
      <>
        <ProviderErrorBoundary>
          <PageLoadingSuspense>
            <Router component={Identity}>
              <RequestList path="/requests"/>
              <ExpertRoutes path="/experts/*"/>
              <InvitedExpertRoutes path="/cms-expert/:jwt/*"/>
              <CMSIframe path="/cms-experts/*"/>
              <AssociatesApp path="/associate/*"/>
              <MobileApp path="/mobile-app/*"/>
              <PatientRoutes default/>
            </Router>
          </PageLoadingSuspense>
        </ProviderErrorBoundary>
        <Notifications/>
        <Router component={Identity}>
          <Identity path="/mobile-app/*"/>
          <Identity path="/cms-experts/*"/>
          <HelpButton default/>
        </Router>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100}}>
          <Slot name="Popup"/>
        </div>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
          <Slot name="Portal"/>
          <Slot name="Dialog"/>
          <Slot name="Confirm-Dialog"/>
          <Slot name="Notification"/>
          <Slot name="Notifications"/>
        </div>
      </>
    </ThemeProvider>
  </StartlibsProvider>
}

const ColdRedirect = cold(Redirect)

const PatientRoutes = () => {
  const providerInfo = useSuspense('/api/providerInfo')
  return <>
    <Router component={Identity}>
      <Identity path="/access"/>
      <Identity path="/"/>
      <Identity path="/share/*"/>
      <Navbar providerInfo={providerInfo} default/>
    </Router>
    <Router component={Identity}>
      <ShareRoutes path="/share/:jwt" login providerInfo={providerInfo}/>
      <ShareRoutes path="/shared/:jwt" providerInfo={providerInfo}/>
      <LandingPage path="/" providerInfo={providerInfo} />
      <Login providerInfo={providerInfo} path="/access"/>
      <EnsureLoggedIn providerInfo={providerInfo} path="/expert-opinion"/>
      <SignUp providerInfo={providerInfo} path="/start"/>
    </Router>
    <PurviewFooter/>
  </>
}


class EnsureLoggedIn extends React.Component {
  state = {}

  componentDidCatch(error) {
    if (error.invalidateCache) {
      callIfFunction(error.invalidateCache)
      this.setState({navigate: true})
    }
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  render() {
    return !this.state.hasError
           ? <LoggedIn {...this.props}/>
           : this.state.navigate
             ? <Redirect to="/access"/>
             : null
  }
}
