import React, {Suspense, useEffect} from 'react'
import {useToggle} from '@startlibs/core'
import {createGlobalStyle} from 'styled-components'
import {navigate, Router} from '@reach/router'
import {ProviderErrorBoundary} from '../pages/errors/ProviderError'
import {LoadingPage} from '../components/PageLoading'
import {useNotification} from '../components/Notifications'
import {CMSExpertShareDialog} from './CMSExpertShareDialog'
import {LoadingErrorDialog} from 'sharecomponents/src/pages/LoadingErrorDialog'
import {useRegisterJwt} from './hooks/useJwt'

const TransparentBackground = createGlobalStyle`
  body {
    background-color: transparent !important;
  }
`

export const CMSIframe = () => {

  const page = useToggle()

  const maxDuration = useToggle(1)
  useEffect(() => {
    setTimeout(() => maxDuration.openWith(10000), 1000)
  }, [])

  useEffect(() => {
    window.addEventListener('message', (e) => {
      const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data
      if (data.type === 'blank') {
        navigate('/cms-experts/blank/')
      }
      if (['blank', 'loading', 'error', 'share'].indexOf(data.type) >= 0) {
        page.openWith(data)
      }
    }, false)
    window.parent.postMessage(JSON.stringify({type: 'loaded'}), '*')
  }, [])

  return <ProviderErrorBoundary isDialog={window.location.pathname.indexOf("/share/") < 0}>
    <Suspense fallback={<LoadingPage/>}>
      <TransparentBackground/>
      <Router>
        <CMS path="/vivashare/:jwt"/>
        <CMS path="/loading"/>
        <Error path="/error"/>
        {
          !page.isOpen
            ? <Blank path="/blank"/>
            : ((page.isOpen.type === 'blank' && <Blank default/>) ||
            (page.isOpen.type === 'loading' && <CMS default/>) ||
            (page.isOpen.type === 'error' && <Error default errorStatus={page.isOpen.errorStatus}/>) ||
            (page.isOpen.type === 'share' && <CMS default jwt={page.isOpen.jwt} caseInfo={page.isOpen.caseInfo}/>))
        }
      </Router>
    </Suspense>
  </ProviderErrorBoundary>
}

const Error = ({errorStatus}) => {
  const dialog = useToggle(true)

  const closeDialog = React.useCallback(() => {
    dialog.close()
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }, [])

  return dialog.isOpen && <LoadingErrorDialog
    errorCode={errorStatus || "01"}
    closeDialog={closeDialog}
  />
}


const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}
  <div ref={ref}/>
</React.Fragment>)

const Blank = () => {
  useEffect(() => {
    const ref = document.getElementById('js-share-notification')
    if (ref) {
      const t = setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 5000)
      return () => window.clearTimeout(t)
    }
    window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*')
  }, [])
  return <div/>
}


const CMS = ({jwt, location, caseInfo}) => {
  useRegisterJwt(() => jwt)

  const query = new URLSearchParams(location && location.search)
  const [notification, setNotification] = useNotification()

  const closingDialog = useToggle()

  const closeDialog = React.useCallback(() => {
    closingDialog.open()
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }, [])

  const notifyAndClose = React.useCallback((notification) => {
    closingDialog.open()
    document.body.parentElement.style.overflow = 'hidden'
    setNotification(notification)
    setTimeout(() => {
      const ref = document.getElementById('js-share-notification')
      if (ref) {
        const {width, height, top, left} = ref.getBoundingClientRect()
        window.parent.postMessage(JSON.stringify({
          type: 'resizeIframe',
          width: width + 2,
          height: height + top + 4,
          left: left - 1,
          top: 0
        }), '*')
      }
    }, 300)
  }, [])

  useEffect(() => {
    if (closingDialog.isOpen && !notification) {
      setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
    }
  }, [notification])

  if (closingDialog.isOpen) {
    return null
  }


  return <CMSExpertShareDialog
    closeDialog={closeDialog}
    notifyAndClose={notifyAndClose}
    caseInfo={caseInfo}
    jwt={jwt}
  />
}
