import {Button, Dialog, Icon} from '@startlibs/components';
import {Errors, WithForm} from '@startlibs/form'
import {getColor} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {CurrentCardDetails} from './CurrentCardDetails'
import {InfoBox} from '../../../components/InfoBox';
import {UpdatePayment} from './UpdatePayment'
import {responseFailure} from '../../../utils/validation'


const CardBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1rem 1.5rem;
  font-size: 13px;
`

const onFailure = responseFailure((resp,{status,errors}) => console.log(status,resp.errors) ||
  (resp && resp.errors) ||
  (status === 560 && ({"": [resp]})) ||
  ({'': ['Something went wrong. Please try again. If the error persists please contact us.']})
)


export const PaymentErrorDialog = ({closeDialog, error, setCardInfo, caseRequest, onSuccess, action, nextStep, updateCardAction}) => {
  const changeCard = useToggle(!caseRequest)
  const stripeRef = useRef()
  const retried = useToggle()

  const submit = (values) => {
    retried.open()
    if (changeCard.isOpen) {
      return stripeRef.current.submit(values).then(updateCardAction).then((v) => action(v).then(() => setCardInfo(v.cardInfo))).then(nextStep)
    } else {
      return (action(values).then(nextStep))
    }
  }

  return <WithForm
    alwaysSave
    onFailure={onFailure}
    action={submit}
    values={{acceptedTerms: true}}
    onSuccess={onSuccess}
  >{form =>
    <Dialog
      closeDialog={closeDialog}
      form={form}
      title="Payment issue: request not sent"
      footer={<>
        <Button onClick={closeDialog}>Send later</Button>
        <Button highlight isLoading={form.isLoading}
                type="submit">{changeCard.isOpen ? "Update card & Submit request" : "Try again & Submit request"}</Button>
      </>}
    >
      <p css="margin-bottom:0.25rem;">Something went wrong when we attempted to charge your credit card.</p>
      {
        !retried.isOpen &&
        <InfoBox error withIcon css="margin-top:0.75rem;">
          <Icon icon="failure"/> <span>{error}</span>
        </InfoBox>
      }
      <p>Please review or update your card details below to submit your request:</p>
      <CardBox>
        {
          changeCard.isOpen
            ? <UpdatePayment ref={stripeRef} caseRequest={caseRequest} changeCard={changeCard}
                             patientInfo={caseRequest.patientInfo}/>
            : <CurrentCardDetails caseRequest={caseRequest} changeCard={changeCard}/>
        }
      </CardBox>
      <Errors/>
    </Dialog>
  }
  </WithForm>

}
