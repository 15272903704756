import { Button, Icon } from '@startlibs/components';
import { getColor, media } from '@startlibs/utils';
import {navigate} from '@reach/router'
import React from 'react'
import styled from 'styled-components';
import {confirmExitPage} from '../hooks/useConfirmExitPage'
import {useIsAssociate} from '../hooks/useIsAssociate'
import {useQRCodeDialog} from '../hooks/useQRCodeDialog'
import {useShareDialog} from '../hooks/useShareDialog'

const PageTitle = styled.h1 `
  font-size: 22px;
  margin-bottom: 1rem;
  flex-shrink; 0;
  white-space: nowrap;
  margin-bottom: 0;
`
const SubHeader = styled.div `
  color: rgba(0,0,0,0.6);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 13px;
`
export const ReturnButton = styled(Icon) `
  position: absolute;
  font-size: 23px;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.07);
  left: -4.25rem;
  top: 8px;
  :hover {
    cursor: pointer;
    background: rgba(0,0,0,0.1);
  }
  :active {
    background: rgba(0,0,0,0.13);
  }
  ${media.max(895)`
    left: 0;
  `}
  ${media.max(530)`
    top: 0;
  `}
`

export const Header = styled(({caseRequest,subheader,title,className, canShare = true, isReport}) => {
  const [isAssociate] = useIsAssociate()

  const [openShareDialog,shareDialog] = useShareDialog(caseRequest,isAssociate)
  const [openQRCodeDialog,qrCodeDialog] = useQRCodeDialog(caseRequest)

  return <div className={isAssociate ? 'associate ' + className : className}>
    <div>
      {isAssociate && <ReturnButton icon="return" onClick={() => Promise.all(confirmExitPage()).then(([navigation]) => (navigation && navigation.prevStep) ? navigation.prevStep() : navigate("/associate"))}/>}
      <PageTitle>{title ? title : "Expert opinion request"}</PageTitle>
      {
        caseRequest &&
        <SubHeader><b>Patient:</b> {caseRequest.patientInfo.firstName} {caseRequest.patientInfo.lastName} {caseRequest.requestCode && <span><span css="margin: 0 0.25rem;"> • </span><b>Request code:</b> {caseRequest.requestCode}</span>}</SubHeader>
      }
    </div>
    {
      caseRequest &&
      <div>
        <Button icon="phone" onClick={openQRCodeDialog} highlight={isReport && !canShare} className="desktop-only">Import to app</Button>
          {canShare && <Button highlight={isReport} onClick={openShareDialog} icon="send">Share</Button>}
      </div>
    }
    {qrCodeDialog}
    {shareDialog}
  </div>
})`
  border-bottom: 1px solid ${getColor('gray210')};;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5rem;
  position: relative;
  ${media.max(895)`
    &.associate {
      padding-left: 4rem;
    }
  `}
  ${media.max(530)`
    flex-wrap: wrap;
    ${PageTitle} {
      flex-basis: 100%;
    }
  `}
 `
