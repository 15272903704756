import React, {Suspense, useState} from 'react'
import _ from 'lodash/fp'
import {navigate, Router} from '@reach/router'
import {ExpertList} from './ExpertList'
import {EditExpert} from './EditExpert'
import {ViewExpert} from './ViewExpert'
import {useRegisterExpertDeleted} from './hooks/useDeleteExpertDialog'
import {postFetcher} from '@startlibs/utils'
import {authPostFetcher, jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {willUseSuspense} from '../hooks/useSuspense'
import {useConstant} from '@startlibs/core'
import {useRegisterJwt, useRegisterOrigin} from './hooks/useJwt'
import {PageLoadingSuspense} from '../components/PageLoading'
import {useNotificationRef} from '../components/Notifications'

const DEV_JWT = ''

const useAuthSuspense = willUseSuspense((jwt) =>
  jwtGetFetcher(jwt)(`/api/experts?nocache=${new Date().getTime()}`)
)


const createSuspense = (jwt) => willUseSuspense((id) =>
  !id
    ? Promise.resolve()
    : jwtGetFetcher(jwt)(`/api/expert/${id}?nocache=${new Date().getTime()}`)
    .then(_.update('info.specialization', (v) => v || []))
    .then(_.set('id', Number(id)))
)

export const ExpertRoutes = ({}) => {
  const jwt = useConstant(new URLSearchParams(window.location.search).get("jwt"))
  // const jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcm92aWRlck5hbWUiOiJkZW1vIiwiZXhwIjoxNTczNjU0NTE2LCJhY2Nlc3NUeXBlIjoiQ01TIn0.4_iasLC4XkFM5zQ5DYDn-xsydENQ-6jAYMYQ8L2J0RA'
  const [experts, setExperts] = useState(useAuthSuspense(jwt))
  const [, setNotification] = useNotificationRef()
  useRegisterJwt(() => jwt)
  useRegisterOrigin(() => new URL(window.document.referrer || window.location.href).origin)

  const addOrUpdateExpert = (newExpert) => (
    newExpert.id
    ? jwtPostFetcher(jwt)('/api/expert', newExpert, {method: 'PUT'})
    : jwtPostFetcher(jwt)('/api/expert', newExpert)
  )
    .then((expert) =>
      jwtGetFetcher(jwt)(`/api/experts?nocache=${new Date().getTime()}`)
        .then(setExperts)
        .then(() =>  setNotification(newExpert.id ? "Expert profile updated successfully." : "Expert added successfully."))
        .then(() => expert)
    )

  useRegisterExpertDeleted((deletedExpert) => setExperts(_.filter(exp => exp !== deletedExpert)))

  const useExpertGetSuspense = useConstant(createSuspense(jwt))
  const useExpertViewSuspense = useConstant(createSuspense(jwt))

  const useExpertCases = useConstant(willUseSuspense((id) =>
    jwtGetFetcher(jwt)(`/api/expert/${id}/cases?nocache=${new Date().getTime()}`)
  ))

  const onSaveExpert = (v,{id}) => {
    navigate("/experts/view/"+id)
  }

  return <PageLoadingSuspense>
    <Router>

      <ViewExpert path="view/:id" useExpertCases={useExpertCases} useExpertViewSuspense={useExpertViewSuspense} action={addOrUpdateExpert}/>

      <EditExpert onSuccess={onSaveExpert} path="edit/:id" useExpertViewSuspense={useExpertGetSuspense} action={addOrUpdateExpert}/>
      <EditExpert onSuccess={onSaveExpert} path="new" create useExpertViewSuspense={useExpertGetSuspense} action={addOrUpdateExpert}/>
      <ExpertList jwt={jwt} experts={experts} default/>
    </Router>
  </PageLoadingSuspense>
}