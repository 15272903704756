import { ContextMenu, Icon, Li } from '@startlibs/components';
import { Slot, usePopupToggle } from '@startlibs/core';
import { getColor, media } from '@startlibs/utils';
import { navigate } from '@reach/router';
import React from 'react'
import styled, { css } from 'styled-components';
import {confirmExitPage} from '../hooks/useConfirmExitPage'

const NavbarComponent = styled.div`
  background: white;
  height: 5.5rem;
  left:0;
  right:0;
  top: 0;
  position: fixed;
  z-index: 100;
  min-width: 320px;
  padding: 0 1.5rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px, rgba(0, 0, 0, 0.2) 0px 0px 5px 1px;
  .user-dropdown {
    cursor: pointer;
    color: ${getColor('gray120')};
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 1;
    justify-content: flex-end;
    max-width: calc(100% - 132px);
    .email {
      margin-right: .5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .user-icon {
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid ${getColor('gray120')};
      font-size: 18px;
      margin-right: .25rem;
      flex-shrink: 0;
    }
  }
  ${media.max(520)`
    height: 5rem;
  `}
  @media print {
    display: none !important;
  }
`
const NavbarSystemLogo = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: ${getColor('gray120')};
  img {
    max-width: 180px;
    max-height: 3rem;
    display: inline-block;
    margin-right: 1rem;
    ${props => props.isAssociate && css`
      cursor: pointer;
    `}
  }
  ${media.max(520)`
    font-size: 14px;
    img {
      max-width: 120px;
      max-height: 3rem;
    }
  `}
  ${media.max(320)`
    span {
      display: none;
    }
  `}
`
export const LogoutButton = styled.a `
  color: ${getColor('main')};
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  vertical-align: 2px;
`

export const Navbar = ({providerInfo, isAssociate, associateInfo}) => {

  const contextMenu = usePopupToggle()

  return <NavbarComponent>
  <NavbarSystemLogo isAssociate={isAssociate}>
    { !isAssociate ?
      <img src="/public/purview-logo.png" alt="Logo"/>
      :
      <img src="/public/purview-logo.png" alt="Logo" onClick={() => Promise.all(confirmExitPage()).then(() => navigate("/associate"))} />
    }
  </NavbarSystemLogo>
  { !isAssociate ?
    <Slot name="Navbar-Exit"/>
    :
    <div className="user-dropdown" onClick={contextMenu.open}>
      <span className="email">{associateInfo.login}</span>
      <Icon icon="user" className="user-icon" /> <Icon icon="arrow-down" />
      {
        contextMenu.isOpen &&
        <ContextMenu atRight>
          <Li label="Change password" onClick={() => Promise.all(confirmExitPage()).then((r) => setTimeout(() => window.location = "/api/associate/resetPassword?t="+window.sessionStorage.getItem("jwt"),100))} />
          <Li label="Sign out" icon="sign-out" onClick={() => Promise.all(confirmExitPage()).then(() => navigate("/access"))} />
        </ContextMenu>
      }
    </div>
  }
</NavbarComponent>
}
