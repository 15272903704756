import React from 'react'
import {InfoBox} from '../../components/InfoBox'

export const ContactInfo = ({caseRequest}) => <InfoBox>
  <div className="contact-name">
    <span>Contact details: </span>
    {
      caseRequest.associate
        ? <span>{caseRequest.associate.firstName} {caseRequest.associate.lastName}</span>
        : (
          caseRequest.patientInfo.contactName
            ? <span>{caseRequest.patientInfo.contactName}</span>
            : <span>{caseRequest.patientInfo.firstName} {caseRequest.patientInfo.lastName}</span>
        )
    }
  </div>
  <div className="details">
    <div className="email">
      <span>Email: </span>{caseRequest.associate ? caseRequest.associate.email : caseRequest.patientInfo.email}</div>
    {
      caseRequest.associate
        ? (caseRequest.associate.phoneNumber &&
        <div className="phone"><span>Phone: </span>{caseRequest.associate.phoneNumber}</div>)
        : (caseRequest.patientInfo.phoneNumber &&
        <div className="phone"><span>Phone: </span>{caseRequest.patientInfo.phoneNumber}</div>)
    }
  </div>
</InfoBox>
