import React from 'react'
import {Button, Dialog} from '@startlibs/components'
import {Errors, TextInput, WithForm} from '@startlibs/form'
import {ContactInfo} from './ContactInfo'
import {RequestInfo} from './RequestInfo'
import { InfoBox } from '../../components/InfoBox'
import {iframeAuthPostFetcher} from '../../utils/authFetch'

const declineRequest = (requestCode) => (values) => iframeAuthPostFetcher("/api/declineCase",{...values,requestCode})

const EMPTY_FORM = {reason:""}

export const ConfirmDeclineDialog = ({closeDialog,caseRequest,onSuccess}) =>
  <WithForm
    alwaysSave
    values={EMPTY_FORM}
    action={declineRequest(caseRequest.requestCode)}
    onSuccess={onSuccess}
  >{ form =>
  <Dialog
    title="Are you sure to decline this request?"
    closeDialog={closeDialog}
    withFormWrap
    footer={<>
      <Button onClick={closeDialog}>Do not decline</Button>
      <Button alert isLoading={form.isLoading} type="submit">Decline request</Button>
    </>}
  >
    <RequestInfo caseRequest={caseRequest} />
    <p>
      You are about to decline the above expert opinion request. This action is irreversible.
    </p>
    <p>
      If you need additional information to accept this request, consider contacting the request initiator for the additional information rather than declining this request:
    </p>
    <ContactInfo caseRequest={caseRequest} />
    <p>
      The request initiator will be notified by email once this request is declined.
    </p>
    <TextInput
      path="reason"
      label="Reason to decline"
      descText="This will be sent to the request initiator explaining why its request was declined"
      placeholder="Type the reason to decline this request here"
    />
    <Errors />
  </Dialog>
}</WithForm>
